import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { ImTruck } from "react-icons/im";
import { MdNotificationsActive } from "react-icons/md";
import { useCart } from 'react-use-cart';
import { numberFormat } from '../components/numberFormate';
import { getSingleDataApi, userLoginAPi, userOrder, userVerifyMobileOTPAPi } from '../utils/api';
import { toast } from 'react-toastify';
import { Stepper } from 'react-form-stepper';


const CheckOut = () => {
    const [mobile, setMobile] = useState("");
    const [mobileotp, setMobileOTP] = useState("");
    const [toggled, setToggled] = useState(true);
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const pathname = window.location.href;
    const {
        items,
        totalUniqueItems,
        emptyCart,
        cartTotal,
    } = useCart();
    const cartdata = JSON.stringify(items);
    const navigate = useNavigate();

    const initialState = {
        name: '',
        email: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        address: '',
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (userdata) {
            getSingleUserdata();
        }
    }, [])

    const getSingleUserdata = () => {
        const userid = JSON.parse(localStorage.getItem('userdata')).id;
        getSingleDataApi(`get-single-data/${userid}`).then((res) => {
            if (res.data.status === 1) {
                setState(...[res.data.userdata])
            }
            else {
                toast.error("Unable to fetched user data");
            }
        })
    }

    const handleCheckout = async (e) => {
        e.preventDefault();
        if (state.name && state.email && state.email && state.country && state.city && state.state && state.pincode && state.address && state.paymentmethod) {
            if (userdata) {
                const userid = JSON.parse(localStorage.getItem('userdata')).id;
                const datas = { userid: userid, mobile: state.mobile, name: state.name, email: state.email, country: state.country, state: state.state, city: state.city, pincode: state.pincode, address: state.address, paymentmethod: state.paymentmethod, cartdata: cartdata, totalamt: cartTotal, discount: "100" }
                try {
                    userOrder('user-order', datas).then((res) => {
                        if (res.data.status === 1) {
                            const orderid = res.data.orderid;
                            emptyCart();
                            localStorage.setItem("orderid", orderid);
                            navigate('/order-confirm');
                        }
                    })
                }
                catch (error) {
                    toast.error("Server internal error!!");
                }
            }
        }
        else {
            toast.error("All fields are required!!");
        }
    }
    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobile(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (mobile.length === 10) {
            userLoginAPi("user-send-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("usermobile", mobile)
                    toast.success("Mobile OTP has been send succssfully");
                    e.target.reset();
                    setToggled(!toggled);
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }

    const NumericOTPOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobileOTP(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    // Matched Mobile OTP
    const handleMobileOTPSubmit = async (e) => {
        e.preventDefault();
        if (mobileotp.length === 4) {
            const mobile = localStorage.getItem("usermobile");
            const mobiledata = {
                mobile,
                mobileotp,
            };
            userVerifyMobileOTPAPi("user-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("userdata", JSON.stringify(res.data.userdata))
                    getSingleUserdata();
                    toast.success("Your mobile OTP has been verified!!");
                    navigate('/checkout');
                }
                else {
                    toast.error("Unable to verify mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile OTP");
        }
    }
    const handleInputs = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const displayLoginSection = () => {
        if (toggled) {
            return <div className="account-card">
                <div className="account-title">
                    <h4>User Login</h4>
                </div>
                <div className="account-content">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <form method="post" className="checkout-login" onSubmit={handleLoginSubmit}>
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile Number</label>
                                    <input type="text" name="mobile" id="mobile" onChange={NumericOnly} placeholder="Mobile Number" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btncheckcont" name="login">Send OTP</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="secureLogin">
                                <h4>Advantages of our secure login</h4>
                                <ul>
                                    <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                    <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return <div className="account-card">
            <div className="account-title">
                <h4>Verify Mobile OTP</h4>
            </div>
            <div className="account-content">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <form method="post" className="checkout-login" onSubmit={handleMobileOTPSubmit}>
                            <div className="form-group">
                                <label htmlFor="mobileotp">Enter Mobile OTP</label>
                                <input onChange={NumericOTPOnly} type="text" name="mobileotp" id="mobileotp" placeholder="Enter your Mobile OTP" className="form-control" />
                            </div>
                            <div className="form-group">
                                <button className="btncheckcont" name="login">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="secureLogin">
                            <h4>Advantages of our secure login</h4>
                            <ul>
                                <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };
    return (
        <div className="faq-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Checkout</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Checkout</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            {!userdata ?
                                <>{displayLoginSection()}</>
                                :
                                ""
                            }
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Billing Address</h4>
                                </div>
                                <div className="account-content">
                                    <form onSubmit={handleCheckout} method="post">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control" id="name" name="name" placeholder="Name" required="" value={state.name} onChange={handleInputs} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" id="email" name="email" required="" placeholder="Email" value={state.email} onChange={handleInputs} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="mobile">Mobile</label>
                                                <input type="text" className="form-control" id="mobile" placeholder="Mobile" userphone="true" name="mobile" readOnly={true} required="" value={state.mobile} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="city">City</label>
                                                <input type="text" className="form-control" id="city" name="city" placeholder="City" onChange={handleInputs} required="" value={state.city} />
                                            </div>
                                            <div className="col-md-6 mb-3"><label htmlFor="state">State</label><input type="text" className="form-control" id="state" name="state" placeholder="State" required="" value={state.state} onChange={handleInputs} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="country">Country</label>
                                                <input type="text" className="form-control" id="country" name="country" placeholder="Country" required="" value={state.country} onChange={handleInputs} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="pincode">Pincode</label>
                                                <input type="text" className="form-control" id="pincode" name="pincode" placeholder="Pincode" required="" value={state.pincode} onChange={handleInputs} />
                                            </div>
                                            <div className="col-md-6 mb-3 paymentType">
                                                <div className="paymentmethod">
                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"COD"} onChange={handleInputs} />
                                                    <label htmlFor="paymentmethod">COD(Cash On Delivery)</label>
                                                </div>
                                                {/* <div className="paymentmethod">
                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"Payment"} onChange={handleInputs} />
                                                    <label htmlFor="paymentmethod">Pay Now</label>
                                                </div> */}
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="address">Address</label>
                                                <textarea className="form-control" id="address" name="address" placeholder="Address" required="" rows={5} value={state.address} onChange={handleInputs}></textarea>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    {userdata ?
                                                        <button className="btncheckcont" name="login">Submit</button>
                                                        :
                                                        <button disabled={true} className="btncheckcont disabledbtn" name="login">Submit</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="account-card checkoutCartDetails">
                                <div className="account-title">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-muted">Your cart</span><span className="badge-secondary badge-pill">{totalUniqueItems}</span>
                                    </h4>
                                </div>
                                <div className="account-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form method="post" className="apply-coupon">
                                                <input className="form-control" type="text" placeholder="Enter Coupon Code..." />
                                                <button disabled={true} className="btn  btn-md" name="login">Apply Coupon</button>
                                            </form>
                                            <ul className="list-group mb-3 checkoutCart">
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">Subtotal</h6>
                                                    </div>
                                                    <span className="text-muted">{numberFormat(cartTotal)}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div><h6 className="my-0">GST</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">SGST</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                                    <div>
                                                        <h6 className="my-0">Discount</h6>
                                                    </div>
                                                    <span className="text-muted">₹0</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span>Total (INR)</span>
                                                    <strong>{numberFormat(cartTotal)}</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default CheckOut