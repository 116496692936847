import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { GrLanguage } from "react-icons/gr";
import { MdFolderSpecial } from 'react-icons/md';
import { FaRegHandPointRight, FaTransgenderAlt, FaUserGraduate } from 'react-icons/fa';
import { fetchDataFromApi, talkToExpert } from '../utils/api';
import Calendar from 'react-calendar';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import { SlCalender } from 'react-icons/sl';
import { FcAlarmClock } from 'react-icons/fc';
import Swal from 'sweetalert2';
import { Form, FormGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import loadingif from ".././assets/loading-gif.gif";



const TalkToOurExperts = () => {
    const pathname = window.location.href;
    const [loading, setLoading] = useState(false);
    const [doctor, setDoctor] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/doctor/";
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [validated, setValidated] = useState(false);
    const [values, setTalkToExpert] = useState();
    const [doctorTitle, setDoctorTitle] = useState([]);
    const [doctorFaq, setDoctorFaq] = useState([]);



    useEffect(() => {
        getDoctorTitle();
        getAllDoctor();
        getTime();
        getDoctorFaq()
    }, [])

    const getDoctorTitle = () => {
        fetchDataFromApi('get-all-title-doctor').then((res) => {
            if (res.status === 1) {
                setDoctorTitle(...[res.tdoctor])
            }
        })
    }
    const getAllDoctor = () => {
        fetchDataFromApi('get-all-active-doctor').then((res) => {
            if (res.status === 1) {
                setDoctor(...[res.doctor])
            }
        })
    }
    const getDoctorFaq = () => {
        fetchDataFromApi('get-all-active-doctor-faq').then((res) => {
            if (res.status === 1) {
                setDoctorFaq(...[res.doctorfaq])
            }
        })
    }
    const [timeSlot, setTimeSlot] = useState([]);
    const [selectTimeSlot, setSelectTimeSlot] = useState(null);
    const [selectDate, setSelectDate] = useState(null);
    const [doctorId, setDoctorId] = useState("");

    const getTime = () => {
        const timeList = [];
        for (let i = 10; i <= 12; i++) {
            timeList.push({
                time: i + ':00 AM'
            })
            timeList.push({
                time: i + ':30 AM'
            })
        }
        for (let i = 1; i <= 6; i++) {
            timeList.push({
                time: i + ':00 PM'
            })
            timeList.push({
                time: i + ':30 PM'
            })
        }
        setTimeSlot(timeList);
    }
    const handleBookingBtn = (doctorid) => {
        setDoctorId(doctorid)
        setShow(true)
    }
    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setLoading(false);
            event.stopPropagation();
        }
        else {
            try {
                const talkdata = { sheduledate: selectDate, sheduletime: selectTimeSlot, talkname: values?.talk_name, talknumber: values?.talk_number, doctorid: doctorId }
                if (selectDate === null || selectTimeSlot === null) {
                    toast.error("Please select date and time");
                    setLoading(false);
                }
                else {
                    talkToExpert('talk-to-expert', talkdata).then((res) => {
                        if (res.data.status === 1) {
                            setLoading(false);
                            setShow(false);
                            Swal.fire({
                                position: "top-center",
                                icon: "success",
                                title: "Your appointment has been booked successfully!!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                        else {
                            setLoading(false);
                            toast.warning("Check your connection!!");
                        }
                    })
                }
            }
            catch (error) {
                toast.error("Something is worng!!");
            }

        }
        setValidated(true);
    };
    const handleInputs = e => {
        setTalkToExpert({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Free Ayurvedic Consultation - Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <span className="calender"><SlCalender /> Select Date</span>
                                <Calendar className="mt-2" minDate={new Date()} onChange={(e) => setSelectDate(e)} />
                            </Col>
                            <Col md={6}>
                                <div className="timeSlot">
                                    <span className="calendertime"><FcAlarmClock /> Select Time</span>
                                    <Row className="timesBody border rounded p-2">
                                        {timeSlot?.map((item, index) => (
                                            <Col onClick={() => setSelectTimeSlot(item.time)} md={3} key={index} className={`p-2 border rounded timebody ${item.time == selectTimeSlot && 'timeactive'}`}>{item.time}</Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                            <FormGroup className="mb-3" controlId="validationCustom01">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter the name"
                                    autoFocus
                                    required
                                    onChange={handleInputs}
                                    name="talk_name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a Full name.
                                </Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup className="mb-3" controlId="validationCustom02">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile Number"
                                    autoFocus
                                    required
                                    onChange={handleInputs}
                                    name="talk_number"
                                    pattern="[6789][0-9]{9}"
                                    maxLength={10}
                                    minLength={10}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a mobile number.
                                </Form.Control.Feedback>
                            </FormGroup>
                            {(() => {
                                if (loading === true) {
                                    return (
                                        <Button type="submit" className="btnpopup" disabled={true}>
                                            <img src={loadingif} alt={loadingif} style={{ width: "7%", marginTop: "-3px" }} /> BOOKING...
                                        </Button>
                                    )
                                }
                                else {
                                    return (
                                        <Button type="submit" className="btnpopup">
                                            Book
                                        </Button>
                                    )
                                }
                            })()}
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Talk to our experts</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Talk to experts</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 bg-white">
                <ContentWrapper>
                    <div className="row bg-white">
                        <div className="col-md-12 col-sm-12">
                            <div className="productTopBar productTopBars">
                                <div className="productViewTitle catdespstitle">
                                    <h1 className="text-center">{doctorTitle?.d_head_title}</h1>
                                </div>
                            </div>
                            <div className="productView mt-4">
                                <div className="col-md-12 col-sm-12">
                                    <p className="text-center">
                                        <div className="catdesps" dangerouslySetInnerHTML={{ __html: doctorTitle?.d_head_desc }}></div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <h1>Our Team Of Ayurvedic Doctors</h1>
                            {doctor?.map((doctor, index) => (
                                <div className="doctorContainer" key={index}>
                                    <div className="doctorCard">
                                        <div className="doctorImg">
                                            <img src={BASE_URL + doctor?.doctor_img} alt={doctor?.doctor_img} />
                                        </div>
                                        <div className="doctorBody">
                                            <h2 className="doctorName">Dr. {doctor?.doctor_name}</h2>
                                            <div className="doctorSpc">
                                                <p><FaTransgenderAlt /> {doctor?.doctor_splz}.</p>
                                                <p>{doctor?.doctor_total_exp}</p>
                                            </div>
                                            <div className="doctorQua">
                                                <p><MdFolderSpecial /> {doctor?.doctor_title}</p>
                                            </div>
                                            <div className="doctorDegree">
                                                <p><FaUserGraduate /> {doctor?.doctor_qlf}</p>
                                            </div>
                                            <div className="doctorLang">
                                                <p><GrLanguage /> {doctor?.doctor_lang}</p>
                                            </div>
                                            <div className="doctorDesc">
                                                <div dangerouslySetInnerHTML={{ __html: doctor?.doctor_desc }}></div>
                                            </div>
                                            <div className="doctorBtn">
                                                <button onClick={() => handleBookingBtn(doctor?.id)}>Book FREE Appointment</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row mt-4 talkfaq">
                        <h1 className="text-center">FAQS</h1>
                        <Accordion defaultActiveKey="0">
                            {doctorFaq?.map((doctorFaq, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{doctorFaq?.d_faq_question}</Accordion.Header>
                                    <Accordion.Body>
                                    <div className="catdesps" dangerouslySetInnerHTML={{ __html: doctorFaq?.d_faq_desc }}></div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default TalkToOurExperts