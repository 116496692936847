import React, { useRef, useEffect, useState } from 'react'
import { fetchDataFromApi } from '../utils/api';
import { MetaTags } from 'react-meta-tags';
import HeroBanner from '../components/HeroBanner';
import BestSeller from '../components/BestSeller';
import HealthCare from '../components/HealthCare';
import Testimonial from '../components/Testimonial';
import TopSelling from "../components/TopSelling";
import AuthenticProduct from "../components/AuthenticProduct";
import HomeBlog from "../components/HomeBlog";
import FooterContent from "../components/FooterContent";
import NewArrival from '../components/NewArrival';
import AyurvedicProducts from '../components/AyurvedicProducts';
import Vitamin from '../components/Vitamin';
import TalkToExpert from '../components/TalkToExpert';
import HerbalProducts from '../components/HerbalProducts';


const Home = () => {
    const pathname = window.location.href;
    const [htitle, setHTitle] = useState([]);
    const [lastScrolly, setLastScrolly] = useState(0);

    useEffect(() => {
        getHomeTitle();
        window.addEventListener("scroll", checkScroll);
        return () => {
            window.addEventListener("scroll", checkScroll);
        }
    }, [lastScrolly])
    const checkScroll = () => {
        setLastScrolly(window.scrollY);
    }
    const getHomeTitle = () => {
        fetchDataFromApi('get-home-title').then((res) => {
            if (res.status === 1) {
                setHTitle(...[res.data]);
            }
        })
    }
    return (
        <div className="homeSection">
            <MetaTags>
                <title>{htitle?.page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={htitle?.meta_title} />
                <meta name="description" content={htitle?.meta_description} />
                <meta name="keywords" content={htitle?.meta_keyword} />
                <meta property="og:url" content="https://www.satkartar.in/" />
                <meta property="og:title" content="Sat Kartar Shopping | Buy Ayurvedic Medicines &amp;amp; Health Products Online" />
            </MetaTags>
            <HeroBanner />
            <BestSeller />
            <HealthCare />
            {lastScrolly > 500 ?
                <>
                    <TopSelling />
                </>
                :
                ""
            }
            {lastScrolly > 800 ?
                <>
                    <AuthenticProduct />
                    <NewArrival />
                    <TalkToExpert />
                    <HerbalProducts />
                    <AyurvedicProducts />
                    <Vitamin />
                    <Testimonial />
                </>
                :
                ""
            }
            {lastScrolly > 1200 ?
                <>
                    <HomeBlog />
                    <FooterContent />
                </>
                :
                ""
            }
        </div>
    )
}

export default Home
