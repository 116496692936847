import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import TCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { MdOutlineStarHalf, MdOutlineStarPurple500 } from 'react-icons/md';
import { fetchDataFromApi } from '../utils/api';

const Testimonial = () => {
    const [testimonial, setTestimonial] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/testimonial/";
    useEffect(() => {
        getTestimonial();
    }, []);

    const getTestimonial = () => {
        fetchDataFromApi('home-testimonial').then((res) => {
            if (res.status === 1) {
                setTestimonial(...[res.data]);
            }
        })
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="testimonial mt-4 bg-white">
            <ContentWrapper>
                <div className="col-lg-12 descktopview">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">
                            What Our Customer Say About Us
                        </h2>
                        <p className="text-justify">We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                    </div>
                    <TCarousel responsive={responsive} infinite={true} autoPlay={true} className="testimonialList">
                        {testimonial.map((testimonial, index) => (
                            <div className="htestmonial" key={index}>
                                <div className="single-reviews-box mlr-5">
                                    <div className="reviews-top-content">
                                        <div className="client-img">
                                            <img src={BASE_URL + testimonial?.test_img} alt={testimonial?.test_img} title={testimonial?.test_img} width="50" height="50" style={{ height: "auto" }} />
                                        </div>
                                        <p>{testimonial?.test_desc}</p>
                                    </div>
                                    <div className="reviews-bottom-content">
                                        <div className="row align-items-center">
                                            <div className="col-7">
                                                <h3>{testimonial?.test_name}</h3>
                                                <p>{testimonial?.test_location}</p>
                                            </div>
                                            <div className="col-5">
                                                <ul>
                                                    <MdOutlineStarPurple500 />
                                                    <MdOutlineStarPurple500 />
                                                    <MdOutlineStarPurple500 />
                                                    <MdOutlineStarPurple500 />
                                                    <MdOutlineStarHalf />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TCarousel>
                </div>
                <div className="col-lg-12 mobileview">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">
                            What Our Customer Say About Us
                        </h2>
                        <p className="text-justify">We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>

                        <div className="mobileviewTestimonial">
                            {testimonial.map((testimonial, index) => (
                                <div className="htestmonial" key={index}>
                                    <div className="single-reviews-box mlr-5">
                                        <div className="reviews-top-content">
                                            <div className="client-img">
                                                <img src={BASE_URL + testimonial?.test_img} alt={testimonial?.test_img} title={testimonial?.test_img} width="50" height="50" style={{ height: "auto" }} />
                                            </div>
                                            <p>{testimonial?.test_desc}</p>
                                        </div>
                                        <div className="reviews-bottom-content">
                                            <div className="row align-items-center">
                                                <div className="col-12">
                                                    <h3>{testimonial?.test_name}</h3>
                                                    <p>{testimonial?.test_location}</p>
                                                </div>
                                                <div className="col-12">
                                                    <ul>
                                                        <MdOutlineStarPurple500 />
                                                        <MdOutlineStarPurple500 />
                                                        <MdOutlineStarPurple500 />
                                                        <MdOutlineStarPurple500 />
                                                        <MdOutlineStarHalf />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Testimonial
