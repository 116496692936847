import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InCarousel from "react-multi-carousel";
import LpTCarousel from "react-multi-carousel";
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { toast } from 'react-toastify'
import { useCart } from 'react-use-cart'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { numberFormat } from '../components/numberFormate';
import { getLandingPageProductDetailApi, getMultitpleDataApi, getSingleDataApi } from '../utils/api'
import ayurvedic from "../assets/landingpage/100-safe-and-natural.webp";
import codimg from "../assets/landingpage/cash-on-delivery.webp";
import freece from "../assets/landingpage/Free-expert-consultation.webp";
import freeshipping from "../assets/landingpage/free-shipping-in-india.webp";
import { AiFillStar, AiOutlineCheckSquare, AiOutlineShoppingCart } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import gmp from "../assets/landingpage/why-gmp-certified.webp";
import natural from "../assets/landingpage/why-100-natural.webp";
import ayush from "../assets/landingpage/why-ayush-certified.webp";
import cod from "../assets/landingpage/why-cash-on-delivery.webp";
import { MdOutlineStarHalf, MdOutlineStarPurple500 } from 'react-icons/md';
import { HiPhoneMissedCall } from 'react-icons/hi';
import TagManager from 'react-gtm-module';
import { MetaTags } from 'react-meta-tags';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const AyurvedaProductDetails = () => {
    // Start Content Menu
    const [activeSection, setActiveSection] = useState(null);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const sections = useRef([]);
    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop && pageYOffset
                < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };
    // End Content Menu

    const { addItem } = useCart();
    const navigate = useNavigate();
    const addToCart = (productd) => {
        addItem(productd);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd.price,
                    ecomm_pagetype: "Cart",
                    value: productd.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd.id,
                            name: productd.name,
                            brand: "Satkartar",
                            price: productd.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (productd) => {
        addItem(productd);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd.price,
                    ecomm_pagetype: "Begin Checkout",
                    value: productd.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd.id,
                            name: productd.name,
                            brand: "Satkartar",
                            price: productd.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if(userid){
            navigate("/delivery-address");
        }
        else{
            navigate("/checkout-login");
        }
    }
    
    const [productd, setProductd] = useState([]);
    const productid = productd?.id;
    const [mimage, setMImage] = useState([]);
    const [highliter, setHighliter] = useState([]);
    const [overview, setOverview] = useState([]);
    const [benefittitle, setBenefitTitle] = useState([]);
    const [benefit, setBenefit] = useState([]);
    const [howtousetitle, setHowtouseTitle] = useState([]);
    const [howtouse, setHowtouse] = useState([]);
    const [ingredienttitle, setIngredientTitle] = useState([]);
    const [ingredient, setIngredient] = useState([]);
    const [productspecstitle, setProductdSpecsTitle] = useState([]);
    const [productspecs, setProductdSpecs] = useState([]);
    const [faqtitle, setFAQTitle] = useState([]);
    const [faq, setFAQ] = useState([]);
    const [reviewtitle, setReviewTitle] = useState([]);
    const [review, setReview] = useState([]);

    const { slug } = useParams();
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const BASE_URL_BENEFITS = "https://backendsatkartar.satkartar.co.in/assets/img/benefits/";
    const BASE_URL_HOWTOUSE = "https://backendsatkartar.satkartar.co.in/assets/img/howtouse/";
    const BASE_URL_INGREDIENT = "https://backendsatkartar.satkartar.co.in/assets/img/ingredient/";

    // Content Menu
    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // End Content Menu
    useEffect(() => {
        getProductDetail(slug);
        if (productid) {
            getProductMoreImage(productid);
            getLandingPageHighliter(productid);
            getLandingPageOverview(productid);
            getLandingPageBenefitsTitle(productid);
            getLandingPageBenefits(productid);
            getLandingPageHowToUseTitle(productid);
            getLandingPageHowToUse(productid);
            getLandingPageIngredientTitle(productid);
            getLandingPageIngredient(productid);
            getLandingPageProductSpecsTitle(productid);
            getLandingPageProductSpecs(productid);
            getLandingPageFAQTitle(productid);
            getLandingPageFAQ(productid);
            getLandingPageReviewTitle(productid);
            getLandingPageReview(productid);
        }
    }, [slug, productid])

    const activeStyle = {
        fontWeight: 'bold',
        color: 'red',
    };

    const getProductDetail = (slug) => {
        getLandingPageProductDetailApi(`get-vitamins-nutrition-product-details/${slug}`).then((res) => {
            setProductd(...[res.data?.product]);
        })
    }
    const getProductMoreImage = (productid) => {
        getMultitpleDataApi(`get-landing-page-multiple-image-data/${productid}`).then((res) => {
            setMImage(...[res.data?.pimage]);
        })
    }
    const getLandingPageHighliter = (productid) => {
        getMultitpleDataApi(`get-landing-page-highliter-data/${productid}`).then((res) => {
            setHighliter(...[res.data?.highliter]);
        })
    }
    const getLandingPageOverview = (productid) => {
        getSingleDataApi(`get-landing-page-overview-data/${productid}`).then((res) => {
            setOverview(...[res.data?.overview]);
        })
    }
    const getLandingPageBenefitsTitle = (productid) => {
        getSingleDataApi(`get-landing-page-benefits-title/${productid}`).then((res) => {
            setBenefitTitle(...[res.data?.data]);
        })
    }
    const getLandingPageBenefits = (productid) => {
        getMultitpleDataApi(`get-landing-page-benefits/${productid}`).then((res) => {
            setBenefit(...[res.data?.benefit]);
        })
    }
    const getLandingPageHowToUseTitle = (productid) => {
        getSingleDataApi(`get-landing-page-howtouse-title/${productid}`).then((res) => {
            setHowtouseTitle(...[res.data?.howtousetitle]);
        })
    }
    const getLandingPageHowToUse = (productid) => {
        getMultitpleDataApi(`get-landing-page-howtouse/${productid}`).then((res) => {
            setHowtouse(...[res.data?.howtouse]);
        })
    }
    const getLandingPageIngredientTitle = (productid) => {
        getSingleDataApi(`get-landing-page-ingredient-title/${productid}`).then((res) => {
            setIngredientTitle(...[res.data?.ingredienttitle]);
        })
    }
    const getLandingPageIngredient = (productid) => {
        getMultitpleDataApi(`get-landing-page-ingredient/${productid}`).then((res) => {
            setIngredient(...[res.data?.ingredient]);
        })
    }
    const getLandingPageProductSpecsTitle = (productid) => {
        getSingleDataApi(`get-landing-page-productspecs-title/${productid}`).then((res) => {
            setProductdSpecsTitle(...[res.data?.productspecstitle]);
        })
    }
    const getLandingPageProductSpecs = (productid) => {
        getMultitpleDataApi(`get-landing-page-productspecs/${productid}`).then((res) => {
            setProductdSpecs(...[res.data?.productspecs]);
        })
    }
    const getLandingPageFAQTitle = (productid) => {
        getSingleDataApi(`get-landing-page-faq-title/${productid}`).then((res) => {
            setFAQTitle(...[res.data?.faqtitle]);
        })
    }
    const getLandingPageFAQ = (productid) => {
        getMultitpleDataApi(`get-landing-page-faq/${productid}`).then((res) => {
            setFAQ(...[res.data?.faq]);
        })
    }
    const getLandingPageReviewTitle = (productid) => {
        getSingleDataApi(`get-landing-page-review-title/${productid}`).then((res) => {
            setReviewTitle(...[res.data?.reviewtitle]);
        })
    }
    const getLandingPageReview = (productid) => {
        getMultitpleDataApi(`get-landing-page-review/${productid}`).then((res) => {
            setReview(...[res.data?.review]);
        })
    }
    let Schemadata = {
        "@context": "https://schema.org",
        "@type": "Product",
        "aggregateRating": {
            "@type": "AggregateRating",
            "bestRating": "5",
            "ratingValue": productd?.product_rating
        },
        "name": productd?.name,
        "image": BASE_URL + productd?.image,
        "description": productd?.short_desc,
        "offers": {
            "@type": "AggregateOffer",
            "priceCurrency": "INR",
            "availability": productd?.product_availability,
            "highPrice": numberFormat(productd?.price),
            "lowPrice": numberFormat(productd?.price),
            "offerCount": productd?.product_save,
        }
    }

    return (
        <div className="productDetailSection bg-white">
            <MetaTags>
                <meta charSet="utf-8" />
                <title>{productd?.page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="robots" content="noindex,nofollow" />
                <meta name="title" content={productd?.meta_title} />
                <meta name="description" content={productd?.meta_description} />
                <meta name="keywords" content={productd?.meta_keyword} />
                <meta property="og:description" content={productd?.meta_description} />
                <link rel="preload" fetchpriority="high" as="image" href={BASE_URL + productd?.image} type="image/webp"></link>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(Schemadata) }}
                />
            </MetaTags>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="items product">
                            <span>{productd?.name}</span>
                        </li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row productDetailsSection productLandingDetailsSection lpProductDetailsSection">
                        <div className="col-md-6 col-sm-12 productBanner productLandingBanner">
                            <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false} swipeable={false} showStatus={false}>
                                <div>
                                    <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                </div>
                                {mimage?.map((mimage, index) => (
                                    <div key={index}>
                                        <img rel="preload" fetchpriority="high" as="image" alt={mimage?.product_image} src={BASE_URL + mimage?.product_image} />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="col-md-6 col-sm-12 productContDetail productLangDetail">
                            <div className="productDetails">
                                <h1 className="sk-turbo-treats-shilajit-gummies-title">{productd?.name}</h1>
                                <hr />
                                <div className="reviewsBottomContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="inner-shop-details-meta">
                                                <ul>
                                                    <li>Pack Type : <b style={{ color: "#000" }}>Starter Pack</b></li>
                                                    <li className="inner-shop-details-review d-flex">
                                                        <b className="trating">
                                                            65487 ratings
                                                        </b>
                                                    </li>
                                                    <li className="inner-shop-details-review d-flex">
                                                        <div className="rating">
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <BsStarHalf />
                                                        </div>
                                                        <span>({productd?.product_rating}★)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="product-details">
                                                <ul>
                                                    {highliter?.map((highliter, index) => (
                                                        <li key={index}>
                                                            <AiOutlineCheckSquare className="productDescCheck" />
                                                            <span>{highliter?.highlighter}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="pro-availabale">
                                                <span className="available"><b>Availability:</b></span>
                                                <span className="pro-instock" style={{ color: "green" }}> {productd?.product_availability}</span>
                                            </div>
                                            <span className="pro-details">{productd?.product_tag_line}</span>
                                            <div className="pro-price">
                                                <span className="new-price" >{numberFormat(productd?.price)}</span>
                                                <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                                <div className="Pro-lable">
                                                    <span className="lp-discount">Save {productd?.product_save}</span>
                                                    <p>Limited Offer (inclusive of all taxes)</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="productBtn">
                                                    <button onClick={() => addToCart(productd)} className="addToCart">Add To Cart</button>
                                                    <button onClick={() => buyItNow(productd)} className="buyItNow">Buy It Now</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="landinPpaymentType">
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={freece} alt="Free Expert Consultation" />
                                                        <h6>Free Expert Consultation</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={freeshipping} alt="Free Shipping on Prepaid" />
                                                        <h6>Free Shipping on Prepaid</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={ayurvedic} alt="100 % Ayurvedic" />
                                                        <h6>100 % Ayurvedic</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={codimg} alt="Cash On Delivery" />
                                                        <h6>Cash On Delivery</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 mt-4  sectionMenu">
                <ContentWrapper>
                    <div className="col-md-12 col-sm-12">
                        <div className="sectionMenu">
                            <ul className="productDetailsMenu">
                                <li className={activeSection === 'Overview' ? 'active' : ''}><a href="#Overview" style={activeSection ===
                                    'Overview' ? activeStyle : {}}>Overview<span>|</span></a></li>
                                <li className={activeSection === 'Benefits' ? 'active' : ''}><a href="#Benefits" style={activeSection ===
                                    'Benefits' ? activeStyle : {}}>Benefits<span>|</span></a></li>
                                <li className={activeSection === 'Howtouse' ? 'active' : ''}><a href="#Howtouse" style={activeSection ===
                                    'Howtouse' ? activeStyle : {}}>How To Use<span>|</span></a></li>
                                <li className={activeSection === 'Ingredients' ? 'active' : ''}><a href="#Ingredients" style={activeSection ===
                                    'Ingredients' ? activeStyle : {}}>Ingredients<span>|</span></a></li>
                                <li className={activeSection === 'ProductSpece' ? 'active' : ''}><a href="#ProductSpece" style={activeSection ===
                                    'ProductSpece' ? activeStyle : {}}>Product Specs<span>|</span></a></li>
                                <li className={activeSection === 'faqs' ? 'active' : ''}><a href="#faqs" style={activeSection ===
                                    'faqs' ? activeStyle : {}}>FAQs<span>|</span></a></li>
                                <li className={activeSection === 'Reviews' ? 'active' : ''}><a href="#Reviews" style={activeSection ===
                                    'Reviews' ? activeStyle : {}}>Reviews<span>|</span></a></li>
                                <li className={activeSection === 'Whyuse' ? 'active' : ''}><a href="#Whyuse" style={activeSection ===
                                    'Whyuse' ? activeStyle : {}}>Why Us</a></li>
                            </ul>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-9 col-sm-12 productDetailSection">
                            {/* // Overview */}
                            <div data-section id="Overview">
                                <div className="overviewDetails">
                                    <h2 className="heading" style={{ fontWeight: "600" }}>{overview?.overview_title}</h2>
                                    <div className="col-md-12">
                                        <div className="processBox" >
                                            <div className="servicesBox">
                                                <p className="mr-0">
                                                    <div dangerouslySetInnerHTML={{ __html: overview?.overview_desc }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* // Benefits */}
                            <div data-section id="Benefits" className="mt-4">
                                <div className="keybenefits">
                                    <h2 className="heading">{benefittitle?.benefits_title}</h2>
                                    <div className="col-lg-12">
                                        {benefit?.map((benefit, index) => (
                                            <div className="keybenefitscard" key={index}>
                                                <div className="imgSection">
                                                    <img src={BASE_URL_BENEFITS + benefit?.benefit_image} alt={benefit?.benefit_image} />
                                                </div>
                                                <div className="keyBenefitsDescp">
                                                    <h2>{benefit?.benefit_title}</h2>
                                                    <p>{benefit?.benefits_desc}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* // How To Use */}
                            <div data-section id="Howtouse">
                                <div className="Howtouse">
                                    <h2 className="heading">{howtousetitle?.howtouse_title}</h2>
                                    <div className="col-lg-12">
                                        {howtouse.map((howtouse, index) => (
                                            <div className="howToUseCard" key={index}>
                                                <div className="howImage">
                                                    <img src={BASE_URL_HOWTOUSE + howtouse?.howtouse_image} alt={howtouse?.howtouse_image} />
                                                </div>
                                                <div className="howDesc">
                                                    <h4>{howtouse?.howtouse_title}</h4>
                                                    <p>{howtouse?.howtouse_desc}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Ingredient */}
                            <div data-section id="Ingredients" className="mt-4">
                                <div className="lpIngredient">
                                    <h2 className="heading">{ingredienttitle?.ingredient_title}</h2>
                                    <InCarousel arrows={false} responsive={responsive} infinite={true} className="lpIngredientList" showDots={true} autoPlay={true}>
                                        {ingredient.map((ingredient, index) => (
                                            <div className="lpIngredientcard" key={index}>
                                                <div className="imgSection">
                                                    <img src={BASE_URL_INGREDIENT + ingredient?.ingredient_image} alt={ingredient?.ingredient_image} />
                                                </div>
                                                <div className="lpIngredientDescp">
                                                    <h2>{ingredient?.ingredient_title}</h2>
                                                    <p>{ingredient?.ingredient_desc}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </InCarousel>
                                </div>
                            </div>
                            {/* Product Specs */}
                            <div data-section id="ProductSpece" className="mt-4">
                                <div className="productSpece">
                                    <h2 className="heading">{productspecstitle?.productspecs_title}</h2>
                                    <div className="productSpeceDescription">
                                        <table className="table table-responsive">
                                            <div>
                                                {productspecs.map((productspecs, index) => (
                                                    <tr key={index}>
                                                        <th><b>{productspecs?.productspecs_title}:</b></th>
                                                        <th>{productspecs?.productspecs_desc}</th>
                                                    </tr>
                                                ))}
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* FAQ */}
                            <div data-section id="faqs" className="mt-4">
                                <div className="lpFaq">
                                    <h2 className="heading">{faqtitle?.faq_title}</h2>
                                    <Accordion>
                                        {faq.map((faq, index) => (
                                            <AccordionItem className="mt-4" key={index}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {faq?.faq_title}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {faq?.faq_desc}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                            {/* Review */}
                            <div data-section id="Reviews" className="mt-4">
                                <div className="lpReviews">
                                    <h2 className="heading">{reviewtitle?.reviews_title}</h2>
                                    <LpTCarousel arrows={false} responsive={responsive}
                                        infinite={true} autoPlay={true} className="testimonialList" showDots={true}>
                                        {review.map((review, index) => (
                                            <div className="htestmonial" key={index}>
                                                <div className="single-reviews-box single-reviews-lading mlr-5">
                                                    <div className="reviews-top-content reviewTop">
                                                        <p>{review?.reviews_desc}</p>
                                                    </div>
                                                    <div className="reviews-bottom-content">
                                                        <div className="row align-items-center">
                                                            <div className="col-12">
                                                                <h3>{review?.reviews_name}</h3>
                                                                <div style={{ display: "flex" }}>
                                                                    <p>{review?.reviews_rating}/5</p>
                                                                    <ul>
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarHalf />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </LpTCarousel>
                                </div>
                            </div>
                            <div data-section id="Whyuse" className="mt-4">
                                <div className="lpWhyuse">
                                    <h2 className="heading">Why Us</h2>
                                    <div className="col-lg-12 lpWhyUseMain">
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={gmp} alt={gmp} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>GMP Certified</h4>
                                                <p>Delivering high standard & authentic ayurvedic products</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={natural} alt={natural} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Pure Organic</h4>
                                                <p>Ingredients sourced indigenously from mountains & forests</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={ayush} alt={ayush} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Ayush Approved</h4>
                                                <p>Approved by Ministry of Ayush, Government of india</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={cod} alt={cod} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Full Convenience</h4>
                                                <p>All India free shipping & cashon delivery option available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 productDetailBtn">
                            <div className="stickyBuySection">
                                <h4>{productd?.name}</h4>
                                <p>{`${productd?.short_desc}... `}</p>
                                <div className="pro-price mb-4">
                                    <span className="new-price">{numberFormat(productd?.price)}</span>
                                    <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                    <div className="Pro-lable">
                                        <span className="p-discount">Save {productd?.product_save}</span>
                                    </div>
                                </div>
                                <button onClick={() => buyItNow(productd)}>Buy It Now</button>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <section className="fact-area mob">
                <div className="container">
                    <div className="col-12 col-lg-12 pt-1">
                        <div className="">
                            <h4 className="sticky-title">{productd?.name}</h4>
                        </div>
                    </div>
                    <div className="row text-center mb-2">
                        <div className="col-4 col-lg-4 pt-1 d-flex">
                            <div>
                                <span className="sticky-price"><b>{numberFormat(productd?.price)}</b></span>
                            </div>
                        </div>
                        <div className="col-8 col-lg-8 pt-1 d-flex" style={{ justifyContent: "start" }}>
                            <div className="sticky-buy-now" id="cartsticky">
                                <span className="">
                                    <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                </span>
                                <button onClick={() => buyItNow(productd)}>Buy Now</button>
                            </div>
                            <div className="callNow">
                                <Link to="tel:9319728256"><HiPhoneMissedCall /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AyurvedaProductDetails