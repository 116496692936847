import React, { useEffect, useState, useRef } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper';
import Logo from "../assets/logo.webp";
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { BsFillCartCheckFill, BsTrash3 } from "react-icons/bs";
import { AiFillCloseCircle, AiFillHome, AiOutlineCloseCircle, AiOutlineMinus, AiOutlinePlus, AiOutlineShoppingCart } from "react-icons/ai";
import { FaQuestionCircle, FaProductHunt, FaMicroblog, FaShoppingBasket, FaUserAlt, FaNutritionix, FaMicrophone } from "react-icons/fa";
import { MdHealthAndSafety } from "react-icons/md";
import { SiAboutdotme } from "react-icons/si";
import { BiLogInCircle } from "react-icons/bi";
import { BsFillTrash3Fill } from "react-icons/bs";
import { SlMenu } from "react-icons/sl";
import { useCart } from 'react-use-cart'
import { numberFormat } from './numberFormate';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { getSingleDataApi } from '../utils/api';
import TagManager from 'react-gtm-module';
import 'react-tooltip/dist/react-tooltip.css'
import { IoSearchOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FaUserDoctor } from 'react-icons/fa6';

const initialState = {
    search_name: '',
}

const MegaMenuBar = () => {
    const [paramurl, setParamURL] = useState(null);
    const pathnames = window.location.href.split("/").pop();
    const pathname = window.location.href;
    const [searchValues, setSearchValues] = useState(initialState);
    const navigate = useNavigate();

    const {
        items,
        totalUniqueItems,
        updateItemQuantity,
        cartTotal,
        removeItem
    } = useCart();

    const removeItems = (id) => {
        removeItem(id);
        TagManager.dataLayer({
            dataLayer: {
                event: "remove_from_cart",
                pagePath: pathname,
                eventModel: {
                    ecomm_totalvalue: cartTotal,
                    ecomm_pagetype: "Remove Cart Item",
                    value: cartTotal,
                    currency: "INR",
                    items
                }
            },
        });
    };

    const [mobileMenu, setMobileMenu] = useState(false);
    const [mobileCartItem, setMobileCartItem] = useState(false);
    const [cartItem, setCartItem] = useState(false);
    const [show, setShow] = useState("top");
    const [lastScrolly, setLastScrolly] = useState(0);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    const controlNavbar = () => {
        if (window.scrollY > 40) {
            if (window.screenY > lastScrolly) {
                setShow("hide");
            }
            else {
                setShow("show");
            }
        }
        else {
            setShow("top");
        }
        setLastScrolly(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.addEventListener("scroll", controlNavbar);
        }
    }, [lastScrolly]);

    useEffect(() => {
        matchedTopBarParam(pathnames);
    }, [pathnames]);

    const matchedTopBarParam = (pathnames) => {
        if (pathnames) {
            getSingleDataApi(`landing-page-matched-params-urls/${pathnames}`).then((res) => {
                setParamURL(...[res.data?.paramsurl]);
            })
        }
    }

    const openCartItem = () => {
        setCartItem(true);
    }
    const openMobileMenu = () => {
        setMobileMenu(true)
    }
    const clickMobileMegaMenu = (url) => {
        setMobileMenu(false)
        navigate(url);
    }
    const openMobileCartItem = () => {
        setMobileCartItem(true);
    }
    const clickToDeskProceedCheckout = (checkurl) => {
        setCartItem(false)
        navigate(checkurl);
    }
    const clickToProceedCheckout = (checkurl) => {
        setMobileCartItem(false)
        navigate(checkurl);
    }

    const handleInputs = e => {
        setSearchValues({
            ...searchValues,
            [e.target.name]: e.target.value,
        })
    }
    const {
        transcript,
        resetTranscript
    } = useSpeechRecognition();

    const handleStartListining = () => {
        ref.current.value = '';
        setSearchValues(false);
        SpeechRecognition.startListening()
    }
    if (transcript) {
        setTimeout(() => {
            navigate(`/search?q=${transcript}`);
            resetTranscript()
        }, 2000);
        clearTimeout();
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        navigate(`/search?q=${searchValues.search_name}`);
        //console.log(`/search?q=${searchValues.search_name}`)
    }
    const ref = useRef(null);
    const handleRemoveSearch = () => {
        ref.current.value = '';
        setSearchValues(false);
        resetTranscript()
    }

    return (
        <>
            {(() => {
                if (paramurl?.product_type == "Landing Page" || paramurl?.product_type == "LP" || paramurl?.product_type == "New Landing Page") {
                    return (
                        <div className="dMegaMenu" >
                            <ContentWrapper>
                                <div className="MegaMenu lpmegamenu">
                                    <div className="headerLogo landingPageLogo">
                                        <img fetchpriority="high" src={Logo} alt="Sat Kartar Shopping: Authentic Ayurveda Products & Herbal Medicines Online" title="Logo" />
                                    </div>

                                    <div className="menuLink">

                                    </div>

                                    <div className="mobileMenuItems">

                                    </div>
                                    <div className={`${mobileMenu ? "mobileMenu" : "mobileLeftMenu"}`}>
                                        <div className="mobileTopBar">
                                            <div className="yourCartCounter">

                                            </div>
                                            {setMobileMenu ? (<div className="mobileCloseBtn">
                                                <AiFillCloseCircle className="closeBtn" onClick={() => setMobileMenu(false)} />
                                            </div>
                                            ) : (
                                                <div>
                                                </div>
                                            )}
                                        </div>
                                        <ul>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/"><AiFillHome className="menuMobileIcons" />Home</NavLink></li>
                                            {/* <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/products')} to="/products"><FaProductHunt className="menuMobileIcons" />Product</NavLink></li> */}
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/ayurveda-products')} to="/ayurveda-products"><FaProductHunt className="menuMobileIcons" />Ayurveda Products</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/vitamin-and-supplements')} to="/vitamin-and-supplements"><FaNutritionix className="menuMobileIcons" />Vitamin & Supplements</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/herbal')} to="/ingredients"><FaNutritionix className="menuMobileIcons" />Herbal</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/shop-by-concern')} to="/shop-by-concern"><MdHealthAndSafety className="menuMobileIcons" />Shop By Concern</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/faqs')} to="/faqs"><FaQuestionCircle className="menuMobileIcons" />FAQS</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us"><SiAboutdotme className="menuMobileIcons" />About</NavLink></li>
                                            <li><NavLink activeclassname="active" target="_blank" to="https://www.satkartar.co.in/blog/"><FaMicroblog className="menuMobileIcons" />Blog</NavLink></li>
                                            {localStorage.getItem('userdata') ?
                                                <li><Link onClick={() => clickMobileMegaMenu('/my-account')} to="/my-account"><FaUserAlt className="menuMobileIcons" />My Account</Link></li>
                                                :
                                                <li><Link onClick={() => clickMobileMegaMenu('/login')} to="/login"><BiLogInCircle className="menuMobileIcons" />Login</Link></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </ContentWrapper>
                        </div>
                    )
                }
                else {
                    return (
                        <div className={`dMegaMenu ${show}`}>
                            <ContentWrapper>
                                <div className="MegaMenu">
                                    <div className="headerLogo">
                                        <Link to="/">
                                            <img fetchpriority="high" src={Logo} alt="Sat Kartar Shopping: Authentic Ayurveda Products & Herbal Medicines Online" title="Logo" />
                                        </Link>
                                    </div>
                                    <div className="headerSearchBar">
                                        <form method="GET" onSubmit={handleSearchSubmit} name="site-search" role="search">
                                            <input onChange={handleInputs} type="text" name="search_name" placeholder="Search for Medicines and Health Products" />
                                            <div className="searchtbn">
                                                {searchValues ?
                                                    <button type="submit" className="searchiconssubmit">
                                                        <IoSearchOutline className="header_search_icon" />
                                                    </button> :
                                                    <button disabled type="submit" className="searchiconssubmit">
                                                        <IoSearchOutline className="header_search_icon" />
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                        <ul>
                                            {/* <li><NavLink activeclassname="active" to="/products">Product</NavLink></li> */}
                                            <li><NavLink activeclassname="active" to="/ayurveda-products">Ayurveda Products</NavLink></li>
                                            <li><NavLink activeclassname="active" to="/vitamin-and-supplements">Vitamin & Supplements</NavLink></li>
                                            <li><NavLink activeclassname="active" to="/herbal">Herbal</NavLink></li>
                                            <li><NavLink activeclassname="active" to="/ingredients">Ingredients</NavLink></li>
                                            <li><NavLink activeclassname="active" to="/shop-by-concern">Shop By Concern</NavLink></li>
                                            <li><NavLink target="_blank" to="https://www.satkartar.co.in/blog/" id="my-anchor-element">Blog</NavLink></li>
                                        </ul>
                                    </div>
                                    <div className="topCartCounter" onClick={openCartItem}>
                                        <div className="yourCartCounter">
                                            <BsFillCartCheckFill className="cartIcons" />
                                            <span className="cartCounter">{totalUniqueItems}</span>
                                        </div>
                                        <div className="yourCart">
                                            <span className="textCart">TOTAL PRICE</span>
                                            <br />
                                            <span className="totalPriceCart">{numberFormat(cartTotal)}</span>
                                        </div>
                                    </div>
                                    <div className="mobileMenuItems">
                                        <button onClick={openMobileCartItem} className="cart-btn" title="Cartlist">
                                            <AiOutlineShoppingCart className="basketIcon" />
                                            <sup id="displaymycountcartfs">{totalUniqueItems}</sup>
                                        </button>
                                        <SlMenu className={`${mobileMenu ? "mobileMenuIcon" : "mobileMenuIcons"}`} onClick={openMobileMenu} />
                                    </div>
                                    <div className={`${mobileMenu ? "mobileMenu" : "mobileLeftMenu"}`}>
                                        <div className="mobileTopBar">
                                            <div className="yourCartCounter">

                                            </div>
                                            {setMobileMenu ? (<div className="mobileCloseBtn">
                                                <AiFillCloseCircle className="closeBtn" onClick={() => setMobileMenu(false)} />
                                            </div>
                                            ) : (
                                                <div>
                                                </div>
                                            )}
                                        </div>
                                        <ul>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/"><AiFillHome className="menuMobileIcons" />Home</NavLink></li>
                                            {/* <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/products')} to="/products"><FaProductHunt className="menuMobileIcons" />Product</NavLink></li> */}
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/ayurveda-products')} to="/ayurveda-products"><FaProductHunt className="menuMobileIcons" />Ayurveda Products</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/vitamin-and-supplements')} to="/vitamin-and-supplements"><FaNutritionix className="menuMobileIcons" />Vitamin & Supplements</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/herbal')} to="/herbal"><FaNutritionix className="menuMobileIcons" />Herbal</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/shop-by-concern')} to="/shop-by-concern"><MdHealthAndSafety className="menuMobileIcons" />Shop By Concern</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/talk-to-expert')} to="/talk-to-expert"><FaUserDoctor className="menuMobileIcons" />Talk to expert</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/faqs')} to="/faqs"><FaQuestionCircle className="menuMobileIcons" />FAQS</NavLink></li>
                                            <li><NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us"><SiAboutdotme className="menuMobileIcons" />About</NavLink></li>
                                            <li><NavLink activeclassname="active" target="_blank" to="https://www.satkartar.co.in/blog/"><FaMicroblog className="menuMobileIcons" />Blog</NavLink></li>
                                            {localStorage.getItem('userdata') ?
                                                <li><Link onClick={() => clickMobileMegaMenu('/my-account')} to="/my-account"><FaUserAlt className="menuMobileIcons" />My Account</Link></li>
                                                :
                                                <li><Link onClick={() => clickMobileMegaMenu('/login')} to="/login"><BiLogInCircle className="menuMobileIcons" />Login</Link></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="searchBardiv">
                                    <form method="GET" onSubmit={handleSearchSubmit} name="site-search" role="search">
                                        <input ref={ref} onChange={handleInputs} type="text" name="search_name" placeholder="Search for Medicines and Health Products" value={searchValues.search_name || transcript} />
                                        <div className="searchtbn">
                                            <div className="micIcons">
                                                {(() => {
                                                    if (searchValues.search_name) {
                                                        return (
                                                            <span onClick={handleRemoveSearch} className="searchiconssubmit btnenable 1">
                                                                <RxCross2 />
                                                            </span>
                                                        )
                                                    }
                                                    else if (transcript) {
                                                        return (
                                                            <span onClick={handleRemoveSearch} className="searchiconssubmit btnenable 2">
                                                                <RxCross2 />
                                                            </span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span onClick={handleStartListining}>
                                                                <FaMicrophone />
                                                            </span>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ContentWrapper>
                        </div>
                    )
                }
            })()}
            <div className="cartItems">
                <aside className={`cart-sidebar ${cartItem ? "activeCartItem" : ""}`}>
                    <div className="cart-header">
                        <div className="cart-total">
                            <FaShoppingBasket className="basketIcon" />
                            <span>total item (<span>{totalUniqueItems}</span>)</span>
                        </div>
                        <button className="cart-close">
                            <AiOutlineCloseCircle className="cartCloseIcon" onClick={() => setCartItem(false)} />
                        </button>
                    </div>
                    <ul className="cart-list">
                        {items?.map((item, index) => (
                            <li className="cart-item" key={index}>
                                <div className="cart-media">
                                    <a href="#">
                                        <img src={BASE_URL + item.image} alt={item.image} title={item.image} />
                                    </a>
                                </div>
                                <div className="cart-info-group">
                                    <div className="cart-info">
                                        <h6>{item.name}</h6>
                                        <p>Price - {numberFormat(item.price)}</p>
                                    </div>
                                    <div className="cart-action-group">
                                        <div className="product-action">
                                            <button className="action-minus deskminus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                <AiOutlineMinus className="cartQtyMinus" />
                                            </button>
                                            <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" value={item.quantity} pattern="[0-9]*" />
                                            <button onClick={() => updateItemQuantity(item.id, item.quantity + 1)} className="action-plus deskplus"><AiOutlinePlus className="cartQtyPlus" /></button>
                                        </div>
                                        <h6>{numberFormat(item.itemTotal)}</h6>
                                    </div>
                                </div>
                                <div className="cartTrash">
                                    <button onClick={() => removeItems(item.id)} className="cart-delete">
                                        <BsFillTrash3Fill className="cartItemTrash" />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="cart-footer">
                        {localStorage.getItem('userdata') ?
                            <a href="javascript:void(0)" className="cart-checkout-btn" onClick={() => clickToDeskProceedCheckout('/delivery-address')}>
                                <span className="checkout-label">Proceed to Checkout</span>&nbsp;
                                <span className="checkout-price">{numberFormat(cartTotal)}</span>
                            </a>
                            :
                            <a href="javascript:void(0)" className="cart-checkout-btn" onClick={() => clickToDeskProceedCheckout('/checkout-login')}>
                                <span className="checkout-label">Proceed to Checkout</span>&nbsp;
                                <span className="checkout-price">{numberFormat(cartTotal)}</span>
                            </a>
                        }
                    </div>
                </aside>
            </div>
            <div className="mobileSticky">
                <aside className={` mcart-sidebar ${mobileCartItem ? "actives" : ""}`}>
                    <div className="cart-header">
                        <div className="cart-total">
                            <FaShoppingBasket className="cartbasketIcon" />&nbsp;<span>total item ({totalUniqueItems})</span>
                        </div>
                        <button onClick={() => setMobileCartItem(false)} className="cart-close"><IoMdCloseCircleOutline /></button>
                    </div>
                    <div className="displaycartitem">
                        <ul className="cart-list">
                            {items?.map((item, index) => (
                                <li className="cart-item" key={index}>
                                    <div className="cart-media">
                                        <a href="#">
                                            <img src={BASE_URL + item.image} alt={item.image} />
                                        </a>
                                    </div>
                                    <div className="cart-info-group">
                                        <div className="cart-info">
                                            <div className="cartProductInfo">
                                                <h6>{item.name}</h6>
                                                <p>Price - {numberFormat(item.price)}</p>
                                            </div>
                                            <div className="cartItemRemove">
                                                <BsTrash3 onClick={() => removeItems(item.id)} />
                                            </div>
                                        </div>
                                        <div className="cart-action-group">
                                            <div className="product-action">
                                                <button className="action-minus" title="Quantity Minus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}><AiOutlineMinus /></button>
                                                <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" value={item.quantity} pattern="[0-9]*" />
                                                <button className="action-plus" title="Quantity Plus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}><AiOutlinePlus /></button>
                                            </div>
                                            <h6>{numberFormat(item.itemTotal)}</h6>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="cart-footer">
                            {localStorage.getItem('userdata') ?
                                <a className="cart-checkout-btn" onClick={() => clickToProceedCheckout('/delivery-address')}>
                                    <span className="checkout-label">Proceed to Checkout</span>&nbsp;
                                    <span className="checkout-price">{numberFormat(cartTotal)}</span>
                                </a>
                                :
                                <a className="cart-checkout-btn" onClick={() => clickToProceedCheckout('/checkout-login')}>
                                    <span className="checkout-label">Proceed to Checkout</span>&nbsp;
                                    <span className="checkout-price">{numberFormat(cartTotal)}</span>
                                </a>
                            }
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default MegaMenuBar
