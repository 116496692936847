import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import BCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from 'react-router-dom';
import { AiTwotoneStar } from "react-icons/ai";
import { fetchDataFromApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from './numberFormate';
import { useCart } from 'react-use-cart';
import TagManager from 'react-gtm-module';

const NewArrival = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const pathname = window.location.href;

    const [productTitle, setProductTitle] = useState([]);
    const [product, setProduct] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    useEffect(() => {
        getProductTitle();
        getProduct();
    }, [])

    const getProductTitle = () => {
        fetchDataFromApi('new-arrival-product-title').then((res) => {
            if (res.status === 1) {
                setProductTitle(...[res.data]);
            }
        })
    };
    const getProduct = () => {
        fetchDataFromApi('new-arrival-products').then((res) => {
            if (res.status === 1) {
                setProduct(...[res.data]);
            }
        })
    };
    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (product) => {
        addItem(product);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Begin Checkout",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="bestSellerSection mt-4">
            <ContentWrapper>
                <div className="col-lg-12 descktopview">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">{productTitle?.product_heading}</h2>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: productTitle.product_desc }}></div>
                    </div>
                    <BCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        {product?.map((product, index) => (
                            <div className="appSinglePricingCard" key={index}>
                                <div className="pricingTopContent">
                                    <div className="productImg">
                                        {(() => {
                                            if (product?.product_type === "Vitamin & Nutrition") {
                                                return (
                                                    <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                        <img width="292" height="292" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else if (product?.product_type === "Nutraceuticals") {
                                                return (
                                                    <Link to={`/herbal/${product?.product_slug_url}`}>
                                                        <img width="292" height="292" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Link to={`/product/${product?.product_slug_url}`}>
                                                        <img width="292" height="292" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <div className="pricingList">
                                        {(() => {
                                            if (product?.product_type === "Vitamin & Nutrition") {
                                                return (
                                                    <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                        <h4 className="title productnames">{product?.page_title}</h4>
                                                    </Link>
                                                )
                                            }
                                            else if (product?.product_type === "Nutraceuticals") {
                                                return (
                                                    <Link to={`/herbal/${product?.product_slug_url}`}>
                                                        <h4 className="title productnames">{product?.page_title}</h4>
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Link to={`/product/${product?.product_slug_url}`}>
                                                        <h4 className="title productnames">{product?.page_title}</h4>
                                                    </Link>
                                                )
                                            }
                                        })()}
                                        <p>
                                            {(() => {
                                                if (product?.price != 0) {
                                                    return (
                                                        <div className="productPrice">
                                                            <span>{numberFormat(product?.price)}</span>
                                                            <del>{numberFormat(product?.product_cut_price)}</del>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            <div className="productRatings">
                                                {product?.product_rating}
                                                <AiTwotoneStar className="ratingIcons" />
                                            </div>
                                        </p>
                                    </div>
                                    {(() => {
                                        if (product?.price != 0) {
                                            return (
                                                <div className="btnMainProduct">
                                                    <button onClick={() => addToCart(product)} className="addToCartBtn">Add To Cart</button>
                                                    <button onClick={() => buyItNow(product)} className="buyItNowBtn">Buy It Now</button>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        ))}
                    </BCarousel>
                </div>
                <div className="col-lg-12 mobileview">
                    <div className="appContainer">
                        <h1 className="text-center text-uppercase">{productTitle?.product_heading}</h1>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: productTitle.product_desc }}></div>
                    </div>
                    <div className="mobileviewBestSeller">
                        {product?.map((product, index) => (
                            <div className="appSinglePricingCard" key={index}>
                                <div className="pricingTopContent">
                                    <div className="productImg">
                                        {(() => {
                                            if (product?.product_type === "Vitamin & Nutrition") {
                                                return (
                                                    <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                        <img width="250" height="250" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else if (product?.product_type === "Nutraceuticals") {
                                                return (
                                                    <Link to={`/herbal/${product?.product_slug_url}`}>
                                                        <img width="250" height="250" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Link to={`/product/${product?.product_slug_url}`}>
                                                        <img width="250" height="250" title={product?.product_alt_name} src={BASE_URL + product?.image} alt={product?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                        })()}
                                    </div>
                                    {(() => {
                                        if (product?.product_type === "Vitamin & Nutrition") {
                                            return (
                                                <Link className="productNameMobile" to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                    <h4 className="title">{product?.page_title}</h4>
                                                </Link>
                                            )
                                        }
                                        else if (product?.product_type === "Nutraceuticals") {
                                            return (
                                                <Link className="productNameMobile" to={`/herbal/${product?.product_slug_url}`}>
                                                    <h4 className="title">{product?.page_title}</h4>
                                                </Link>
                                            )
                                        }
                                        else {
                                            return (
                                                <Link className="productNameMobile" to={`/product/${product?.product_slug_url}`}>
                                                    <h4 className="title">{product?.page_title}</h4>
                                                </Link>
                                            )
                                        }
                                    })()}
                                    <div className="bestSellterBtnPrice">
                                        <div className="pricingList">
                                            <p>
                                                {(() => {
                                                    if (product?.price != 0) {
                                                        return (
                                                            <div className="productPrice">
                                                                <span>{numberFormat(product?.price)}</span>
                                                                <del>{numberFormat(product?.product_cut_price)}</del>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <div className="productRatings">
                                                    {product?.product_rating}
                                                    <AiTwotoneStar className="ratingIcons" />
                                                </div>
                                            </p>
                                        </div>
                                        {(() => {
                                            if (product?.price != 0) {
                                                return (
                                                    <div className="btnMainProduct">
                                                        <button onClick={() => addToCart(product)} className="addToCartBtn">Add To Cart</button>
                                                        <button onClick={() => buyItNow(product)} className="buyItNowBtn">Buy It Now</button>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default NewArrival
