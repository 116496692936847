import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { addPaymentDataAPI } from '../utils/api'

const PaymentFailed = () => {
    const[paymentstatus, setPaymentstatus] = useState([]);
    const transactionId = "TR7156192751";

    useEffect(()=>{
        getPaymentStatus()
    },[])

    const getPaymentStatus = () => {
        const datas = { transactionId, transactionId };
        addPaymentDataAPI('check-payment-status', datas).then((res) => {
            if (res.data.status === 1) {
                setPaymentstatus(...[res.data.response.data])
            }
        })
    }
    return (
        <div className="orderConfirmation">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Payment Failed</title>
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page">
                                <span >Home</span>
                            </Link>
                        </li>
                        <li className="items product"><span>Payment Failed</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="failedBosySection">
                <ContentWrapper>
                    <div className="failedConfirms">
                        <div className="orderHeader">
                            <h3><b className="text-white">Your Payment has been failed!!...</b></h3>
                        </div>
                        <div className="failedsection">
                            <div className="failedbody">
                                <p>Amount</p>
                                <p>{paymentstatus?.amount/100}</p>
                            </div>
                            <div className="failedbody">
                                <p>Bank ID</p>
                                <p>{paymentstatus.paymentInstrument?.bankId}</p>
                            </div>
                            <div className="failedbody">
                                <p>Type</p>
                                <p>{paymentstatus?.paymentInstrument?.type}</p>
                            </div>
                            <div className="failedbody">
                                <p>Card Type</p>
                                <p>{paymentstatus?.paymentInstrument?.cardType}</p>
                            </div>
                            <div className="failedbody">
                                <p>Payment Status</p>
                                <p>{paymentstatus?.state}</p>
                            </div>
                            <div className="failedbody">
                                <p>Failed Tr ID</p>
                                <p>{paymentstatus?.merchantTransactionId}</p>
                            </div>
                        </div>
                        <Link className="btntryagain" to="/order-summary">Try Paying Again</Link>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default PaymentFailed