import React, { useState } from 'react'
import { useNavigate} from 'react-router-dom'
import { FaShoppingBasket } from "react-icons/fa";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useCart } from 'react-use-cart';
import { numberFormat } from './numberFormate';


const MobileSticky = () => {
    const {
        items,
        totalUniqueItems,
        updateItemQuantity,
        cartTotal,
    } = useCart();

    const { removeItem } = useCart();
    const removeItems = (id) => {
        removeItem(id);
    };

    const [mobileCartItem, setMobileCartItem] = useState(false);
    const navigate = useNavigate();
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    const openMobileCartItem = () => {
        setMobileCartItem(true);
    }
    const clickCheckout = () => {
        setMobileCartItem(false);
        navigate('/checkout')
    }

    return (
        <div className="mobileSticky">
            {/* <div className="mobileMenu">
                <Link aria-label="home" title="home" to="/"><AiOutlineHome /></Link>
                <Link aria-label="call now" title="call now" to="tel:+91-11-40550741"><HiOutlinePhoneMissedCall /></Link>
                <button onClick={openMobileCartItem} className="cart-btn" title="Cartlist">
                    <FaShoppingBasket className="basketIcon" />
                    <sup id="displaymycountcartfs">{totalUniqueItems}</sup>
                </button>
            </div> */}
            <aside className={` mcart-sidebar ${mobileCartItem ? "actives" : ""}`}>
                <div className="cart-header">
                    <div className="cart-total">
                        <FaShoppingBasket className="cartbasketIcon" />&nbsp;<span>total item ({totalUniqueItems})</span>
                    </div>
                    <button onClick={() => setMobileCartItem(false)} className="cart-close"><IoMdCloseCircleOutline /></button>
                </div>
                <div className="displaycartitem">
                    <ul className="cart-list">
                        {items?.map((item, index) => (
                            <li className="cart-item">
                                <div className="cart-media">
                                    <a href="#">
                                        <img src={BASE_URL + item.image} alt={item.image} />
                                    </a>
                                </div>
                                <div className="cart-info-group">
                                    <div className="cart-info">
                                        <div className="cartProductInfo">
                                            <h6>{item.name}</h6>
                                            <p>Price - {numberFormat(item.price)}</p>
                                        </div>
                                        <div className="cartItemRemove">
                                            <BsTrash3 onClick={() => removeItems(item.id)} />
                                        </div>
                                    </div>
                                    <div className="cart-action-group">
                                        <div className="product-action">
                                            <button className="action-minus" title="Quantity Minus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}><AiOutlineMinus /></button>
                                            <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" value={item.quantity} pattern="[0-9]*" />
                                            <button className="action-plus" title="Quantity Plus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}><AiOutlinePlus /></button>
                                        </div>
                                        <h6>{numberFormat(item.itemTotal)}</h6>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="cart-footer">
                        <a className="cart-checkout-btn" onClick={clickCheckout}>
                            <span className="checkout-label">Proceed to Checkout</span>
                            <span className="checkout-price">&nbsp;{numberFormat(cartTotal)}</span>
                        </a>
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default MobileSticky
