import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { AiTwotoneStar } from "react-icons/ai";
import Img from '../components/lazyLoadImage/Img';
import { fetchDataFromApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from '../components/numberFormate';
import { useCart } from 'react-use-cart';

const Combo = () => {
    const { addItem } = useCart();
    const naviaget = useNavigate();
    const pathname = window.location.href;
    const [producttitle, setProductTitle] = useState([]);

    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
    }

    const buyItNow = (product) => {
        addItem(product);
        naviaget("/checkout");
    }

    const [deals, setDeals] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    useEffect(() => {
        getDealsProduct();
        getProductPageTilte();
    }, [])
    const getProductPageTilte = () => {
        fetchDataFromApi('get-product-page-como-title').then((res) => {
            if (res.status === 1) {
                setProductTitle(...[res.data]);
            }
        })
    }
    const getDealsProduct = () => {
        fetchDataFromApi('home-combo-product').then((res) => {
            if (res.status === 1) {
                setDeals(...[res.data]);
            }
        })
    }
    return (
        <div className="dealsSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{producttitle?.product_heading}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={producttitle?.product_heading} />
                <meta name="description" content={producttitle?.product_desc} />
                <meta name="keywords" content="" />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={producttitle?.product_heading} />
                <meta property="og:description"
                    content={producttitle?.product_desc} />
                <meta name="description"
                    content={producttitle?.product_desc} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>{producttitle?.product_heading}</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 bg-white">
                <ContentWrapper>
                    <h1 className="text-center mb-4">{producttitle?.product_heading}</h1>
                    <p className="text-center">{producttitle?.product_desc}</p>
                    <div className="row">
                        {deals?.map((deals, index) => (
                            <div className="col-md-4 col-sm-12">
                                <div className="appSinglePricingCard" key={index}>
                                    <div className="pricingTopContent">
                                        <div className="productImg">
                                            <Link to={`/product/${deals?.product_slug_url}`}>
                                                <Img src={BASE_URL + deals?.image} alt={deals?.product_alt_name}></Img>
                                            </Link>
                                        </div>
                                        <div className="pricingList">
                                            <Link to={`/product/${deals?.product_slug_url}`}>
                                                <h4 className="title">{deals?.name}</h4>
                                            </Link>
                                            <p>
                                                <div className="productPrice">
                                                    <span content="INR"></span>
                                                    <span content={numberFormat(deals?.price)}>{numberFormat(deals?.price)}</span>
                                                    <del content={numberFormat(deals?.product_cut_price)}>{numberFormat(deals?.product_cut_price)}</del>
                                                </div>
                                                <div className="productRatings">
                                                    {deals?.product_rating}
                                                    <AiTwotoneStar className="ratingIcons" />
                                                </div>
                                            </p>
                                        </div>
                                        <div className="btnMainProduct">
                                            <button onClick={() => addToCart(deals)} className=" addToCartBtn">Add To Cart</button>
                                            <button onClick={() => buyItNow(deals)} className=" buyItNowBtn">Buy It Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Combo
