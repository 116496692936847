import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSingleDataApi, updateUserProfileAPi } from '../utils/api'
import Loading from '../components/Loading'
import { toast } from 'react-toastify'

const initialState = {
    delivery_name: '',
    delivery_mobile: '',
    delivery_addresse: '',
    delivery_landmark: '',
    delivery_city: '',
    delivery_pincode: '',
    delivery_state: '',
    delivery_country: '',
}

const EditDeliveryAddress = () => {
    const navigate = useNavigate();
    const pathname = window.location.href;
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const { slug } = useParams();
    const [values, setInputValues] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (userid) {
            getDeliveryAddressForEdit(slug)
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    const getDeliveryAddressForEdit = (slug) => {
        setIsLoading(true);
        getSingleDataApi(`get-delivery-address-for-edit/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setInputValues(...[res.data.address])
            }
            else {
                setIsLoading(false);
            }
        })
    }
    const updateDeliveryAddress = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            updateUserProfileAPi(`update-delivery-address/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    toast.success("Delivery address has been updated successfully!!");
                    navigate("/order-address");
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="myAccountSection bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="item home">
                            <Link to="/my-account" title="Go to Home Page"><span>My Account</span></Link>
                        </li>
                        <li className="item home">
                            <Link to="/order-address" title="Go to Home Page"><span>Delivery Address</span></Link>
                        </li>
                        <li className="items product"><span>Edit Delivery Address</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    {isLoading ?
                        <Loading />
                        :
                        <div className="addressbodysection">
                            <form method="post" enctype="multipart/form-data" onSubmit={updateDeliveryAddress}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_name">Name</label>
                                        <input name="delivery_name" id="delivery_name" onChange={handleInputs} placeholder="Enter Name..." className="form-control" type="text" required="true" value={values.delivery_name} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_mobile">Mobile</label>
                                        <input placeholder="Enter here..." type="text" name="delivery_mobile" id="delivery_mobile" className="form-control" value={values.delivery_mobile} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_country">Country</label>
                                        <input placeholder="Country" type="text" name="delivery_country" id="delivery_country" required="true" onChange={handleInputs} value={values.delivery_country} className="form-control" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_state">State</label>
                                        <input placeholder="State" type="text" name="delivery_state" id="delivery_state" required="true" onChange={handleInputs} className="form-control" value={values.delivery_state} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_city">City</label>
                                        <input placeholder="City" type="text" name="delivery_city" id="delivery_city" className="form-control" onChange={handleInputs} required="true" value={values.delivery_city} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_pincode">pincode</label>
                                        <input placeholder="Pincode" type="text" name="delivery_pincode" id="delivery_pincode" required="true" onChange={handleInputs} className="form-control" value={values.delivery_pincode} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_landmark">Landmark</label>
                                        <input placeholder="Pincode" type="text" name="delivery_landmark" id="delivery_landmark" required="true" onChange={handleInputs} className="form-control" value={values.delivery_landmark} />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 address">
                                        <label htmlFor="delivery_addresse">Address</label>
                                        <textarea type="text" rows={1} name="delivery_addresse" id="delivery_addresse" className="form-control" required="true" onChange={handleInputs} value={values.delivery_addresse}></textarea>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: "10px",textAlign:"center" }}>
                                        <button className="userBtn mt-0">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </ContentWrapper>
            </div>
        </div>
    )
}

export default EditDeliveryAddress