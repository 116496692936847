import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { Step, Stepper } from 'react-form-stepper';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { deliveryHareAPi, getSingleDataApi, userDeliveryAddressAPi } from '../utils/api';
import { useCart } from 'react-use-cart';
import TagManager from 'react-gtm-module';

const DeliveryAddress = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata')).id;
    const mobile = localStorage.getItem("usermobile");
    const [daddress, setDAddress] = useState([]);
    const referrerurl = localStorage.getItem("referrer");

    useEffect(() => {
        if (mobile) {
            getDeliveryAddressByUserNumber(mobile);
        }
        else {
            navigate("/login");
        }
    }, [mobile]);

    const {
        items,
        cartTotal,
    } = useCart();
    const cartdata = JSON.stringify(items);

    const [values, setUserValue] = useState({
        user_mobile: mobile,
        delivery_name: '',
        delivery_mobile: '',
        delivery_addresse: '',
        delivery_landmark: '',
        delivery_city: '',
        delivery_pincode: '',
        delivery_state: '',
        delivery_country: '',
        cart_data: cartdata,
        totalamt: cartTotal,
        userid: userid,
    });
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleDeliveryAddress = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                userDeliveryAddressAPi('add-delivery-address', values).then((res) => {
                    if (res.data.status === 1) {
                        localStorage.setItem("deliveryid", res.data.deliveryid);
                        localStorage.setItem("orderid", res.data.orderid);
                        TagManager.dataLayer({
                            dataLayer: {
                                event: "add_shipping_info",
                                pagePath: pathname,
                                eventModel: {
                                    ecomm_totalvalue: cartTotal,
                                    ecomm_pagetype: "Billing Address",
                                    value: cartTotal,
                                    currency: "INR",
                                    items
                                }
                            },
                        });
                        navigate('/order-summary');
                    }
                })
            }
            catch (error) {
                toast.error("Server internal error!!");
            }
        }
    }

    const getDeliveryAddressByUserNumber = (mobile) => {
        getSingleDataApi(`get-delivery-here-address/${mobile}`).then((res) => {
            if (res.data.status === 1) {
                setDAddress(...[res.data.address]);
            }
            else {
                setDAddress(false);
            }
        })
    }
    const handleRemoveAddress = (removeaddressid) => {
        getSingleDataApi(`remove-delivery-here-address/${removeaddressid}`).then((res) => {
            if (res.data.status === 1) {
                getDeliveryAddressByUserNumber(mobile);
                toast.success("Delivery address has been removed!")
            }
        })
    }
    const handleDeliveryHare = (deleiveryhereid) => {
        const datas = { 'deleiveryhereid': deleiveryhereid, 'cart_data': cartdata, 'userid': userid, 'totalamt': cartTotal, referrer: referrerurl };
        deliveryHareAPi("delivery-here-order", datas).then((res) => {
            if (res.data.status === 1) {
                localStorage.setItem("deliveryid", res.data.deliveryid);
                localStorage.setItem("orderid", res.data.orderid);
                TagManager.dataLayer({
                    dataLayer: {
                        event: "add_shipping_info",
                        pagePath: pathname,
                        userId: "SK-" + userid,
                        eventModel: {
                            ecomm_totalvalue: cartTotal,
                            ecomm_pagetype: "Billing Address",
                            value: cartTotal,
                            currency: "INR",
                            items
                        }
                    },
                });
                navigate('/order-summary');
            }
        })
    }
    return (
        <div className="deliverySection">
            <Helmet>
                <title>Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Delivery Address</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="spetbar-card">
                        <Stepper activeStep={1}>
                            <Step label="LOGIN" />
                            <Step label="DELIVERY ADDRESS" />
                            <Step label="ORDER SUMMARY" />
                            <Step label="PAYMENT OPTIONS" />
                        </Stepper>
                    </div>
                    {daddress != "" ?
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Delivery Address</h4>
                            </div>
                            {daddress?.map((daddress, index) => (
                                <div className="account-contenrt ddeliveryBottom" key={index}>
                                    <div className="deliverynames">
                                        <div className="deliveryname">
                                            <b>{daddress?.delivery_name}</b>
                                            <b className="deliverynumber">{daddress?.delivery_mobile}</b>
                                        </div>
                                        <div className="deliveryBtn">
                                            {/* <button className="btnEdit">Edit</button> */}
                                            <button className="btnRemove" onClick={() => handleRemoveAddress(daddress?.id)}>Remove</button>
                                        </div>
                                    </div>
                                    <div className="adeliveryaddress">
                                        <span>{daddress?.delivery_addresse}, {daddress?.delivery_city}, {daddress?.delivery_state}, {daddress?.delivery_pincode}</span>
                                    </div>
                                    <div className="adeliveryaddressbtn mb-4">
                                        <button onClick={() => handleDeliveryHare(daddress?.id)}>DELIVER HERE</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        ""
                    }
                    <div className="account-card">
                        <div className="account-title">
                            <h4>Add New Delivery Address</h4>
                        </div>
                        <div className="account-content">
                            <form onSubmit={handleDeliveryAddress} method="post">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_name">Name</label>
                                        <input type="text" className="form-control" id="delivery_name" name="delivery_name" placeholder="Name" required={true} onChange={handleInputs} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_mobile">Mobile</label>
                                        <input type="number" className="form-control" id="delivery_mobile" placeholder="Mobile" name="delivery_mobile" required={true} onChange={handleInputs} pattern="[6789][0-9]{9}" maxLength={10} minLength={10} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_addresse">Full Address</label>
                                        <textarea className="form-control" id="delivery_addresse" name="delivery_addresse" placeholder="Flat, House No, Building, Apartment, Area, Street, Sector, Village" required={true} rows={1} onChange={handleInputs}></textarea>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_landmark">Landmark</label>
                                        <input type="text" className="form-control" id="delivery_landmark" name="delivery_landmark" placeholder="Landmark" onChange={handleInputs} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_city">City</label>
                                        <input type="text" className="form-control" id="delivery_city" name="delivery_city" placeholder="City" onChange={handleInputs} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_pincode">Pincode</label>
                                        <input type="number" className="form-control" id="delivery_pincode" name="delivery_pincode" placeholder="Pincode" required={true} onChange={handleInputs} minLength={6} maxLength={6} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_state">State</label>
                                        <input type="text" className="form-control" id="delivery_state" name="delivery_state" placeholder="State" required={true} onChange={handleInputs} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="delivery_country">Country</label>
                                        <input type="text" className="form-control" id="delivery_country" name="delivery_country" placeholder="Country" required={true} onChange={handleInputs} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                            <button className="btncheckcont" name="login">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default DeliveryAddress
