import React from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { fetchDataFromApi } from '../utils/api';

const Ingredients = () => {
    const pathname = window.location.href;
    const [ingredient, setIngredient] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/ingredient/";

    useEffect(() => {
        getIngredient();
    }, []);

    const getIngredient = () => {
        fetchDataFromApi('all-ingredient').then((res) => {
            if (res.status === 1) {
                setIngredient(...[res.data]);
            }
        })
    }

    return (
        <div className="faq-section">
            <Helmet>
                <title>Ingredients</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs breadcrumbss">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Ingredients</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <h1 className="text-center">Ingredients</h1>
                        {ingredient.length > 0 ? ingredient?.map((ingredient, index) => (
                            <div className="col-12 col-lg-3">
                                <div className="ingredientList">
                                    <Link target='_blank' to={ingredient?.ingredient_url}>
                                        <img src={BASE_URL + ingredient?.ingredient_img} alt={ingredient?.ingredient_img} />
                                    </Link>
                                    <h4>{ingredient?.ingredient_name}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: ingredient?.ingredient_desc }}></div>
                                </div>
                            </div>
                        ))
                            :
                            <div className="datanotfound">
                                <h2>Comming Soon...</h2>
                            </div>
                        }
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Ingredients