import React, { useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userLoginAPi, userVerifyMobileOTPAPi } from '../utils/api'
import TagManager from 'react-gtm-module';

const MobileOtp = () => {
    const [mobileotp, setMobileOTP] = useState("");
    const mobile = localStorage.getItem("usermobile");
    const pathname = window.location.href;
    const navigate = useNavigate();
    

    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobileOTP(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    const resendMobileOTP = () => {
        if (mobile.length === 10) {
            userLoginAPi("user-resend-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    toast.success("Mobile OTP has been send succssfully");
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }
    // Matched Mobile OTP
    const handleMobileOTPSubmit = async (e) => {
        e.preventDefault();
        if (mobileotp.length === 4) {
            const mobiledata = {
                mobile,
                mobileotp,
            };
            userVerifyMobileOTPAPi("user-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("userdata",JSON.stringify(res.data.userdata))
                    toast.success("Your mobile OTP has been verified!!");
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "login",
                            pagePath: pathname,
                            userId:"SK-" + res.data.userdata.id
                        },
                    });
                    navigate('/my-account');
                }
                else {
                    toast.error("Unable to verify mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile OTP");
        }
    }
    return (
        <div className="loginSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mobile OTP</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Mobile OTP</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <ContentWrapper>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="userLoginCard">
                            <h1 className="text-center">Mobile OTP</h1>
                            <div className="user-form-group">
                                <form className="user-form" method="post" onSubmit={handleMobileOTPSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="mobileotp">Mobile OTP:</label>
                                        <input onChange={NumericOnly} type="tel" name="mobileotp" id="mobileotp" className="form-control" placeholder="Enter your Mobile OTP" />
                                    </div>
                                    <a href="javascript:void(0)" onClick={resendMobileOTP} className="resendMobileTop">Resend Mobile OTP</a>
                                    <div className="form-button">
                                        <button className="register">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MobileOtp
