import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { Link, useNavigate } from 'react-router-dom';
import payment from "../assets/myaccount/payment.png";
import location from "../assets/myaccount/address-map-pin.png"
import signin from "../assets/myaccount/sign-in-lock.png"
import box from "../assets/myaccount/Box.png"
import tracking from "../assets/myaccount/tracking.png";
import contact from "../assets/myaccount/contact-us.png"

const MyAccount = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    useEffect(() => {
        if (userid) {
        }
        else{
            navigate("/login");
        }
    }, [userid]);

    return (
        <div className="myAccountSection bg-white">
            <Helmet>
                <title>My Account</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home" >
                            <Link to="/" title="Go to Home Page">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="items product">
                            <span>My Account</span>
                        </li>
                    </ul>
                </ContentWrapper>
            </div>
            <ContentWrapper>
                <div className="col-lg-12 accountBodySection">
                    <div className="row">
                        <h1 className="accountHeading">Your Account</h1>
                        <div className="col-md-4">
                            <Link to="/my-order">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={box} alt={box} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>My Orders</h4>
                                        <span>Track, retrun or buy things again</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={signin} alt={signin} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Login & Security</h4>
                                        <span>Edit login, name and mobile number</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/order-address">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={location} alt={location} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Your Address</h4>
                                        <span>Edit address for orders</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={payment} alt={payment} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Payment Options</h4>
                                        <span>Edit or payment method</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <div className="accountSection">
                                <div className="imgSection">
                                    <img src={contact} alt={contact} />
                                </div>
                                <div className="contentSection">
                                    <h4>Contact Us</h4>
                                    <span className="callnow">Call Now <Link to="tel:9319728256">+91-9319728256</Link></span><br />
                                    <span>Email: <Link to="mail:support@satkartar.co.in">support@satkartar.co.in</Link></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={tracking} alt={tracking} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Track Your Order</h4>
                                        <span>Check the status of your order</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyAccount
