import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { getSingleDataApi } from '../utils/api';
import Loading from '../components/Loading';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

const OrderAddress = () => {
    const mobile = localStorage.getItem("usermobile");
    const pathname = window.location.href;

    const [address, setAddress] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getDeliveryAddress(mobile)
    }, [])
    const getDeliveryAddress = (mobile) => {
        setIsLoading(true);
        getSingleDataApi(`get-delivery-here-address/${mobile}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setAddress(...[res.data.address]);
            }
            else {
                setIsLoading(false);
            }
        })
    }
    const removeDeliveryAddress = (removeid) => {
        getSingleDataApi(`remove-delivery-here-address/${removeid}`).then((res) => {
            if (res.data.status === 1) {
                getDeliveryAddress(mobile)
                toast.success("Delivery address has been removed!")
            }
        })
    }

    return (
        <div className="myOrderSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="item home">
                            <Link to="/my-account" title="Go to Home Page"><span>My Account</span></Link>
                        </li>
                        <li className="items product"><span>Delivery Address</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 col-md-12 col-md-6 mb-4">
                <ContentWrapper>
                    <div className="orderAddress">
                        {isLoading ?
                            <Loading />
                            :
                            address?.map((address, index) => (
                                <div className="addressSection">
                                    <div className="row">
                                        <div className="addressHeading">
                                            <h3><IoPerson /> {address?.delivery_name}</h3>
                                            <h3>
                                                <Link to={`/edit-delivery-address/${address?.id}`}><FaRegEdit /></Link>
                                                <button onClick={() => removeDeliveryAddress(address?.id)}className="btnaddressremove"><MdDelete /></button>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 addressBody">
                                            <div className="ordertitle">
                                                <span className="name">Mobile:</span>
                                                <span className="namedetail">{address?.delivery_mobile}</span>
                                            </div>
                                            <div className="ordertitle">
                                                <span className="name">Address:</span>
                                                <span className="namedetail">{address?.delivery_addresse}</span>
                                            </div>
                                            <div className="ordertitle">
                                                <span className="name">State:</span>
                                                <span className="namedetail">{address?.delivery_state}</span>
                                            </div>
                                            <div className="ordertitle">
                                                <span className="name">Country:</span>
                                                <span className="namedetail">{address?.delivery_country}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 addressBody">
                                            <div className="ordertitle">
                                                <span className="name">Landmark:</span>
                                                <span className="namedetail">{address?.delivery_landmark}</span>
                                            </div>
                                            <div className="ordertitle">
                                                <span className="name">City:</span>
                                                <span className="namedetail">{address?.delivery_city}</span>
                                            </div>
                                            <div className="ordertitle">
                                                <span className="name">Pincode:</span>
                                                <span className="namedetail">{address?.delivery_pincode}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default OrderAddress