import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import { BiUser } from "react-icons/bi";
import { SlCalender } from "react-icons/sl";
import BCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { fetchDataFromApi } from '../utils/api';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import moment from 'moment';

const HomeBlog = () => {
    const [blogtitle, setBlogTitle] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [blog, setBlog] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/blog/";

    useEffect(() => {
        getBlogTitle();
        getBlog();
    }, [])

    const getBlogTitle = () => {
        setLoading(true)
        fetchDataFromApi('home-blog-title').then((res) => {
            if (res.status === 1) {
                setBlogTitle(...[res.data]);
            }
            else {
                setLoading(true)
            }
        })
    };

    const getBlog = () => {
        setLoading(true)
        fetchDataFromApi('home-blog').then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setBlog(...[res.data]);
            }
            else {
                setLoading(true)
            }
        })
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="homeBlog mt-4">
            <ContentWrapper>
                <div className="col-lg-12 descktopview">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">
                            {blogtitle?.blog_heading}
                        </h2>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: blogtitle?.blog_desc }}></div>
                    </div>
                    <div className="row justify-content-center">  
                        <BCarousel responsive={responsive}
                            infinite={true} arrows={true} showDots={false}  >
                            {blog.map((blog, index) => (
                                <div className="col-md-12" key={index}>
                                    <div className="app-single-blog-card">
                                        <div className="blog-img">
                                            <Link target="_blank" to="https://www.satkartar.co.in/blog/how-to-identify-your-dominant-dosha/">
                                                <img src={BASE_URL + blog?.blog_img} alt={blog?.blog_img} title={blog?.blog_img} width="386" height="291" style={{ height: "auto" }} />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <div className="date-and-comment">
                                                <ul>
                                                    <li className="pull-right"><BiUser className="blogIcons" />Author: {blog?.blog_auther}</li>
                                                </ul>
                                            </div>
                                            <h3><Link target="_blank" to={blog?.blog_slug_url}>{blog?.blog_title}</Link></h3>
                                            <Link target="_blank" to={blog?.blog_slug_url} className="app-default-btn style3">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </BCarousel>
                    </div>
                </div>
                <div className="col-lg-12 mobileview">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">
                            {blogtitle?.blog_heading}
                        </h2>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: blogtitle?.blog_desc }}></div>
                    </div>
                    <div className="row justify-content-center">  
                        <div className="mobileviewHomeBlog">
                            {blog.map((blog, index) => (
                                <div className="col-md-12" key={index}>
                                    <div className="app-single-blog-card">
                                        <div className="blog-img">
                                            <Link target="_blank" to="https://www.satkartar.co.in/blog/how-to-identify-your-dominant-dosha/">
                                                <img src={BASE_URL + blog?.blog_img} alt={blog?.blog_img} title={blog?.blog_img} width="386" height="291" style={{ height: "auto" }} />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <div className="date-and-comment">
                                                <ul>
                                                    <li className="pull-right"><BiUser className="blogIcons" />Author: {blog?.blog_auther}</li>
                                                </ul>
                                            </div>
                                            <h3><Link target="_blank" to={blog?.blog_slug_url}>{blog?.blog_title}</Link></h3>
                                            <Link target="_blank" to={blog?.blog_slug_url} className="app-default-btn style3">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default HomeBlog
