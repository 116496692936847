import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import Img from '../components/lazyLoadImage/Img';
import Logo from "../assets/logo.webp";
import { MdLocationPin, MdWifiCalling3, MdMarkEmailUnread } from 'react-icons/md';
import { getSingleDataApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from '../components/numberFormate';
import moment from 'moment';
import QRCode from 'react-qr-code';


const OrderInvoice = () => {
    const { orderid } = useParams();
    const [order, setOrder] = useState([]);
    const [dorder, setDOrder] = useState([]);

    useEffect(() => {
        getOrderSingleDetails(orderid);
        getOrderDetails(orderid);
    }, [orderid])
    const getOrderSingleDetails = (orderid) => {
        getSingleDataApi(`get-my-orde-sinlle-detail/${orderid}`).then((res) => {
            if (res.data.status === 1) {
                setOrder(...[res.data.myorder])
            }
            else {
                toast.error("Unable to fetched order details");
            }
        })
    }
    const getOrderDetails = () => {
        getSingleDataApi(`get-my-order-detail/${orderid}`).then((res) => {
            if (res.data.status === 1) {
                setDOrder(...[res.data.myorder])
            }
            else {
                toast.error("Unable to fetched order details");
            }
        })
    }
    return (
        <div className="orderInvoice mt-4 mb-4">
            <ContentWrapper>
                <div className="col-lg-12 orderInvoiceDetails">
                    <button className="btnPrint" onClick={() => window.print()}>Print</button>
                    <div className="row">
                        <div className="col-md-8 col-sm-6 mb-4">
                            <h2 className="invoicename">INVOICE</h2>
                            <span>#{orderid}</span>
                            <div className=" billingAddress">
                                <span>Billing To</span>
                                <p>Name: <b>{order.order_name}</b></p>
                                <p>Address: <b>{order.order_address}</b></p>
                                <p>Email: <b>{order.order_email}</b></p>
                                <p>Mobile: <b>{order.order_mobile}</b></p>
                                <p>Zipcode: <b>{order.order_zipcode}</b></p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <Img src={Logo} alt="logo"></Img>
                            <div className="companyAddress">
                                <ul>
                                    <li className="d-flex">
                                        <MdLocationPin className="footerConnectIcon" />
                                        <span>
                                            6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001
                                        </span>
                                    </li>
                                    <li>
                                        <MdWifiCalling3 className="footerConnectIcons" />
                                        <span>+91-9319728256</span>
                                    </li>
                                    <li>
                                        <MdMarkEmailUnread className="footerConnectIcons" />
                                        <span>support@satkartar.co.in</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="col-md-12 col-sm-12">
                            <div className="rderDetails">
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>QTY</th>
                                            <th>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dorder.map((dorder, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{dorder.productname}</td>
                                                <td>{numberFormat(dorder.productprice)}</td>
                                                <td>{dorder.qty}</td>
                                                <td>{numberFormat(dorder.subtotal)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="amountDetails">
                                <div className="amountDetail">
                                    <span>Subtotal</span>
                                    <span><b>{numberFormat(order.totalprice)}</b></span>
                                </div>
                                <div className="amountDetail">
                                    <span>GST</span>
                                    <span><b>0.00</b></span>
                                </div>
                                <div className="amountDetail">
                                    <span>SGST</span>
                                    <span><b>0.00</b></span>
                                </div>
                                <div className="amountDetail">
                                    <span>Shipping</span>
                                    <span><b>0.00</b></span>
                                </div>
                                <div className="amountDetail">
                                    <span><b>Total AMount:</b></span>
                                    <span><strong>{numberFormat(order.totalprice)}</strong></span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="col-md-12 thankyousection">
                            <div className="thankyou">
                                <h3>Thank You</h3>
                                <p>Order Date: <b>{moment(order.created_at).format("MMMM DD YYYY")}</b></p>
                                <p>Pyment Method: <b>{order.payment_type}</b></p>
                            </div>
                            <div className="barcodesection">
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "50%" }}
                                    value={orderid}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default OrderInvoice
