import React, { useEffect, useState } from 'react'
import "../App.scss";
import ContentWrapper from './contentWrapper/ContentWrapper';
import { Link } from 'react-router-dom';
import { BiPhoneCall } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHelpCircle } from 'react-icons/io';
import { getSingleDataApi } from '../utils/api';
import { FaUserDoctor } from "react-icons/fa6";



const TopBar = () => {
    const [paramurl, setParamURL] = useState(null);
    const pathname = window.location.href.split("/").pop();
    const referrer = document.referrer;
    localStorage.setItem("referrer",referrer);

    useEffect(() => {
        matchedTopBarParam(pathname);
    }, [pathname]);

    const matchedTopBarParam = (pathname) => {
        if (pathname) {
            getSingleDataApi(`landing-page-matched-params-urls/${pathname}`).then((res) => {
                setParamURL(...[res.data?.paramsurl]);
            })
        }
    }

    return (
        <div className="topBar">
            {(() => {
                if (paramurl?.product_type == "Landing Page" || paramurl?.product_type == "LP" || paramurl?.product_type == "New Landing Page") {
                    return (
                        <ContentWrapper>
                            <div className="wrapper-top-bar">
                                <div className="leftTopBar">
                                    <span className="callNow">
                                        Call Now :
                                    </span>
                                    {(() => {
                                        if (paramurl?.display_mobile_number != null) {
                                            return (
                                                <Link to={`tel:+91-${paramurl?.display_mobile_number}`} className="displayNumber">
                                                    <BiPhoneCall className="userIcons" />+91-{paramurl?.display_mobile_number}
                                                </Link>
                                            )
                                        }
                                        else{
                                            return (
                                                <Link to="tel:+91-9319728256" className="displayNumber">
                                                    <BiPhoneCall className="userIcons" />+91-9319728256
                                                </Link>
                                            )
                                        }
                                    })()}
                                </div>
                                <div className="rightTopBar">
                                    {localStorage.getItem('userdata') ?
                                        <Link className="userLogin" to="/my-account"><FaUserAlt className="userIcons" />MyAccount</Link>
                                        :
                                        <Link className="userLogin" to="/login"><FaUserAlt className="userIcons" />Login </Link>
                                    }
                                    |
                                    <Link to="/talk-to-expert" className="help">
                                        <FaUserDoctor className="helpIcons" />
                                        Talk to expert
                                    </Link>
                                    |
                                    <Link to="/contact-us" className="help">
                                        <IoMdHelpCircle className="helpIcons" />
                                        Help
                                    </Link>
                                </div>
                            </div>
                        </ContentWrapper>
                    )
                }
                else {
                    return (
                        <ContentWrapper>
                            <div className="wrapper-top-bar">
                                <div className="leftTopBar">
                                    <span className="callNow">
                                        Call Now :
                                    </span>
                                    <Link to="tel:+91-9319728256" className="displayNumber">
                                        <BiPhoneCall className="userIcons" />+91-9319728256
                                    </Link>
                                </div>
                                <div className="rightTopBar">
                                    {localStorage.getItem('userdata') ?
                                        <Link className="userLogin" to="/my-account"><FaUserAlt className="userIcons" />MyAccount</Link>
                                        :
                                        <Link className="userLogin" to="/login"><FaUserAlt className="userIcons" />Login </Link>
                                    }
                                    |
                                    <Link to="/talk-to-expert" className="help">
                                        <FaUserDoctor className="helpIcons" />
                                        Talk to expert
                                    </Link>
                                    |
                                    <Link to="/contact-us" className="help">
                                        <IoMdHelpCircle className="helpIcons" />
                                        Help
                                    </Link>
                                </div>
                            </div>
                        </ContentWrapper>
                    )
                }
            })()}
        </div>
    )
}

export default TopBar
