import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { getSingleDataApi } from '../utils/api'
import { useEffect } from 'react'
import { useState } from 'react'
import { MdLocationPin, MdPayment, MdWifiCalling3 } from 'react-icons/md'
import { BiSupport } from 'react-icons/bi'
import { numberFormat } from '../components/numberFormate'
import { useCart } from 'react-use-cart'
import moment from 'moment'
import { SlCalender } from 'react-icons/sl'
import { FaUserAlt } from 'react-icons/fa'
import noorder from "../assets/no-order.jpg"

const MyOrders = () => {
    const navigate = useNavigate();
    const { addItem } = useCart();
    const buyItNow = (productd) => {
        addItem(productd);
        navigate("/checkout");
    }
    const [myorder, setMyOrder] = useState([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    useEffect(() => {
        if (userid) {
            getMyOrder(userid);
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    const getMyOrder = (userid) => {
        getSingleDataApi(`get-my-order/${userid}`).then((res) => {
            if (res.data.status === 1) {
                setMyOrder(...[res.data.myorder])
            }
        })
    }

    return (
        <div className="myOrderSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Orders</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="item home">
                            <Link to="/my-account" title="Go to Home Page"><span>My Account</span></Link>
                        </li>
                        <li className="items product"><span>My Orders</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 mb-4">
                <ContentWrapper>
                    {myorder != "" ?
                        <div className="myOrderDetails">
                            <h2>MY ORDERS</h2>
                            {myorder?.map((myorder, index) => (
                                <div className="orderCard" key={index}>
                                    <div className="orderDetails">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="orderPacedDetails">
                                                    <div className="orderPlaced">
                                                        <span className="orderType">Order Placed</span>
                                                        <span className="orderTypeDetails"><SlCalender />{moment(myorder?.created_at).format("MMMM DD YYYY")}</span>
                                                    </div>
                                                    <div className="orderTotal">
                                                        <span className="orderType">Total</span>
                                                        <span className="orderTypeDetails">{numberFormat(myorder?.productprice)} x {myorder?.qty} = {numberFormat(myorder?.subtotal)}</span>
                                                    </div>
                                                    <div className="orderShipTo">
                                                        <span className="orderType">SHIP TO</span>
                                                        <span className="orderTypeDetails orderShipName"><FaUserAlt />{myorder?.order_name}</span>
                                                    </div>
                                                    <div className="orderPaymentMethod">
                                                        <span className="orderType">Payment Method</span>
                                                        <span className="orderPaymentDetails orderShipName"><MdPayment />{myorder?.payment_type}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="orderId">
                                                    <span>ORDER ID#: {myorder?.orderid}</span>
                                                    <br />
                                                    <Link target="_blank" to={`/order-invoice/${myorder?.orderid}`}>View order details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipProductDetails">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="productImg">
                                                    <img src={BASE_URL + myorder?.productimage} alt={myorder?.productimage} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="productNameBtn">
                                                    <Link to={`/product/${myorder?.orderpdetails.product_slug_url}`}><h2>{myorder?.productname}</h2>
                                                        <div className="orderMobile">
                                                            <MdWifiCalling3 />
                                                            <span>{myorder?.order_mobile}</span>
                                                        </div>
                                                        <div className="orderAddress">
                                                            <MdLocationPin />
                                                            <p>{myorder?.order_address}</p>
                                                        </div>
                                                        <div className="buyAgain">
                                                            <button>Buy It Again</button>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="getProductSupport">
                                                    <Link to="tel:+91-9319728256"><BiSupport />Get Product Support</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="orderNotFounds">
                            <h2>MY ORDERS</h2>
                            <div className="norderimg">
                                <img src={noorder} alt={noorder} />
                            </div>
                            <Link className="continue-shoppingbtn" to="/products">Continue Shopping</Link>
                        </div>
                    }
                </ContentWrapper>
            </div>
        </div>
    )
}

export default MyOrders
