import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { getSingleDataApi, updateUserProfileAPi, userUploadProfilePicAPi } from '../utils/api'
import { toast } from 'react-toastify'
import UserSideBar from '../components/UserSideBar'


const initialState = {
    name: '',
    email: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    address: '',
}

const UserDashboard = () => {
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/user/";
    const [file, setFile] = useState('');
    const [values, setInputValues] = useState(initialState);
    const pathname = window.location.href;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (userid) {
            getSingleUserdata(userid)
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    const getSingleUserdata = (userid) => {
        setLoading(true);
        getSingleDataApi(`get-single-data/${userid}`).then((res) => {
            if (res.data.status === 1) {
                setLoading(false);
                setInputValues(...[res.data.userdata])
            }
            else {
                setLoading(true);
            }
        })
    }
    const updateUserProfile = async (e) => {
        e.preventDefault();
        try {
            updateUserProfileAPi(`update-user-profile/${userid}`, values).then((res) => {
                if (res.data.status === 1) {
                    toast.success("profile has been updated successfully!!");
                }
                else {
                    toast.error("Unable to fetched user data");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("foto", file);
                userUploadProfilePicAPi(`update-user-profile-pic/${userid}`, data).then((res) => {
                    if (res.status===200) {
                        getSingleUserdata(userid);
                        toast.success("profile pic updated sussfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);

    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="userDashboard mb-4">
            <Helmet>
                <title>user dashboard</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>user dashboard</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-3">
                            <UserSideBar />
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <h3 className="fomes">Edit User Profile</h3>
                            <div className="contact-form-div">
                                <form method="post" enctype="multipart/form-data" onSubmit={updateUserProfile}>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3">
                                            <img src={BASE_URL + values.foto} className="imgProfilePic" />
                                            <label className="labelProfilePic" htmlFor="foto">Change Profile Picture</label>
                                            <input className="profilePic" type="file" onChange={(e) => setFile(e.target.files[0])} id='foto' name="foto" accept="image/*" />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Name</label>
                                                    <input name="name" id="name" onChange={handleInputs} placeholder="Enter Name..." className="form-control" type="text" required="true" value={values.name} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Email</label>
                                                    <input placeholder="Enter Email..." type="email" name="email" id="email" onChange={handleInputs} className="form-control" value={values.email} required="true" />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Mobile</label>
                                                    <input placeholder="Enter here..." type="text" name="mobile" id="mobile" className="form-control" value={values.mobile} readOnly="true" />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Country</label>
                                                    <input placeholder="Country" type="text" name="country" id="country" required="true" onChange={handleInputs} value={values.country} className="form-control" />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>State</label>
                                                    <input placeholder="State" type="text" name="state" id="state" required="true" onChange={handleInputs} className="form-control" value={values.state} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>City</label>
                                                    <input placeholder="City" type="text" name="city" id="city" className="form-control" onChange={handleInputs} required="true" value={values.city} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Zipcode</label>
                                                    <input placeholder="Pincode" type="text" name="pincode" id="pincode" required="true" onChange={handleInputs} className="form-control" value={values.pincode} />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label>Address</label>
                                                    <textarea type="text" name="address" id="address" className="form-control" required="true" onChange={handleInputs} value={values.address}></textarea>
                                                </div>
                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                    <button className="userBtn mt-0">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default UserDashboard
