import React from 'react'
import gifloading from "../assets/loading.gif";

const Loading = () => {
    return (
        <div className="loadingImg">
            <img src={gifloading} alt="loader image" />
        </div>
    )
}

export default Loading
