import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import { Button, Form, FormGroup, Modal, ModalBody, ModalHeader, ModalTitle, Col, Row } from 'react-bootstrap';
import { FaRegHandPointRight } from "react-icons/fa";
import { fetchDataFromApi, talkToExpert } from '../utils/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { RiKakaoTalkFill } from "react-icons/ri";
import loadingif from "../assets/loading-gif.gif";
import Carousel from 'react-bootstrap/Carousel';
import Calendar from 'react-calendar';
import { SlCalender } from 'react-icons/sl';
import { FcAlarmClock } from 'react-icons/fc';



const TalkToExpert = () => {
    const [show, setShow] = useState(false);
    const [values, setTalkToExpert] = useState();
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [doctor, setDoctor] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/doctor/";
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };

    useEffect(() => {
        getAllDoctor();
        getTime();
    }, [])

    const [timeSlot, setTimeSlot] = useState([]);
    const [selectTimeSlot, setSelectTimeSlot] = useState(null);
    const [selectDate, setSelectDate] = useState(null);
    const [doctorId, setDoctorId] = useState("");

    const getTime = () => {
        const timeList = [];
        for (let i = 10; i <= 12; i++) {
            timeList.push({
                time: i + ':00 AM'
            })
            timeList.push({
                time: i + ':30 AM'
            })
        }
        for (let i = 1; i <= 6; i++) {
            timeList.push({
                time: i + ':00 PM'
            })
            timeList.push({
                time: i + ':30 PM'
            })
        }
        setTimeSlot(timeList);
    }
    const handleBookingBtn = (doctorid) => {
        setDoctorId(doctorid)
        setShow(true)
    }

    const getAllDoctor = () => {
        fetchDataFromApi('get-all-active-doctor').then((res) => {
            if (res.status === 1) {
                setDoctor(...[res.doctor])
            }
        })
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setLoading(false);
            event.stopPropagation();
        }
        else {
            try {
                const talkdata = { sheduledate: selectDate, sheduletime: selectTimeSlot, talkname: values?.talk_name, talknumber: values?.talk_number, doctorid: doctorId }
                if (selectDate === null || selectTimeSlot === null) {
                    toast.error("Please select date and time");
                    setLoading(false);
                }
                else {
                    talkToExpert('talk-to-expert', talkdata).then((res) => {
                        if (res.data.status === 1) {
                            setLoading(false);
                            setShow(false);
                            Swal.fire({
                                position: "top-center",
                                icon: "success",
                                title: "Your appointment has been booked successfully!!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                        else {
                            setLoading(false);
                            toast.warning("Check your connection!!");
                        }
                    })
                }
            }
            catch (error) {
                toast.error("Something is worng!!");
            }

        }
        setValidated(true);
    };
    const handleInputs = e => {
        setTalkToExpert({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="talktoexpertsec mt-4 bg-white">
            <ContentWrapper>
                <div className="talktoexpert">
                    <div className="col-lg-12">
                        <div className="appContainer">
                            <h2 className="text-center text-uppercase">Get a Free Consultation from our Ayurvedic Expert</h2>
                        </div>
                        <Carousel indicators={false} interval={2500}>
                            {doctor?.map((doctor, index) => (
                                <Carousel.Item key={index}>
                                    <div className="talktodetail">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="talkimg">
                                                    <img src={BASE_URL + doctor?.doctor_img} alt={doctor?.doctor_img} />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="drdesc">
                                                    <h3>Dr. {doctor?.doctor_name}</h3>
                                                    <p><strong>Specialization: </strong>{doctor?.doctor_splz}</p>
                                                    <p><strong>Total Experience: </strong>{doctor?.doctor_total_exp}</p>
                                                    <p><strong>Consultation Language Known: </strong>{doctor?.doctor_lang}</p>
                                                    {isReadMore ?
                                                        <p className="talkdrdesc"><div dangerouslySetInnerHTML={{ __html: doctor?.doctor_desc.slice(0, 500) }}></div></p>
                                                        : <div className="catdesps" dangerouslySetInnerHTML={{ __html: doctor?.doctor_desc }}></div>
                                                    }
                                                    {doctor?.doctor_desc.length > 500 &&
                                                        <button className="btnreadmore" onClick={toggleReadMore}>
                                                            {isReadMore ? '...read more' : ' ...show less'}
                                                        </button>
                                                    }
                                                    <div className="btns d-flex justify-content-center">
                                                        <button className="btntalk" onClick={() => handleBookingBtn(doctor?.id)}><RiKakaoTalkFill /> Book Your Free Consultation Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </ContentWrapper>
            <Modal show={show} onHide={handleClose} size="lg">
                <ModalHeader closeButton>
                    <ModalTitle>Free Ayurvedic Consultation - Booking</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <span className="calender"><SlCalender /> Select Date</span>
                                <Calendar className="mt-2" minDate={new Date()} onChange={(e) => setSelectDate(e)} />
                            </Col>
                            <Col md={6}>
                                <div className="timeSlot">
                                    <span className="calendertime"><FcAlarmClock /> Select Time</span>
                                    <Row className="timesBody border rounded p-2">
                                        {timeSlot?.map((item, index) => (
                                            <Col onClick={() => setSelectTimeSlot(item.time)} md={3} key={index} className={`p-2 border rounded timebody ${item.time == selectTimeSlot && 'timeactive'}`}>{item.time}</Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                            <FormGroup className="mb-3" controlId="validationCustom01">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter the name"
                                    autoFocus
                                    required
                                    onChange={handleInputs}
                                    name="talk_name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a Full name.
                                </Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup className="mb-3" controlId="validationCustom02">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Mobile Number"
                                    autoFocus
                                    required
                                    onChange={handleInputs}
                                    name="talk_number"
                                    pattern="[6789][0-9]{9}"
                                    maxLength={10}
                                    minLength={10}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a mobile number.
                                </Form.Control.Feedback>
                            </FormGroup>
                            {(() => {
                                if (loading === true) {
                                    return (
                                        <Button type="submit" className="btnpopup" disabled={true}>
                                            <img src={loadingif} alt={loadingif} style={{ width: "7%", marginTop: "-3px" }} /> BOOKING...
                                        </Button>
                                    )
                                }
                                else {
                                    return (
                                        <Button type="submit" className="btnpopup">
                                            Book
                                        </Button>
                                    )
                                }
                            })()}
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default TalkToExpert