import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import { numberFormat } from '../components/numberFormate'
import { BsTrash3 } from 'react-icons/bs'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

const Cart = () => {
    const pathname = window.location.href;
    const {
        items,
        isEmpty,
        updateItemQuantity,
        cartTotal,
    } = useCart();

    const { removeItem } = useCart();
    const removeItems = (id) => {
        removeItem(id);
    };
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    return (
        <div className="cartSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cart</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Cart</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    {isEmpty ? <div style={{ marginBottom: "50px", display: "flex", justifyContent: "center" }}> <img src="https://newskinrange.satkartar.in/assets/images/em-cart.webp" alt="em-cart.webp" /> </div> :
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="account-card">
                                    <div className="account-title">
                                        <h4>Your Cart</h4>
                                    </div>
                                    <div className="account-content">
                                        <div className="table-scroll">
                                            <table className="table-list">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Product</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">quantity</th>
                                                        <th scope="col">Sub Total</th>
                                                        <th scope="col">action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items?.map((item, index) => (
                                                        <tr>
                                                            <td className="table-serial">
                                                                <h6>{index + 1}</h6>
                                                            </td>
                                                            <td className="table-image">
                                                                <img src={BASE_URL + item.image} alt={item.image} />
                                                            </td>
                                                            <td className="table-name">
                                                                <h6>{item.name}</h6>
                                                            </td>
                                                            <td className="table-price">
                                                                <h6>{numberFormat(item.price)}</h6>
                                                            </td>
                                                            <td className="table-quantity">
                                                                <div className="cart-action-group">
                                                                    <div className="product-action">
                                                                        <button className="action-minus" title="Quantity Minus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                                            <AiOutlineMinus />
                                                                        </button>
                                                                        <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value={item.quantity} readOnly={true} />
                                                                        <button className="action-plus" title="Quantity Plus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                                            <AiOutlinePlus />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="table-price">
                                                                <h6>{numberFormat(item.itemTotal)}</h6>
                                                            </td>
                                                            <td className="table-action">
                                                                <button className="trash" title="Remove Wishlist">
                                                                    <BsTrash3 onClick={() => removeItems(item.id)} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="account-card">
                                    <div className="account-content p-40">
                                        <h4 className="mb-10 text-center pt-2">Estimate Shipping &amp; Tax</h4><table className="table-list">
                                            <tbody>
                                                <tr>
                                                    <td className="subtotal">Sub total</td>
                                                    <td>{numberFormat(cartTotal)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="subtotal">Shipping Charge</td>
                                                    <td>₹ 0.00</td>
                                                </tr>
                                                <tr>
                                                    <td className="subtotal">GST</td>
                                                    <td>₹ 0</td>
                                                </tr>
                                                <tr>
                                                    <td className="subtotal">SGST</td>
                                                    <td>₹ 0</td>
                                                </tr>
                                                <tr>
                                                    <td className="subtotal">Discount</td>
                                                    <td>₹ 0</td>
                                                </tr>
                                                <tr>
                                                    <td className="subtotal">Total Price</td>
                                                    <td>
                                                        <b>{numberFormat(cartTotal)}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="btn-checkout">
                                            <Link className="cart-checkout-btn" to="/checkout">
                                                <span className="checkout-label">Proceed to Checkout</span>
                                                <span className="checkout-price">&nbsp;{numberFormat(cartTotal)}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Cart
