import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userLoginAPi } from '../utils/api'


const Login = () => {
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const pathname = window.location.href;

    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    useEffect(() => {
        if (userid) {
            navigate('/my-account');
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobile(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (mobile.length === 10) {
            userLoginAPi("user-send-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("usermobile", mobile)
                    toast.success("Mobile OTP has been send succssfully");
                    navigate('/mobile-otp');
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }

    return (
        <div className="loginSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Login</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <ContentWrapper>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="userLoginCard">
                            <h1 className="text-center">Signup / Signin / Login</h1>
                            <div className="user-form-group">
                                <form className="user-form" method="post" onSubmit={handleLoginSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile:</label>
                                        <input type="tel" pattern="[6789][0-9]{9}" maxLength={10} minLength={10} name="mobile" id="mobile" className="form-control" placeholder="Enter your Mobile Number" onChange={NumericOnly} />
                                    </div>
                                    <div className="form-button">
                                        <button className="register">Send OTP</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Login
