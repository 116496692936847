import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { fetchDataFromApi } from '../utils/api'
import { toast } from 'react-toastify'

const TermCondition = () => {
    const [term, setTerm] = useState([]);
    const pathname = window.location.href;

    useEffect(() => {
        getTermCondition();
    }, [])
    const getTermCondition = () => {
        fetchDataFromApi("term-policy").then((res) => {
            if (res.status === 1) {
                setTerm(...[res.data]);
            }
            else {
                toast.error("Unable to fetched FAQ data");
            }
        })
    };

    return (
        <div className="termSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{term?.page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={term?.meta_title} />
                <meta name="description" content={term?.meta_description} />
                <meta name="keywords" content={term?.meta_keyword} />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={term?.meta_title} />
                <meta property="og:description"
                    content={term?.meta_description}  />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>{term?.policy_heading}</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 bg-white">
                <ContentWrapper>
                    <h1 className="text-center">{term?.policy_heading}</h1>
                    <div dangerouslySetInnerHTML={{__html:term?.policy_desc}}></div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default TermCondition
