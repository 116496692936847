import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import Img from '../components/lazyLoadImage/Img';
import { fetchDataFromApi } from '../utils/api';
import Loading from '../components/Loading';

const HealthCare = () => {
    const [categorytitle, setCategoryTitle] = useState([]);
    const [category, setCategory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/category/";

    useEffect(() => {
        getCategoryTitle();
        getCategory();
    }, [])

    const getCategoryTitle = () => {
        setLoading(true);
        fetchDataFromApi('home-category-title').then((res) => {
            if (res.status === 1) {
                setLoading(false);
                setCategoryTitle(...[res.data]);
            }
            else {
                setLoading(true);
            }
        })
    };

    const getCategory = () => {
        setLoading(true);
        fetchDataFromApi('home-category').then((res) => {
            if (res.status === 1) {
                setLoading(false);
                setCategory(...[res.data]);
            }
            else {
                setLoading(true);
            }
        })
    }

    return (
        <div className="shopByConcernSection bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Shop By Concern</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Shop by concern</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <div className="appContainer">
                    <h2 className="text-center text-uppercase">{categorytitle?.category_heading}</h2>
                    <div className="text-justify shopbyconcerntext" dangerouslySetInnerHTML={{ __html: categorytitle?.category_desc }}></div>
                </div>
                <div className="healthCareLista">
                    <ContentWrapper>
                        <div className="row">
                            {category?.map((category, index) => (
                                <div className="col-md-2 col-sm-12 mb-4">
                                    <div className="shopbyconcern" key={index}>
                                        <Link to={`/category/${category?.category_slug_url}`}>
                                            <img src={BASE_URL + category?.category_img} alt={category?.category_img} />
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </ContentWrapper>
                </div>
            </div>
        </div>
    )
}

export default HealthCare
