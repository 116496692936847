import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { fetchDataFromApi } from '../utils/api';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const HeroBanner = () => {
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/banner/";
    const [banner, setBanner] = useState([]);
    const [mbanner, setMBanner] = useState([]);

    useEffect(() => {
        getHeroBanner();
        getHeroBannerMobile();
    }, [])
    const getHeroBanner = () => {
        fetchDataFromApi("hero-banner").then((res) => {
            if (res.status === 1) {
                setBanner(...[res.data]);
            }
        })
    };
    const getHeroBannerMobile = () => {
        fetchDataFromApi("hero-banner-mobile").then((res) => {
            if (res.status === 1) {
                setMBanner(...[res.data]);
            }
        })
    };
    return (

        <div className="mainBanner">
            <AutoplaySlider className="heroBannerDescktop" play={true} interval={3000} infinite={true}>
                {banner?.map((banner, index) => (
                    <div key={index} className="deskbanner">
                        <Link to={banner?.banner_url}>
                            <img rel="preload" as="image" fetchpriority="high" src={BASE_URL + banner?.banner_img} alt={banner?.banner_alt_name} title={banner?.banner_alt_name} />
                        </Link>
                    </div>
                ))}
            </AutoplaySlider>
            <AutoplaySlider className="heroBannerMobile" play={true} interval={2000} infinite={true} mobileTouch={true}>
                {mbanner?.map((mbanner, index) => (
                    <div key={index + 1}>
                        <Link to={mbanner?.banner_url}>
                            <img rel="preload" as="image" fetchpriority="high" width={410} height={502} src={BASE_URL + mbanner?.banner_img} alt={mbanner?.banner_alt_name} title={mbanner?.banner_alt_name} />
                        </Link>
                    </div>
                ))}
            </AutoplaySlider>
        </div>
    )
}

export default HeroBanner
