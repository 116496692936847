import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import { fetchDataFromApi } from '../utils/api';
import Loading from './Loading';

const AuthenticProduct = () => {
    const [ayurvedictitle, setAyurvedicTitle] = useState([]);
    const [ayurvedic, setAyurvedic] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/medicine/";
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getAyurvedicTtitle();
        getAyurvedic();
    }, [])
    const getAyurvedicTtitle = () => {
        setLoading(true);
        fetchDataFromApi('home-ayurvedic-medicine-title').then((res) => {
            if (res.status === 1) {
                setLoading(false);
                setAyurvedicTitle(...[res.data]);
            }
            else {
                setLoading(true);
            }
        })
    };
    const getAyurvedic = () => {
        setLoading(true);
        fetchDataFromApi('home-ayurvedic-medicine').then((res) => {
            if (res.status === 1) {
                setLoading(false);
                setAyurvedic(...[res.data]);
            }
            else {
                setLoading(true);
            }
        })
    };

    return (
        <div className="authenticProduct bg-white mt-4">
            <ContentWrapper>
                <div className="col-lg-12">
                    <div className="appContainer">
                        <h2 className="text-center text-uppercase">
                            {ayurvedictitle.medicine_heading}
                        </h2>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: ayurvedictitle.medicine_desc }}></div>
                    </div>

                    <div className="row">
                        <div className="authenticBody">
                            {ayurvedic.map((ayurvedic, index) => (
                                <div className="col-md-3 col-sm-6" key={index}>
                                    <div className="singleAppFeaturesBox">
                                        <div className="featuresContent">
                                            <img src={BASE_URL + ayurvedic.medicine_icon} alt={ayurvedic.medicine_icon} title={ayurvedic.medicine_icon} width="206" height="206" style={{ height: "auto" }} />
                                            <h3>{ayurvedic.medicine_title}</h3>
                                            <p>{ayurvedic.medicine_desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AuthenticProduct
