import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { AiTwotoneStar } from "react-icons/ai";
import Img from '../components/lazyLoadImage/Img';
import { fetchDataFromApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from '../components/numberFormate';
import { useCart } from 'react-use-cart';

const Deals = () => {
    const { addItem } = useCart();
    const naviaget = useNavigate();
    const pathname = window.location.href;

    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
    }

    const buyItNow = (product) => {
        addItem(product);
        naviaget("/checkout");
    }

    const [deals, setDeals] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    useEffect(() => {
        getDealsProduct();
    }, [])
    const getDealsProduct = () => {
        fetchDataFromApi('today-deals').then((res) => {
            if (res.status === 1) {
                setDeals(...[res.data]);
            }
            else {
                toast.error("unable to fetch deals data");
            }
        })
    }
    return (
        <div className="dealsSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Today's Deals</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Today's Deals</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <h1 className="text-center mb-4">Today's Deals</h1>
                <ContentWrapper>
                    <div className="row">
                        {deals.map((deals, index) => (
                            <div className="col-md-4 col-sm-12">
                                <div className="appSinglePricingCard" key={index}>
                                    <div className="pricingTopContent">
                                        <div className="productImg">
                                            {(() => {
                                                if (deals?.product_type === "Vitamin & Nutrition") {
                                                    return (
                                                        <Link to={`/vitamin-and-supplements/${deals?.product_slug_url}`}>
                                                            <Img src={BASE_URL + deals?.image} alt={deals?.product_alt_name}></Img>
                                                        </Link>
                                                    )
                                                }
                                                else if (deals?.product_type === "Nutraceuticals") {
                                                    return (
                                                        <Link to={`/nutraceutical/${deals?.product_slug_url}`}>
                                                            <Img src={BASE_URL + deals?.image} alt={deals?.product_alt_name}></Img>
                                                        </Link>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Link to={`/product/${deals?.product_slug_url}`}>
                                                            <Img src={BASE_URL + deals?.image} alt={deals?.product_alt_name}></Img>
                                                        </Link>
                                                    )
                                                }
                                            })()}
                                        </div>
                                        <div className="pricingList">
                                            <Link target='_blank' to={`/product/${deals?.product_slug_url}`}>
                                                <h4 className="title">{deals?.page_title}</h4>
                                            </Link>
                                            <p>
                                                {(() => {
                                                    if (deals?.price != 0) {
                                                        return (
                                                            <div className="productPrice">
                                                                <span>{numberFormat(deals?.price)}</span>
                                                                <del>{numberFormat(deals?.product_cut_price)}</del>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <div className="productRatings">
                                                    {deals?.product_rating}
                                                    <AiTwotoneStar className="ratingIcons" />
                                                </div>
                                            </p>
                                        </div>
                                        {(() => {
                                            if (deals?.price != 0) {
                                                return (
                                                    <div className="btnMainProduct">
                                                        <button onClick={() => addToCart(deals)} className=" addToCartBtn">Add To Cart</button>
                                                        <button onClick={() => buyItNow(deals)} className=" buyItNowBtn">Buy It Now</button>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Deals
