import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import Loading from '../components/Loading';
import { addPaymentDataAPI } from '../utils/api';

const PaymentSuccess = () => {
    const orderid = localStorage.getItem("orderid");
    const tid = localStorage.getItem("transactionid");

    useEffect(() => {
        paymentSuccess();
    }, []);

    const paymentSuccess = async () => {
        const transactionId = localStorage.getItem("transactionid");
        const datas = { transactionId, transactionId };

        addPaymentDataAPI('check-payment-status', datas).then((res) => {
            // console.log("paymentstatus", res);
            if (res.data.status === 1) {
                if (res.data.response.success === false){
                    window.open('/payment-failed', "_self");
                }
                else {
                    const datas = { orderid: orderid, tid, transactionId };
                    addPaymentDataAPI('get-payment-confirm-order-details', datas).then((res) => {
                        window.open('/thank-you', "_self");
                    });
                }
            }
        })
    }


    return (
        <div className="orderConfirmation">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Payment Status</title>
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page">
                                <span >Home</span>
                            </Link>
                        </li>
                        <li className="items product"><span>Payment Status</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div style={{ height: "40vh" }}>
                <ContentWrapper>
                    <Loading />
                </ContentWrapper>
            </div>
        </div>
    )
}

export default PaymentSuccess