import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const UserSideBar = () => {
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/login");
    }
    return (
        <div>
            <div className="right-sideabr">
                <h4>Dashboard</h4>
                <ul className="list-item">
                    <li><Link className="active" to="/user-dashboard">My Profile</Link></li>
                    <li><Link to="/my-order">My Order</Link></li>
                    <li><a href="javascript:void(0)" onClick={logout}>Sign out</a></li>
                </ul>
            </div>
        </div>
    )
}

export default UserSideBar
