import axios from "axios";

const BASE_URL = "https://backendsatkartar.satkartar.co.in/api/";

export const fetchDataFromApi = async (url, params) => {
    try {
        const { data } = await axios.get(BASE_URL + url, {
            params
        })
        return data;
    }
    catch (err) {
        return err;
    }
}

export const userLoginAPi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { mobile: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const userVerifyMobileOTPAPi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const getSingleDataApi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const updateUserProfileAPi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const userUploadProfilePicAPi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const userOrder = async (url, params) => {
    try {
        const order = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        return err;
    }
}

export const getProductDetailApi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}
export const getMultitpleDataApi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}

export const userNewsLetterAPi = async (url, params) => {
    try {
        const sendotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { email: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendotp;
    }
    catch (err) {
        return err;
    }
}

export const productFilterByCategoryAPi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}
export const userDeliveryAddressAPi = async (url, values) => {
    try {
        const deliveryAddress = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: values,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return deliveryAddress;
    }
    catch (err) {
        return err;
    }
}

export const placeOrderAPi = async (url, datas) => {
    try {
        const placeOrders = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: datas,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return placeOrders;
    }
    catch(err){
        return err;
    }
}
export const deliveryHareAPi = async (url, datas) => {
    try {
        const deliveryHare = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: datas,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return deliveryHare;
    }
    catch(err){
        return err;
    }
}
export const getLandingPageProductDetailApi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}
export const addSingleDataAPI = async (url, params) => {
    try {
        const addHr = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return addHr;
    }
    catch (err) {
        return err;
    }
}
export const addPaymentDataAPI = async (url, datas) => {
    try {
        const placeOrders = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: datas,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return placeOrders;
    }
    catch(err){
        return err;
    }
}
export const getPaymentStatusApi = async (url, params) => {
    try {
        const paymentstatus = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return paymentstatus;
    }
    catch (err) {
        return err;
    }
}
export const addPaymentDataAPIFromPhonepay = async (url, datas) => {
    try {
        const placeOrders = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: datas,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return placeOrders;
    }
    catch(err){
        return err;
    }
}
export const getCategoryProductApi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}
export const talkToExpert = async (url, datas) => {
    try {
        const talkToExperts = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: datas,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return talkToExperts;
    }
    catch(err){
        return err;
    }
}
export const getProductBySearchFilter = async (url, params) => {
    try {
        const search = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { search_name: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return search;
    }
    catch (err) {
        return err;
    }
}
export const getError404PageStatusApi = async (url, params) => {
    try {
        const product = await axios({
            method: "get",
            url: BASE_URL + url,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return product;
    }
    catch (err) {
        return err;
    }
}