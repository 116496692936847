import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { fetchDataFromApi } from '../utils/api'
import { toast } from 'react-toastify'

const AboutUs = () => {
    const [about, setAbout] = useState([]);
    const pathname = window.location.href;

    useEffect(() => {
        getAboutUs();
    }, [])
    const getAboutUs = () => {
        fetchDataFromApi("about-us").then((res) => {
            if (res.status === 1) {
                setAbout(...[res.data]);
            }
            else {
                toast.error("Unable to fetched FAQ data");
            }
        })
    };
    
    return (
        <div className="aboutSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{about?.page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={about?.meta_title} />
                <meta name="description" content={about?.meta_description} />
                <meta name="keyword" content={about?.meta_keyword} />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={about?.meta_title} />
                <meta property="og:description"
                    content={about?.meta_description}  />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>{about?.about_heading}</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <h1 className="text-center">{about?.about_heading}</h1>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <h2>Who We Are</h2>
                            <div dangerouslySetInnerHTML={{__html:about?.about_desc}}></div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default AboutUs