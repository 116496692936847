import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { fetchDataFromApi } from '../utils/api';
import parse from "html-react-parser";
import Loading from '../components/Loading';
import { MetaTags } from 'react-meta-tags';

const FAQS = () => {
    const [faq, setFaq] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const pathname = window.location.href;

    useEffect(() => {
        fetchDataConfig();
    }, []);
    const fetchDataConfig = () => {
        setLoading(true)
        fetchDataFromApi("faq").then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setFaq(...[res.data])
            }
            else {
                setLoading(true)
            }
        })
    };
    let Schemadata =
    {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "FAQS",
                "item": "https://www.satkartar.co.in/faqs"
            }]
        },
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            faq?.map((faq) => (
                {
                    "@type": "Question",
                    "name": `${faq?.faq_question}`,
                    "acceptedAnswer": [
                        {
                            "@type": "Answer",
                            "text": `${faq?.faq_answer}`
                        }
                    ]
                }
            )),
        ]
    }


    return (
        <div className="faq-section">
            <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(Schemadata) }}
                />
            <MetaTags>
                <title>FAQS</title>
                <link rel="canonical" href={pathname} />
            </MetaTags>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>FAQS</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <h1 className="text-center">FAQS</h1>
                        {isLoading ?
                            <Loading />
                            :
                            <Accordion>
                                {faq?.map((faq, index) => (
                                    <AccordionItem key={index} className="mt-4">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {faq?.faq_question}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {parse(faq?.faq_answer)}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        }
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default FAQS
