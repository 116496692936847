import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { FiMenu } from "react-icons/fi";
import { AiTwotoneStar } from "react-icons/ai";
import Img from '../components/lazyLoadImage/Img';
import { toast } from 'react-toastify';
import { fetchDataFromApi, getProductBySearchFilter, productFilterByCategoryAPi } from '../utils/api';
import { numberFormat } from '../components/numberFormate';
import { useCart } from "react-use-cart";
import { MetaTags } from 'react-meta-tags';
import TagManager from 'react-gtm-module'

const SearchProduct = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const pathname = window.location.href;
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const search_name = params.get('q');

    const [product, setProduct] = useState([]);
    const [producttitle, setProductTitle] = useState([]);

    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const BASE_URL1 = "https://backendsatkartar.satkartar.co.in/";

    useEffect(() => {
        getProduct(search_name);
        getProductPageTilte();
    }, [search_name])
    const getProductPageTilte = () => {
        fetchDataFromApi('get-product-page-title').then((res) => {
            if (res.status === 1) {
                setProductTitle(...[res.data]);
            }
        })
    }
    const getProduct = (search_name) => {
        getProductBySearchFilter('get-search-all-filter-product',search_name).then((res) => {
            if(res.data.status===1){
                setProduct(...[res.data.data]);
            }
            else{
                setProduct();
            }
        })
    };

    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: producttitle?.product_heading,
                userId: "SK-" + userid,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (product) => {
        addItem(product);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                userId: "SK-" + userid,
                pageTitle: producttitle?.product_heading,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    let productSchema = {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Products",
                "item": "https://www.satkartar.co.in/products"
            }]
        },
        "@context": "https://schema.org",
        "@type": "ItemList",
        "url": `${pathname}`,
        "numberOfItems": `${product?.length}`,
        "itemListElement": [
            product?.map((product, index) => (
                {
                    "@type": "Product",
                    "image": `${BASE_URL + product?.image}`,
                    "url": `${BASE_URL1 + `product/${product?.product_slug_url}`}`,
                    "name": `${product.name}`,
                    "description": `${product?.short_desc}`,
                    "position": `${index + 1}`,
                    "offers": {
                        "@type": "AggregateOffer",
                        "url": `${BASE_URL1 + `product/${product?.product_slug_url}`}`,
                        "lowPrice": `${product?.price}.00`,
                        "highPrice": `${product?.product_cut_price}.00`,
                        "priceCurrency": "INR",
                        "offerCount": `${index + 10}`,
                        "priceSpecification": {
                            "@type": "UnitPriceSpecification",
                            "lowPrice": `${product?.price}.00`,
                            "highPrice": `${product?.product_cut_price}.00`,
                            "priceCurrency": "INR",
                        },
                        "availability": "InStock",
                    },
                    "brand": {
                        "@type": "Brand",
                        "name": "SATKARTAR"
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": `${product?.product_rating}`,
                        "reviewCount": `${product?.price}`
                    },
                }
            )),
        ]
    }
    return (
        <div className="productSection">
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(productSchema) }}
            />
            <MetaTags>
                <title>{producttitle?.product_heading}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={producttitle?.product_heading} />
                <meta name="description" content={producttitle?.product_desc} />
                <meta name="keywords" content="" />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={producttitle?.product_heading} />
                <meta property="og:description"
                    content={producttitle?.product_desc} />
                <meta name="description" content={producttitle?.product_desc} />
            </MetaTags>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="items product"><span className="psearch">Search results for <b>"{search_name}"</b></span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="productTopBar">
                                <div className="productViewTitle">
                                    <h4>Search results for <b>"{search_name}"</b></h4>
                                </div>
                            </div>
                            <div className="productView mt-4">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        {product ? product.map((product, index) => (
                                            <div className="productDownView col-md-3 col-sm-12" key={index}>
                                                <div className="appSinglePricingCard productPageCard">
                                                    <div className="pricingTopContent">
                                                        <div className="productImg">
                                                            {(() => {
                                                                if (product?.product_type === "Vitamin & Nutrition") {
                                                                    return (
                                                                        <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                                else if (product?.product_type === "Nutraceuticals") {
                                                                    return (
                                                                        <Link to={`/herbal/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <Link to={`/product/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className="productContentList">
                                                            <div className="pricingList">
                                                                {(() => {
                                                                    if (product?.product_type === "Vitamin & Nutrition") {
                                                                        return (
                                                                            <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    else if (product?.product_type === "Nutraceuticals") {
                                                                        return (
                                                                            <Link to={`/herbal/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <Link to={`/product/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                })()}
                                                                <p>
                                                                    {(() => {
                                                                        if (product?.price != 0) {
                                                                            return (
                                                                                <div className="productPrice">
                                                                                    <span>{numberFormat(product?.price)}</span>
                                                                                    <del>{numberFormat(product?.product_cut_price)}</del>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <div className="productRatings">
                                                                        {product?.product_rating}
                                                                        <AiTwotoneStar className="ratingIcons" />
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <p>{product?.short_desc}</p>
                                                            {(() => {
                                                                if (product?.price != 0) {
                                                                    return (
                                                                        <div className="btnMainProduct">
                                                                            <button className="addPToCartBtn" onClick={() => addToCart(product)}>Add To Cart</button>
                                                                            <button onClick={() => buyItNow(product)} className="buyPItNowBtn">Buy It Now</button>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                            :
                                            <div>Data Not founds</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default SearchProduct
