import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import { fetchDataFromApi } from '../utils/api';

const FooterContent = () => {
    const [footerText, setFooterText] = useState([]);
    const [showFullDescription, setFullDescription] = useState(false);

    useEffect(() => {
        getFooterText();
    }, [])

    const getFooterText = () => {
        fetchDataFromApi('footer-text').then((res) => {
            if (res.status === 1) {
                setFooterText(...[res.data]);
            }
        })
    }
    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const description = showFullDescription
        ? footerText.footer_text
        : `${footerText.footer_text ? footerText.footer_text.substring(0, 380) : ""}...`;


    return (
        <div className="footerContent bg-white">
            <ContentWrapper>
                <div className="footer-text">
                    <div onClick={showFullDescriptionHandler}>
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        <button className="readMoreText">Read {showFullDescription ? "Less" : "More"}</button>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default FooterContent
