import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { MdLocationPin, MdWifiCalling3, MdMarkEmailUnread } from 'react-icons/md';
import { GoPerson } from "react-icons/go";
import { addSingleDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ContactUs = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const [values, setUserValue] = useState();
    const [sending, setIsSending] = useState(false);

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleContactData = async (e) => {
        setIsSending(true);
        e.preventDefault();
        if (values) {
            try {
                addSingleDataAPI('contact-data', values).then((res) => {
                    if (res.data.status === 1) {
                        setIsSending(false);
                        e.target.reset();
                        Swal.fire({
                            icon: 'success',
                            title: 'Message send successfully!!',
                            text: 'We will get touch you soon!',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                setIsSending(false);
                toast.error("Something is worng!!");
            }
        }
        else {
            setIsSending(false);
            toast.error("Server internal error!!");
        }
    }

    return (
        <div className="contactUs">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact us</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Contact us</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <h1 className="text-center mb-4">Contact us</h1>
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="contact-form">
                                <h4>Drop us message</h4>
                                <form method="post" onSubmit={handleContactData}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input className="form-control" type="text" name="name" id="name" placeholder="Enter Your Name" onChange={handleInputs} required={true} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input className="form-control" type="email" name="email" id="name" placeholder="Enter Your Email" onChange={handleInputs} required={true} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile</label>
                                        <input className="form-control" type="text" name="mobile" id="mobile" placeholder="Enter Your Mobile" onChange={handleInputs} required={true} pattern="[6789][0-9]{9}" maxLength={10} minLength={10} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Message</label>
                                        <textarea className="form-control" name="text" id="text" cols="30" rows="2" onChange={handleInputs} required={true}></textarea>
                                    </div>
                                    <div className="form-group mt-2">
                                        <input type="checkbox" placeholder="Enter Your Mobile" required={true} />
                                        <span className="checkterm">I consent to being contacted by Sat Kartar Shopping Ltd<Link to="/term-and-condition"> Terms of Use</Link> and <Link to="/privacy-Policy">Privacy Policy.</Link></span>
                                    </div>
                                    <div className="form-group">
                                        {sending ?
                                            <button className="contactBtn">Sending...</button>
                                            :
                                            <button className="contactBtn">Send Message</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="getInTouch">
                                <h4>Get in touch</h4>
                                <p>Thank you for showing interest in Sat Kartar Group. Please complete the form & one of our team members will get in touch with you as soon as possible.</p>
                                <ul>
                                    <li className="d-flex">
                                        <GoPerson className="contactIcon" />
                                        <span><b>Sat Kartar Shopping Ltd</b></span>
                                    </li>
                                    <li className="d-flex">
                                        <MdLocationPin className="contactIcon" />
                                        <span>
                                            6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001
                                        </span>
                                    </li>
                                    <li>
                                        <MdWifiCalling3 className="contactIcon" />
                                        <span>+91-9319728256</span>
                                    </li>
                                    <li>
                                        <MdMarkEmailUnread className="contactIcon" />
                                        <span>support@satkartar.co.in</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 mb-4">
                            <div className="contact-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14008.420052751884!2d77.2225016!3d28.6266146!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3135429c5ae1b73!2sSAT%20KARTAR%20GROUP!5e0!3m2!1sen!2sin!4v1649660577098!5m2!1sen!2sin" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ border: "0px", width: "100%" }}></iframe>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default ContactUs
