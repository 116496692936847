import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "react-lazy-load-image-component/src/effects/blur.css";

const Img = ({src, className, alt, title, width, height, srcset, dataSrcset, loading, rel, as, fetchpriority}) => {
    return (
        <LazyLoadImage className={className || ""} alt={alt} title={title} effect="blur" src={src} width={width} height={height} srcSet={srcset} datasrcset={dataSrcset} loading={loading} rel={rel} as={as} fetchpriority={fetchpriority} />
    )
};

export default Img
