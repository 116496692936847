import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InCarousel from "react-multi-carousel";
import LpTCarousel from "react-multi-carousel";
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { toast } from 'react-toastify'
import { useCart } from 'react-use-cart'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { numberFormat } from '../components/numberFormate';
import { getLandingPageProductDetailApi, getMultitpleDataApi, getSingleDataApi } from '../utils/api'
import ayurvedic from "../assets/landingpage/100-safe-and-natural.webp";
import codimg from "../assets/landingpage/cash-on-delivery.webp";
import freece from "../assets/landingpage/Free-expert-consultation.webp";
import freeshipping from "../assets/landingpage/free-shipping-in-india.webp";
import { AiFillStar, AiOutlineCheckSquare, AiOutlineShoppingCart } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import gmp from "../assets/landingpage/why-gmp-certified.webp";
import natural from "../assets/landingpage/why-100-natural.webp";
import ayush from "../assets/landingpage/why-ayush-certified.webp";
import cod from "../assets/landingpage/why-cash-on-delivery.webp";
import { MdOutlineStarHalf, MdOutlineStarPurple500 } from 'react-icons/md';
import { HiOutlinePhoneMissedCall } from 'react-icons/hi';
import TagManager from 'react-gtm-module'
import { IoCheckmarkDoneOutline } from 'react-icons/io5';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const LPPage = () => {
    // Start Content Menu
    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop && pageYOffset
                < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };
    // End Content Menu

    const { addItem } = useCart();
    const navigate = useNavigate();
    const addToCart = (productd) => {
        addItem(productd);
        toast.success("Product has been added in your cart!");
    }
    const buyItNow = (productd) => {
        addItem(productd);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd.price,
                    ecomm_pagetype: "Cart",
                    value: productd.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd.id,
                            name: productd.name,
                            brand: "Satkartar",
                            price: productd.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    const [productd, setProductd] = useState([]);
    const productid = productd?.id;
    localStorage.setItem("lproductid", productid);
    const [mimage, setMImage] = useState([]);
    const [highliter, setHighliter] = useState([]);
    const [lpdetails, setLPDetail] = useState([]);
    const [faqtitle, setFAQTitle] = useState([]);
    const [faq, setFAQ] = useState([]);
    const [reviewtitle, setReviewTitle] = useState([]);
    const [review, setReview] = useState([]);

    const { slug } = useParams();
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const BASE_URL_BENEFITS = "https://backendsatkartar.satkartar.co.in/assets/img/benefits/";
    const BASE_URL_HOWTOUSE = "https://backendsatkartar.satkartar.co.in/assets/img/howtouse/";
    const BASE_URL_INGREDIENT = "https://backendsatkartar.satkartar.co.in/assets/img/ingredient/";

    // Content Menu
    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // End Content Menu
    useEffect(() => {
        getProductDetail(slug);
        if (productid) {
            getProductMoreImage(productid);
            getLandingPageHighliter(productid);
            getLPageDetails(productid);
            getLandingPageFAQTitle(productid);
            getLandingPageFAQ(productid);
            getLandingPageReviewTitle(productid);
            getLandingPageReview(productid);
        }
    }, [slug, productid])

    const activeStyle = {
        fontWeight: 'bold',
        color: 'red',
    };

    const getProductDetail = (slug) => {
        getLandingPageProductDetailApi(`get-lp-product-details/${slug}`).then((res) => {
            if (res) {
                setProductd(...[res.data?.product]);
            }
            else {
                window.open('/*', "_self");
            }
        })
    }
    const getProductMoreImage = (productid) => {
        getMultitpleDataApi(`get-landing-page-multiple-image-data/${productid}`).then((res) => {
            setMImage(...[res.data?.pimage]);
        })
    }
    const getLandingPageHighliter = (productid) => {
        getMultitpleDataApi(`get-landing-page-highliter-data/${productid}`).then((res) => {
            setHighliter(...[res.data?.highliter]);
        })
    }
    const getLPageDetails = (productid) => {
        getSingleDataApi(`get-lp-details/${productid}`).then((res) => {
            setLPDetail(...[res.data?.lpdetail]);
        })
    }
    const getLandingPageFAQTitle = (productid) => {
        getSingleDataApi(`get-landing-page-faq-title/${productid}`).then((res) => {
            setFAQTitle(...[res.data?.faqtitle]);
        })
    }
    const getLandingPageFAQ = (productid) => {
        getMultitpleDataApi(`get-landing-page-faq/${productid}`).then((res) => {
            setFAQ(...[res.data?.faq]);
        })
    }
    const getLandingPageReviewTitle = (productid) => {
        getSingleDataApi(`get-landing-page-review-title/${productid}`).then((res) => {
            setReviewTitle(...[res.data?.reviewtitle]);
        })
    }
    const getLandingPageReview = (productid) => {
        getMultitpleDataApi(`get-landing-page-review/${productid}`).then((res) => {
            setReview(...[res.data?.review]);
        })
    }
    let Schemadata = {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Products",
                "item": "https://www.satkartar.co.in/products"
            }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${productd?.name}`
            }]
        },
        "@context": "https://schema.org/",
        "@type": "Product",
        "sku": `${productd?.product_sku}`,
        "image": `${BASE_URL + productd?.image}`,
        "name": `${productd?.name}`,
        "description": `${productd?.short_desc}`,
        "brand": {
            "@type": "Brand",
            "name": "Satkartar"
        },
        "offers": {
            "@type": "Offer",
            "url": `${pathname}`,
            "itemCondition": "NewCondition",
            "availability": "InStock",
            "price": `${productd?.price}.00`,
            "priceCurrency": "INR",
            "priceValidUntil": "2030-11-20",
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": 0.00,
                    "currency": "INR"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": "IND"
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 0,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    },
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 1,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    }
                }
            }
        },
        "review": [
            review?.map((review) => (
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": `${review?.reviews_rating}`,
                        "bestRating": 5
                    },
                    "name": `${review?.reviews_desc}`,
                    "author": {
                        "@type": "Person",
                        "name": `${review?.reviews_name}`
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "Satkartar Shopping LTD."
                    }
                }
            )),
        ],
        "faqs": {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                faq?.map((faq) => (
                    {
                        "@type": "Question",
                        "name": `${faq?.faq_title}`,
                        "acceptedAnswer": [
                            {
                                "@type": "Answer",
                                "text": `${faq?.faq_desc}`
                            }
                        ]
                    }
                )),
            ]
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": `${productd?.product_rating}`,
            "reviewCount": `${productd?.price}`
        }
    }
    return (
        <div className="productDetailSection bg-white">
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(Schemadata) }}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productd?.meta_title}</title>
                <link rel="canonical" href={pathname} />
                {productd?.no_follow_index_status === 1 ?
                    <meta name="robots" content="noindex,nofollow" />
                    :
                    ""
                }
                <meta name="description" content={productd?.meta_description} />
                <meta name="keywords" content={productd?.meta_keyword} />
                <meta property="og:description" content={productd?.meta_description} />
                <link rel="preload" fetchpriority="high" as="image" href={BASE_URL + productd?.image} type="image/webp"></link>
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="items product">
                            <span>{productd?.page_title}</span>
                        </li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row productDetailsSection productLandingDetailsSection lpProductDetailsSection">
                        <div className="col-md-6 col-sm-12 productBanner productLandingBanner">
                            <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false} swipeable={true} showStatus={false} showThumbs={false}>
                                <div>
                                    <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                </div>
                                {mimage.length > 0 ? mimage?.map((mimage, index) => (
                                    <div key={index}>
                                        <img rel="preload" fetchpriority="high" as="image" alt={mimage?.product_image} src={BASE_URL + mimage?.product_image} />
                                    </div>
                                ))
                                    :
                                    <div>
                                        <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                    </div>
                                }
                            </Carousel>
                        </div>
                        <div className="col-md-6 col-sm-12 productContDetail productLangDetail">
                            <div className="productDetails">
                                <h1 className="sk-turbo-treats-shilajit-gummies-title productnames">{productd?.name}</h1>
                                <hr />
                                <div className="reviewsBottomContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="inner-shop-details-meta">
                                                <ul>

                                                    <li className="inner-shop-details-review d-flex">
                                                        <b className="trating">
                                                            {Math.floor(Math.random() * 5000)} ratings
                                                        </b>
                                                    </li>
                                                    <li className="inner-shop-details-review d-flex">
                                                        <div className="rating">
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <BsStarHalf />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="product-details">
                                                <ul>
                                                    {highliter?.map((highliter, index) => (
                                                        <li key={index}>
                                                            <span><IoCheckmarkDoneOutline className="productDescCheck" /></span>
                                                            <span>{highliter?.highlighter}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="pro-price">
                                                <span content="INR"></span>
                                                <span className="new-price" content={numberFormat(productd?.price)}>{numberFormat(productd?.price)}</span>
                                                <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                                <div className="Pro-lable">
                                                    <span className="lp-discount">Save {productd?.product_save}</span>
                                                    <p>Limited Offer (inclusive of all taxes)</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="productBtn landingBtn">
                                                    <button onClick={() => buyItNow(productd)} className="buyItNow">Buy It Now</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="landinPpaymentType">
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={freece} alt="Free Expert Consultation" />
                                                        <h6>Free Expert Consultation</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={freeshipping} alt="Free Shipping on Prepaid" />
                                                        <h6>Free Shipping on Prepaid</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={ayurvedic} alt="100 % Ayurvedic" />
                                                        <h6>100 % Ayurvedic</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <img width={80} height={80} src={codimg} alt="Cash On Delivery" />
                                                        <h6>Cash On Delivery</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-9 col-sm-12 productDetailSection">
                            {/* // Overview */}
                            <div data-section id="Overview">
                                <div className="overviewDetails">
                                    <div className="col-md-12">
                                        <div className="processBox lpcontainerbox">
                                            <div className="servicesBox lpcontainer">
                                                <p className="mr-0">
                                                    <div dangerouslySetInnerHTML={{ __html: lpdetails?.lp_details }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* FAQ */}
                            <div data-section id="faqs" className="mt-4">
                                <div className="lpFaq">
                                    <h2 className="heading">{faqtitle?.faq_title}</h2>
                                    <Accordion allowZeroExpanded>
                                        {faq.map((faq, index) => (
                                            <AccordionItem className="mt-4" key={index}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {faq?.faq_title}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {faq?.faq_desc}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                            {/* Review */}
                            <div data-section id="Reviews" className="mt-4">
                                <div className="lpReviews">
                                    <h2 className="heading">{reviewtitle?.reviews_title}</h2>
                                    <LpTCarousel arrows={false} responsive={responsive}
                                        infinite={true} autoPlay={true} className="testimonialList" showDots={true}>
                                        {review.map((review, index) => (
                                            <div className="htestmonial ltestmonial">
                                                <div className="single-reviews-box single-reviews-lading mlr-5">
                                                    <div className="reviews-top-content reviewTop">
                                                        <p>{review?.reviews_desc}</p>
                                                    </div>
                                                    <div className="reviews-bottom-content">
                                                        <div className="row align-items-center">
                                                            <div className="col-12">
                                                                <h3>{review?.reviews_name}</h3>
                                                                <div style={{ display: "flex" }}>
                                                                    <p>{review?.reviews_rating}/5</p>
                                                                    <ul>
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarPurple500 />
                                                                        <MdOutlineStarHalf />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </LpTCarousel>
                                </div>
                            </div>
                            <div data-section id="Whyuse" className="mt-4">
                                <div className="lpWhyuse">
                                    <h2 className="heading">Why Us</h2>
                                    <div className="col-lg-12 lpWhyUseMain">
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={gmp} alt={gmp} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>GMP Certified</h4>
                                                <p>Delivering high standard & authentic ayurvedic products</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={natural} alt={natural} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Pure Organic</h4>
                                                <p>Ingredients sourced indigenously from mountains & forests</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={ayush} alt={ayush} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Ayush Approved</h4>
                                                <p>Approved by Ministry of Ayush, Government of india</p>
                                            </div>
                                        </div>
                                        <div className="whyCard">
                                            <div className="img">
                                                <img src={cod} alt={cod} />
                                            </div>
                                            <div className="whyDesc">
                                                <h4>Full Convenience</h4>
                                                <p>All India free shipping & cashon delivery option available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 productDetailBtn">
                            <div className="stickyBuySection">
                                <h4>{productd?.page_title}</h4>
                                <p>{`${productd?.short_desc}... `}</p>
                                <div className="pro-price mb-4">
                                    <span className="new-price">{numberFormat(productd?.price)}</span>
                                    <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                    <div className="Pro-lable">
                                        <span className="p-discount-sticky">Save {productd?.product_save}</span>
                                    </div>
                                </div>
                                <button onClick={() => buyItNow(productd)}>Buy It Now</button>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <section className="fact-area mob sticky-landing-page">
                <div className="container">
                    <div className="col-12 col-lg-12 pt-1">
                        <div className="">
                            <h4 className="sticky-titles productname-sticky">{productd?.page_title}</h4>
                        </div>
                    </div>
                    <div className="row text-center mb-2">
                        <div className="col-3 col-lg-3 pt-1 d-flex">
                            <div style={{ margin: "auto" }}>
                                <p className="sticycutprice"><del>{numberFormat(productd?.product_cut_price)}</del></p>
                                <span className="sticky-price">{numberFormat(productd?.price)}</span>
                            </div>
                        </div>
                        <div className="col-9 col-lg-9 pt-1 d-flex" style={{ justifyContent: "end" }}>
                            {(() => {
                                if (productd?.display_mobile_status === 1) {
                                    return (
                                        <Link className="btncallnow" to={`tel: ${productd?.display_mobile_number}`}><HiOutlinePhoneMissedCall className="userIcons" /> Call Now</Link>
                                    )
                                }
                            })()}
                            <div className="sticky-buy-now" id="cartsticky">
                                <span className="">
                                    <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                </span>
                                <button onClick={() => buyItNow(productd)}>Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default LPPage