import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import error404 from "../assets/oops.webp";
import smile from "../assets/smile.gif";
import { getError404PageStatusApi } from '../utils/api';


const Erorr404 = () => {
    const pathname = window.location.href;

    useEffect(() => {
        getErrorPageStatusApi();
    }, [])

    const getErrorPageStatusApi = () => {

        getError404PageStatusApi('get-error-404-page').then((res) => {
            
        })
    }
    return (
        <div className="error404">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Page not found</title>
                <link rel="canonical" href={pathname} />
                <meta name="description" content="Page not found" />
                <meta http-equiv="status" content="404" />
                <meta name='errorpage' content='true' />
                <meta name='errortype' content='404 - Not Found' />
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <div className="breadcrumbs errorbreadcum">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Page Not Founds</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 col-sm-12">
                <ContentWrapper>
                    <div className="error404Img mb-4 bg-white">
                        <div className="img">
                            <img src={smile} width={110} />
                            <img src={error404} />
                        </div>
                        <div className="contenterror">
                            <h4>Something went wrong!!</h4>
                            <p>The page you were looking for appears to have been moved, deleted or does not exist. Go to our <Link to="/">homepage</Link> or proceed to one of these pages.</p>
                            <p>
                                <Link to="/products">PRODUCT</Link>
                            </p>
                            <p>
                                <Link to="/ingredients">INGREDIENTS</Link>
                            </p>
                            <p>
                                <Link to="/shop-by-concern">SHOP BY CONCERN</Link>
                            </p>
                            <p>
                                <Link target="_blank" to="/blog/">BLOG</Link>
                            </p>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Erorr404
