import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.scss'
import Home from './pages/Home';
import Header from './pages/Header';
import Footer from "./pages/Footer";
import Login from "./pages/Login";
import MobileOtp from "./pages/MobileOtp";
import TermCondition from "./pages/TermCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import FAQS from "./pages/FAQS";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";
import HealthCare from "./pages/HealthCare";
import Deals from "./pages/Deals";
import ProductDetails from "./pages/ProductDetails";
import CheckOut from "./pages/CheckOut";
import Erorr404 from "./pages/Erorr404";
import UserDashboard from "./pages/UserDashboard";
import MobileSticky from "./components/MobileSticky";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from 'react-use-cart';
import OrderConfirmation from "./pages/OrderConfirmation";
import OrderInvoice from "./pages/OrderInvoice";
import Combo from "./pages/Combo";
import Testimonials from "../src/pages/Testimonials";
import LandingPage from "./pages/LandingPage";
import MyAccount from "./pages/MyAccount";
import MyOrders from "./pages/MyOrders";
import Cart from "./pages/Cart";
import CheckOutLogin from "./pages/CheckOutLogin";
import DeliveryAddress from "./pages/DeliveryAddress";
import OrderSummary from "./pages/OrderSummary";
import PaymentOptions from "./pages/PaymentOptions";
import AyurvedaProducts from "./pages/AyurvedaProducts";
import VitaminNutrition from "./pages/VitaminNutrition";
import Nutraceutical from "./pages/Nutraceutical";
import AyurvedaProductDetails from "./pages/AyurvedaProductDetails";
import VitaminNutritionDetails from "./pages/VitaminNutritionDetails";
import NutraceuticalDetails from "./pages/NutraceuticalDetails";
import Ingredients from "./pages/Ingredients";
import PaymentSuccess from "./pages/PaymentSuccess";
import OrderAddress from "./pages/OrderAddress";
import EditDeliveryAddress from "./pages/EditDeliveryAddress";
import PaymentFailed from "./pages/PaymentFailed";
import CategoryProduct from "./pages/CategoryProduct";
import LPPage from "./pages/LPPage";
import SearchProduct from "./pages/SearchProduct";
import NewLandingPage from "./pages/NewLandingPage";
import TalkToOurExperts from "./pages/TalkToOurExperts";


function App() {
  return (
    <>
      <CartProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mobile-otp" element={<MobileOtp />} />
            <Route path="/term-and-condition" element={<TermCondition />} />
            <Route path="/privacy-Policy" element={<PrivacyPolicy />} />
            <Route path="/refund-Policy" element={<RefundPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/faqs" element={<FAQS />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/products" element={<Product />} />
            <Route path="/ayurveda-products" element={<AyurvedaProducts />} />
            <Route path="/ayurveda-product/:slug" element={<AyurvedaProductDetails />} />
            <Route path="/vitamin-and-supplements" element={<VitaminNutrition />} />
            <Route path="/vitamin-and-supplements/:slug" element={<VitaminNutritionDetails />} />
            <Route path="/herbal" element={<Nutraceutical />} />
            <Route path="/herbal/:slug" element={<NutraceuticalDetails />} />
            <Route path="/shop-by-concern" element={<HealthCare />} />
            <Route path="/category/:slug" element={<CategoryProduct />} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/product/:slug" element={<ProductDetails />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout-login" element={<CheckOutLogin />} />
            <Route path="/delivery-address" element={<DeliveryAddress />} />
            <Route path="/order-summary" element={<OrderSummary />} />
            <Route path="/payment-options" element={<PaymentOptions />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/thank-you" element={<OrderConfirmation />} />
            <Route path="/my-order" element={<MyOrders />} />
            <Route path="/order-invoice/:orderid" element={<OrderInvoice />} />
            <Route path="/combo" element={<Combo />} />
            <Route path="/testimonial" element={<Testimonials />} />
            <Route path="promotions/:slug" element={<LandingPage />} />
            <Route path="/lp/:slug" element={<LPPage />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/order-address" element={<OrderAddress />} />
            <Route path="edit-delivery-address/:slug" element={<EditDeliveryAddress />} />
            <Route path="/ingredients" element={<Ingredients />} />
            <Route path="/payment-status" element={<PaymentSuccess />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/landing-page/:slug" element={<NewLandingPage />} />
            <Route path="/search/*" element={<SearchProduct />} />
            <Route path="/talk-to-expert" element={<TalkToOurExperts />} />
            <Route path="*" element={<Erorr404 />} status={404} />
          </Routes>
          <Footer />
          <MobileSticky />
        </BrowserRouter>
        <ToastContainer position="bottom-center" />
      </CartProvider>
    </>
  )
}

export default App
