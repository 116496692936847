import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Step, Stepper } from 'react-form-stepper'
import { FaUserAlt } from 'react-icons/fa'
import { MdLocationPin } from 'react-icons/md'
import { AiOutlineMinus, AiOutlineMobile, AiOutlinePlus } from 'react-icons/ai'
import { useCart } from 'react-use-cart'
import { numberFormat } from '../components/numberFormate'
import { BsTrash3 } from 'react-icons/bs'
import { useEffect } from 'react'
import { getSingleDataApi } from '../utils/api'
import { useState } from 'react'
import TagManager from 'react-gtm-module'

const OrderSummary = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const [delivery, setDelivery] = useState([]);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const deleiveryid = localStorage.getItem("deliveryid");
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    useEffect(() => {
        getDeliveryAddress();
    }, [])

    const {
        items,
        updateItemQuantity,
        cartTotal,
        isEmpty,
    } = useCart();

    const { removeItem } = useCart();
    const removeItems = (id) => {
        removeItem(id);
        TagManager.dataLayer({
            dataLayer: {
                event: "remove_from_cart",
                pagePath: pathname,
                userId:"SK-" + userid,
                eventModel: {
                    ecomm_totalvalue: cartTotal,
                    ecomm_pagetype: "Remove Cart Item",
                    value: cartTotal,
                    currency: "INR",
                    items
                }
            },
        });
    };
    
    const getDeliveryAddress = () => {
        getSingleDataApi(`get-delivery-address/${deleiveryid}`).then((res) => {
            if (res.data.status === 1) {
                setDelivery(...[res.data.delivery])
            }
        })
    }
    const handlePaymentOption = () =>{
        TagManager.dataLayer({
            dataLayer: {
                event: "view_cart",
                pagePath: pathname,
                eventModel: {
                    ecomm_totalvalue: cartTotal,
                    ecomm_pagetype: "Order Summary",
                    value: cartTotal,
                    currency: "INR",
                    items
                }
            },
        });
        navigate('/payment-options');
    }
    return (
        <div className="orderSummarySection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Order Summary</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="spetbar-card">
                        <Stepper activeStep={2}>
                            <Step label="LOGIN" />
                            <Step label="DELIVERY ADDRESS" />
                            <Step label="ORDER SUMMARY" />
                            <Step label="PAYMENT OPTIONS" />
                        </Stepper>
                    </div>
                    <div className="account-card">
                        <div className="account-title">
                            <h4>Billing Address</h4>
                            {isEmpty == "" ?
                                <Link to="/delivery-address">Change Address</Link>
                                :
                                ""
                            }
                        </div>
                        <div className="account-content">
                            <div className="billingsAddress">
                                <FaUserAlt className="userIcons" />
                                <p>{delivery.delivery_name}</p>
                            </div>
                            <div className="billingsAddress">
                                <MdLocationPin className="locationIcon" />
                                <p>{delivery.delivery_addresse}, {delivery.delivery_state}, {delivery.delivery_pincode}</p>
                            </div>
                            <div className="billingsAddress">
                                <AiOutlineMobile className="locationIcon" />
                                <b>+91-{delivery.delivery_mobile}</b>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-8">
                            {items?.map((item, index) => (
                                <div className="card-summary">
                                    <div className="col-md-12">
                                        <div className="card-summary-details">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="cartImg">
                                                        <img src={BASE_URL + item.image} alt={item.image} />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="orderSummaryDetailsPrice">
                                                        <h4>{item.name}
                                                            <button className="btntrash">
                                                                <BsTrash3 onClick={() => removeItems(item.id)} />
                                                            </button></h4>
                                                        <p>QTY: <b>{item.quantity}</b></p>
                                                        <p>price: {numberFormat(item.price)}</p>
                                                        <p>Sub Total: <b>{numberFormat(item.itemTotal)}</b></p>
                                                        <div className="cart-action-group mt-4">
                                                            <div className="product-action">
                                                                <button className="action-minus" title="Quantity Minus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                                    <AiOutlineMinus />
                                                                </button>
                                                                <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value={item.quantity} readOnly={true} />
                                                                <button className="action-plus" title="Quantity Plus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                                    <AiOutlinePlus />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-4">
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Price Details</h4>
                                </div>
                                <div className="price-summary">
                                    <div className="price-summary-details">
                                        <span>Sub Total</span>
                                        <span>{numberFormat(cartTotal)}</span>
                                    </div>
                                    <div className="price-summary-details">
                                        <span>Discount</span>
                                        <span className="priceCharge"><b>0.00</b></span>
                                    </div>
                                    <div className="price-summary-details">
                                        <span>Delivery Charges</span>
                                        <span className="priceCharge"><b>Free</b></span>
                                    </div>
                                    <div className="price-summary-details total-amount-details">
                                        <span><b>Total Amount</b></span>
                                        <span><b>{numberFormat(cartTotal)}</b></span>
                                    </div>
                                    {isEmpty == "" ?
                                        <div className="price-summary-payment">
                                            <button onClick={()=>handlePaymentOption()}>PAYMENT OPTIONS</button>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </ContentWrapper>
            </div>
        </div>
    )
}

export default OrderSummary
