import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import Img from './lazyLoadImage/Img'
import { Link, useNavigate } from 'react-router-dom';
import { AiTwotoneStar } from "react-icons/ai";
import { fetchDataFromApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from './numberFormate';
import { useCart } from 'react-use-cart';
import Loading from './Loading';
import TagManager from 'react-gtm-module';

const TopSelling = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const pathname = window.location.href;

    const [topSelling, setTopSelling] = useState([]);
    const [topsellingtitle, setTopSellingTitle] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    useEffect(() => {
        getTopSellingProducts();
        getTopSellingTitle();
    }, []);

    const getTopSellingTitle = () => {
        setLoading(true)
        fetchDataFromApi('home-top-selling-title').then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setTopSellingTitle(...[res.data]);
            }
            else {
                setLoading(true)
            }
        })
    }
    const getTopSellingProducts = () => {
        setLoading(true)
        fetchDataFromApi('home-top-selling-product').then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setTopSelling(...[res.data]);
            }
            else {
                setLoading(false)
            }
        })
    }
    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: topsellingtitle?.top_selling_heading,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }

    const buyItNow = (product) => {
        addItem(product);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: topsellingtitle?.top_selling_heading,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Begin Checkout",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    return (
        <div className="topSellingSection">
            <ContentWrapper>
                <div className="col-lg-12">
                    <div className="appContainer mt-4">
                        <h2 className="text-center text-uppercase">
                            {topsellingtitle?.top_selling_heading}
                        </h2>
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: topsellingtitle?.top_selling_desc }}></div>
                    </div>
                    <div className="row">
                        {topSelling?.map((topSelling, index) => (
                            <div className="col-md-6 col-sm-12 mt-4" key={index}>
                                <div className="topSellingCart">
                                    <div className="topSellingImg">
                                        {(() => {
                                            if (topSelling?.product_type === "Vitamin & Nutrition") {
                                                return (
                                                    <Link to={`/vitamin-and-supplements/${topSelling?.product_slug_url}`}>
                                                        <img src={BASE_URL + topSelling?.image} alt={topSelling?.product_alt_name} title={topSelling?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else if (topSelling?.product_type === "Nutraceuticals") {
                                                return (
                                                    <Link to={`/herbal/${topSelling?.product_slug_url}`}>
                                                        <img src={BASE_URL + topSelling?.image} alt={topSelling?.product_alt_name} title={topSelling?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Link to={`/product/${topSelling?.product_slug_url}`}>
                                                        <img src={BASE_URL + topSelling?.image} alt={topSelling?.product_alt_name} title={topSelling?.product_alt_name} />
                                                    </Link>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <div className="topSellingPriceList">
                                        <div className="pricingList">
                                            {(() => {
                                                if (topSelling?.product_type === "Vitamin & Nutrition") {
                                                    return (
                                                        <Link to={`/vitamin-and-supplements/${topSelling?.product_slug_url}`}>
                                                            <h4 className="title">{topSelling?.page_title}</h4>
                                                        </Link>
                                                    )
                                                }
                                                else if (topSelling?.product_type === "Nutraceuticals") {
                                                    return (
                                                        <Link to={`/herbal/${topSelling?.product_slug_url}`}>
                                                            <h4 className="title">{topSelling?.page_title}</h4>
                                                        </Link>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Link to={`/product/${topSelling?.product_slug_url}`}>
                                                            <h4 className="title">{topSelling?.page_title}</h4>
                                                        </Link>
                                                    )
                                                }
                                            })()}
                                            <p className="topPriceSection">
                                                {(() => {
                                                    if (topSelling?.price != 0) {
                                                        return (
                                                            <div className="productPrice">
                                                                <span>{numberFormat(topSelling?.price)}</span>&nbsp;
                                                                <del>{numberFormat(topSelling?.product_cut_price)}</del>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                                <div className="productRatings">
                                                    {topSelling?.product_rating}
                                                    <AiTwotoneStar className="ratingIcons" />
                                                </div>
                                            </p>
                                            <p className="topProductDesc">{`${topSelling?.short_desc.substring(0, 150)}...`}</p>
                                        </div>
                                        {(() => {
                                            if (topSelling?.price != 0) {
                                                return (
                                                    <div className="btnMainProduct">
                                                        <button onClick={() => addToCart(topSelling)} className="addToCartBtn">Add To Cart</button>
                                                        <button onClick={() => buyItNow(topSelling)} className="buyItNowBtn">Buy It Now</button>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default TopSelling
