import React, { useEffect } from 'react'
import { Step, Stepper } from 'react-form-stepper';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { MdNotificationsActive } from 'react-icons/md';
import { ImTruck } from 'react-icons/im';
import { useState } from 'react';
import { userLoginAPi, userVerifyMobileOTPAPi } from '../utils/api';
import { toast } from 'react-toastify';
import TagManager from 'react-gtm-module';

const CheckOutLogin = () => {
    const pathname = window.location.href;
    const [toggled, setToggled] = useState(true);
    const [mobile, setMobile] = useState("");
    const [mobileotp, setMobileOTP] = useState("");
    const navigate = useNavigate();
    const rmobile = localStorage.getItem("usermobile");

    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    useEffect(() => {
        if (userid) {
            navigate('/my-account');
        }
        else{
            navigate("/checkout-login");
        }
    }, [userid]);

    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobile(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (mobile.length === 10) {
            userLoginAPi("user-send-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("usermobile", mobile)
                    toast.success("Mobile OTP has been send succssfully");
                    e.target.reset();
                    setToggled(!toggled);
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }
    const NumericOTPOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobileOTP(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    // Matched Mobile OTP
    const handleMobileOTPSubmit = async (e) => {
        e.preventDefault();
        if (mobileotp.length === 4) {
            const mobile = localStorage.getItem("usermobile");
            const mobiledata = {
                mobile,
                mobileotp,
            };
            userVerifyMobileOTPAPi("user-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("userdata", JSON.stringify(res.data.userdata))
                    toast.success("Your mobile OTP has been verified!!");
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "login",
                            pagePath: pathname,
                            userId:"SK-" + res.data.userdata.id
                        },
                    });
                    navigate('/delivery-address');
                }
                else {
                    toast.error("Unable to verify mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile OTP");
        }
    }
    const resendMobileOTP = () => {
        if (mobile.length === 10) {
            userLoginAPi("user-resend-mobile-otp", rmobile).then((res) => {
                if (res.data.status === 1) {
                    toast.success("Mobile OTP has been send succssfully");
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }
    const displayLoginSection = () => {
        if (toggled) {
            return <div className="account-card">
                <div className="account-title">
                    <h4>User Login</h4>
                </div>
                <div className="account-content">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <form method="post" className="checkout-login" onSubmit={handleLoginSubmit}>
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile Number</label>
                                    <input type="text" name="mobile" id="mobile" onChange={NumericOnly} placeholder="Mobile Number" pattern="[6789][0-9]{9}" maxLength={10} minLength={10} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btncheckcont" name="login">Send OTP</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="secureLogin">
                                <h4>Advantages of our secure login</h4>
                                <ul>
                                    <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                    <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return <div className="account-card">
            <div className="account-title">
                <h4>Verify Mobile OTP</h4>
            </div>
            <div className="account-content">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <form method="post" className="checkout-login" onSubmit={handleMobileOTPSubmit}>
                            <div className="form-group">
                                <label htmlFor="mobileotp">Enter Mobile OTP</label>
                                <input onChange={NumericOTPOnly} type="text" name="mobileotp" id="mobileotp" placeholder="Enter your Mobile OTP" className="form-control" />
                            </div>
                            <a href="javascript:void(0)" onClick={resendMobileOTP} className="checRresendMobileTop">Resend Mobile OTP</a>
                            <div className="form-group">
                                <button className="btncheckcont" name="login">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="secureLogin">
                            <h4>Advantages of our secure login</h4>
                            <ul>
                                <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };
    return (
        <div className="checkOutLogin">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Login</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="spetbar-card">
                        <Stepper activeStep={0}>
                            <Step label="LOGIN" />
                            <Step label="DELIVERY ADDRESS" />
                            <Step label="ORDER SUMMARY" />
                            <Step label="PAYMENT OPTIONS" />
                        </Stepper>
                    </div>
                    {displayLoginSection()}
                </ContentWrapper>
            </div>
        </div>
    )
}

export default CheckOutLogin
