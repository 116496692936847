import React from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Testimonial from '../components/Testimonial';


const Testimonials = () => {
    const pathname = window.location.href;
    return (
        <div className="testimonialSection">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Client Testimonials: Success Stories of our Ayurvedic Medicines</title>
                <link rel="canonical" href={pathname} />

            </Helmet>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span >Home</span></Link>
                        </li>
                        <li className="items product"><span>Testimonials</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12 bg-white">
                <ContentWrapper>
                    <Testimonial />
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Testimonials
