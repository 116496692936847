import React, { useEffect, useState } from 'react'
import ContentWrapper from './contentWrapper/ContentWrapper'
import Img from './lazyLoadImage/Img';
import { Link } from 'react-router-dom';
import { fetchDataFromApi } from '../utils/api';
import Loading from './Loading';
import HCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HealthCare = () => {
    const [categorytitle, setCategoryTitle] = useState([]);
    const [category, setCategory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/category/";

    useEffect(() => {
        getCategoryTitle();
        getCategory();
    }, [])

    const getCategoryTitle = () => {
        setLoading(true)
        fetchDataFromApi('home-category-title').then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setCategoryTitle(...[res.data]);
            }
            else {
                setLoading(true)
            }
        })
    };

    const getCategory = () => {
        setLoading(true)
        fetchDataFromApi('home-category').then((res) => {
            if (res.status === 1) {
                setLoading(false)
                setCategory(...[res.data]);
            }
            else {
                setLoading(true)
            }
        })
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };
    return (
        <div className="healthCareSection">
            <ContentWrapper>
                <div className="appContainer">
                    <h2 className="text-center text-uppercase">{categorytitle?.category_heading}</h2>
                    <div className="text-justify" dangerouslySetInnerHTML={{ __html: categorytitle?.category_desc }}></div>
                </div>
                <div className="healthCareList descktopview">
                    <HCarousel responsive={responsive}
                        infinite={true} arrows={true} showDots={false}  >
                        {category?.map((category, index) => (
                            <div className="col-md-12" key={index}>
                                <div key={index}>
                                    <Link to={`/category/${category?.category_slug_url}`}>
                                        <img fetchpriority="high" src={BASE_URL + category?.category_img} alt={category?.category_img} title={category?.category_img} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </HCarousel>
                </div>
                <div className="healthCareList col-lg-12 mobileview">
                    <div className="healthCarebody">
                        {category?.map((category, index) => (
                            <div className="healthCarebodyimage" key={index}>
                                <div>
                                    <Link to={`/category/${category?.category_slug_url}`}>
                                        <img fetchpriority="high" src={BASE_URL + category?.category_img} alt={category?.category_img} title={category?.category_img} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default HealthCare
