import React, { useEffect, useState, lazy, Suspense, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { AiFillStar, AiOutlineShoppingCart } from "react-icons/ai";
import { BsCheck2Square, BsStarHalf } from "react-icons/bs";
import 'react-accessible-accordion/dist/fancy-example.css';
import { getMultitpleDataApi, getProductDetailApi } from '../utils/api';
import { toast } from 'react-toastify';
import { numberFormat } from '../components/numberFormate';
import { useCart } from 'react-use-cart';
import Img from '../components/lazyLoadImage/Img';
import ayurvedic from "../assets/landingpage/100-safe-and-natural.webp";
import codimg from "../assets/landingpage/cash-on-delivery.webp";
import freece from "../assets/landingpage/Free-expert-consultation.webp";
import freeshipping from "../assets/landingpage/free-shipping-in-india.webp";
import { MetaTags } from 'react-meta-tags';
import TagManager from 'react-gtm-module'
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { HiOutlineCheckCircle } from 'react-icons/hi';
import InCarousel from "react-multi-carousel";
import moment from 'moment';
import { FaRegThumbsUp } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { RxCrossCircled } from 'react-icons/rx';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ProductDetails = () => {

    const { addItem } = useCart();
    const navigate = useNavigate();
    //const [review, setReview] = useState([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    const [productd, setProductd] = useState([]);
    const productid = productd?.id;
    localStorage.setItem("lproductid", productid);

    const [toverview, setTOverview] = useState([]);
    const [overview, setOverview] = useState([]);

    const [mimage, setMImage] = useState([]);
    const [highliter, setHighliter] = useState([]);

    const [tpdetail, setTPdetail] = useState([]);
    const [pdetail, setPdetail] = useState([]);

    const [tbenefit, setTbenefit] = useState([]);
    const [benefit, setBenefit] = useState([]);

    const [thowtouse, setTHowToUse] = useState([]);
    const [howtouse, setHowToUse] = useState([]);

    const { slug } = useParams();
    const pathname = window.location.href;
    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    const [tingredient, setTIngredient] = useState([]);
    const [ingredient, setIngredient] = useState([]);
    const BASE_URLI = "https://backendsatkartar.satkartar.co.in/assets/img/ingredient/";

    const [treview, setTReview] = useState([]);
    const [review, setReview] = useState([]);

    const [tfaq, setTFaq] = useState([]);
    const [faq, setFaq] = useState([]);

    const [tcompare, setTCompare] = useState([]);
    const [compare, setCompare] = useState([]);
    const BASE_URLC = "https://backendsatkartar.satkartar.co.in/assets/img/compare/";

    const [tproblem, setTProblem] = useState([]);
    const [problem, setProblem] = useState([]);

    const [tsolution, setTSolution] = useState([]);
    const [solution, setSolution] = useState([]);

    const [thowitwork, setTHowiTWork] = useState([]);
    const [howitwork, setHowItWork] = useState([]);
    const BASEH_URLH = "https://backendsatkartar.satkartar.co.in/assets/img/how/";


    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop && pageYOffset
                < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };
    // Content Menu
    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        getProductDetail(slug);
        if (productid) {
            getProductMoreImage(productid);
            getProductHighliter(productid);
            getoverviewTitle(productid);
            getoverview(productid);
            getPDetailTitle(productid);
            getPdetail(productid);
            getPBenefitTitle(productid);
            getPBenefit(productid);
            getPHowToUseTitle(productid);
            getPHowToUse(productid);
            getPIngredientTitle(productid);
            getPIngredient(productid);
            getPReviewTitle(productid);
            getPReview(productid);
            getPFAQTitle(productid);
            getPFAQ(productid);
            getPCompareTitle(productid);
            getPCompare(productid);
            getProblemTitle(productid);
            getProblem(productid);
            getSolutionTitle(productid);
            getSolution(productid);
            getPHowItWorkTitle(productid);
            getPHowItWork(productid);
        }
    }, [slug, productid])
    const getProductDetail = (slug) => {
        getProductDetailApi(`get-product-details/${slug}`).then((res) => {
            if (res?.data.status === 1) {
                setProductd(...[res?.data.product]);
            }
            else {
                window.open('/page-not-found', "_self");
            }
        })
        .catch((error) => {    
            window.open('/page-not-found', "_self");
        });
    }
    const getProductMoreImage = (productid) => {
        getMultitpleDataApi(`get-multitple-data/${productid}`).then((res) => {
            setMImage(...[res?.data.pimage]);
        })
    }
    const getProductHighliter = (productid) => {
        getMultitpleDataApi(`get-product-highliter-data/${productid}`).then((res) => {
            setHighliter(...[res?.data.highliter]);
        })
    }
    const getoverviewTitle = (productid) => {
        getMultitpleDataApi(`get-overview-title-data/${productid}`).then((res) => {
            setTOverview(...[res.data?.toverview]);
        })
    }
    const getoverview = (productid) => {
        getMultitpleDataApi(`get-overview-data/${productid}`).then((res) => {
            setOverview(...[res.data?.overview]);
        })
    }
    const getPDetailTitle = (productid) => {
        getMultitpleDataApi(`get-product-detail-title-data/${productid}`).then((res) => {
            setTPdetail(...[res.data?.tpdetail]);
        })
    }
    const getPdetail = (productid) => {
        getMultitpleDataApi(`get-product-detail-data/${productid}`).then((res) => {
            setPdetail(...[res.data?.pdetail]);
        })
    }
    const getPBenefitTitle = (productid) => {
        getMultitpleDataApi(`get-product-benefit-title-data/${productid}`).then((res) => {
            setTbenefit(...[res.data?.tbenefit]);
        })
    }
    const getPBenefit = (productid) => {
        getMultitpleDataApi(`get-product-benefit-data/${productid}`).then((res) => {
            setBenefit(...[res.data?.benefit]);
        })
    }
    const getPHowToUseTitle = (productid) => {
        getMultitpleDataApi(`get-product-how-to-use-title-data/${productid}`).then((res) => {
            setTHowToUse(...[res.data?.thowtouse]);
        })
    }
    const getPHowToUse = (productid) => {
        getMultitpleDataApi(`get-product-how-to-use-data/${productid}`).then((res) => {
            setHowToUse(...[res.data?.howtouse]);
        })
    }
    const getPIngredientTitle = (productid) => {
        getMultitpleDataApi(`get-product-ingredient-title-data/${productid}`).then((res) => {
            setTIngredient(...[res.data?.tingredient]);
        })
    }
    const getPIngredient = (productid) => {
        getMultitpleDataApi(`get-product-ingredient-data/${productid}`).then((res) => {
            setIngredient(...[res.data?.ingredient]);
        })
    }
    const getPReviewTitle = (productid) => {
        getMultitpleDataApi(`get-product-review-title-data/${productid}`).then((res) => {
            setTReview(...[res.data?.treview]);
        })
    }
    const getPReview = (productid) => {
        getMultitpleDataApi(`get-product-review-data/${productid}`).then((res) => {
            setReview(...[res.data?.review]);
        })
    }
    const getPFAQTitle = (productid) => {
        getMultitpleDataApi(`get-product-faq-title-data/${productid}`).then((res) => {
            setTFaq(...[res.data?.tfaq]);
        })
    }
    const getPCompareTitle = (productid) => {
        getMultitpleDataApi(`get-product-compare-title-data/${productid}`).then((res) => {
            setTCompare(...[res.data?.tcompare]);
        })
    }
    const getPCompare = (productid) => {
        getMultitpleDataApi(`get-product-compare-data/${productid}`).then((res) => {
            setCompare(...[res.data?.compare]);
        })
    }
    const getProblemTitle = (productid) => {
        getMultitpleDataApi(`get-problem-title-data/${productid}`).then((res) => {
            setTProblem(...[res.data?.tproblem]);
        })
    }
    const getProblem = (productid) => {
        getMultitpleDataApi(`get-problem-data/${productid}`).then((res) => {
            setProblem(...[res.data?.problem]);
        })
    }
    const getSolutionTitle = (productid) => {
        getMultitpleDataApi(`get-solution-title-data/${productid}`).then((res) => {
            setTSolution(...[res.data?.tsolution]);
        })
    }
    const getSolution = (productid) => {
        getMultitpleDataApi(`get-solution-data/${productid}`).then((res) => {
            setSolution(...[res.data?.solution]);
        })
    }
    const getPHowItWorkTitle = (productid) => {
        getMultitpleDataApi(`get-product-how-it-work-title-data/${productid}`).then((res) => {
            setTHowiTWork(...[res.data?.thow]);
        })
    }
    const getPHowItWork = (productid) => {
        getMultitpleDataApi(`get-product-how-it-work-data/${productid}`).then((res) => {
            setHowItWork(...[res.data?.how]);
        })
    }
    const getPFAQ = (productid) => {
        getMultitpleDataApi(`get-product-faq-data/${productid}`).then((res) => {
            setFaq(...[res?.data.faq]);
        });
        TagManager.dataLayer({
            dataLayer: {
                event: "view_item",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd?.price,
                    ecomm_pagetype: "View Item",
                    userId: "SK-" + userid,
                    value: productd?.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd?.id,
                            name: productd?.name,
                            brand: "Satkartar",
                            price: productd?.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const activeStyle = {
        fontWeight: 'bold',
        color: 'red',
    };
    const addToCart = (productd) => {
        addItem(productd);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd?.price,
                    ecomm_pagetype: "Cart",
                    value: productd?.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd?.id,
                            name: productd?.name,
                            brand: "Satkartar",
                            price: productd?.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (productd) => {
        addItem(productd);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd?.price,
                    ecomm_pagetype: "Begin Checkout",
                    value: productd?.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd?.id,
                            name: productd?.name,
                            brand: "Satkartar",
                            price: productd?.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    let Schemadata = {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Products",
                "item": "https://www.satkartar.co.in/products"
            }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${productd?.name}`
            }]
        },
        "@context": "https://schema.org/",
        "@type": "Product",
        "sku": `${productd?.product_sku}`,
        "image": `${BASE_URL + productd?.image}`,
        "name": `${productd?.name}`,
        "description": `${productd?.short_desc}`,
        "brand": {
            "@type": "Brand",
            "name": "Satkartar"
        },
        "offers": {
            "@type": "Offer",
            "url": `${pathname}`,
            "itemCondition": "NewCondition",
            "availability": "InStock",
            "price": `${productd?.price}.00`,
            "priceCurrency": "INR",
            "priceValidUntil": "2030-11-20",
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": 0.00,
                    "currency": "INR"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": "IND"
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 0,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    },
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 1,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    }
                }
            }
        },
        "review": [
            review?.map((review) => (
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": `${review?.user_rating}`,
                        "bestRating": 5
                    },
                    "name": `${review?.user_desc}`,
                    "author": {
                        "@type": "Person",
                        "name": `${review?.user_name}`
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "Satkartar Shopping LTD."
                    }
                }
            )),
        ],
        "faqs": {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                faq?.map((faq) => (
                    {
                        "@type": "Question",
                        "name": `${faq?.faq_question}`,
                        "acceptedAnswer": [
                            {
                                "@type": "Answer",
                                "text": `${faq?.faq_desc}`
                            }
                        ]
                    }
                )),
            ]
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": `${productd?.product_rating}`,
            "reviewCount": `${productd?.price}`
        }
    }
    return (
        <div className="productDetailSection bg-white">
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(Schemadata) }}
            />
            <MetaTags>
                <title>{productd?.meta_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={productd?.meta_title} />
                <meta name="description" content={productd?.meta_description} />
                <meta name="keywords" content={productd?.meta_keyword} />
                <meta property="og:url" content={pathname} />
                {productd?.no_follow_index_status === 1 ?
                    <meta name="robots" content="noindex,nofollow" />
                    :
                    ""
                }
                <meta property="og:title" content={productd?.meta_title} />
                <meta property="og:description" content={productd?.meta_description} />
                <link rel="preload" fetchpriority="high" as="image" href={BASE_URL + productd?.image} type="image/webp"></link>
            </MetaTags>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="item product">
                            <Link to="/products" title="Go to Product Page"><span>Product</span></Link>
                        </li>
                        <li className="items product"><span>{productd?.page_title}</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row productDetailsSection">
                        <div className="col-md-6 col-sm-12 productBanner">
                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showIndicators={true} swipeable={true} showStatus={false}>
                                <div>
                                    <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                </div>
                                {mimage?.map((mimage, index) => (
                                    <div key={index}>
                                        <img rel="preload" fetchpriority="high" as="image" alt={mimage?.product_image} src={BASE_URL + mimage?.product_image} />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="col-md-6 col-sm-12 productContDetail">
                            <div className="productDetails">
                                <h1 className="sk-turbo-treats-shilajit-gummies-title">{productd?.name}</h1>
                                <hr />
                                <div className="reviewsBottomContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="inner-shop-details-meta" >
                                                <ul>
                                                    {/* <li>Pack Type : <b style={{ color: "#000" }}>{productd?.product_type}</b></li> */}
                                                    <li className="inner-shop-details-review d-flex">
                                                        <b className="trating">
                                                            {Math?.floor(Math.random() * 5000)} ratings
                                                        </b>
                                                    </li>
                                                    <li className="inner-shop-details-review d-flex">
                                                        <div className="rating">
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <AiFillStar />
                                                            <BsStarHalf />
                                                        </div>
                                                        {/* <span>({productd?.product_rating}★)</span> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="product-details">
                                                <ul>
                                                    {highliter?.map((highliter, index) => (
                                                        <li key={index}>
                                                            <span><IoCheckmarkDoneOutline className="productDescCheck" /></span>
                                                            <span>{highliter?.highlighter}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="pro-availabale">
                                                <span className="available">Availability:</span>
                                                <span className="pro-instock" style={{ color: "green" }}> {productd?.product_availability}</span>
                                            </div>
                                            {/* <span className="pro-details">{productd?.product_tag_line}</span> */}
                                            {(() => {
                                                if (productd?.price !== 0) {
                                                    return (
                                                        <div className="pro-price">
                                                            <span className="new-price">{numberFormat(productd?.price)}</span>
                                                            <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                                            <div className="Pro-lable">
                                                                <span className="p-discount">Save {productd?.product_save}</span>
                                                                <p>Limited Offer (inclusive of all taxes)</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            {(() => {
                                                if (productd?.price !== 0) {
                                                    return (
                                                        <div className="col-md-12">
                                                            <div className="productBtn">
                                                                <button onClick={() => addToCart(productd)} className="addToCart">Add To Cart</button>
                                                                <button onClick={() => buyItNow(productd)} className="buyItNow">Buy It Now</button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            <div className="col-lg-12">
                                                <div className="paymentType">
                                                    <div className="securePayment">
                                                        <Img width={80} height={80} src={freece} alt="Free Expert Consultation"></Img>
                                                        <h6>Free Expert Consultation</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <Img width={80} height={80} src={freeshipping} alt="Free Shipping on Prepaid"></Img>
                                                        <h6>Free Shipping on Prepaid</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <Img width={80} height={80} src={ayurvedic} alt="100 % Ayurvedic"></Img>
                                                        <h6>100 % Ayurvedic</h6>
                                                    </div>
                                                    <div className="securePayment">
                                                        <Img width={80} height={80} src={codimg} alt="Cash On Delivery"></Img>
                                                        <h6>Cash On Delivery</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <div className="lockicon">
                                                    <AiFillLock />
                                                    <h6>GUARANTEED SAFE CHECKOUT</h6>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-12">
                                                <div className="secure-payment-icon">
                                                    <Img src={paymenticons} alt="payment-icons"></Img>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>

            <div className="col-lg-12 mt-4  sectionMenu">
                <ContentWrapper>
                    <div className="col-md-12 col-sm-12">
                        <div className="sectionMenu">
                            <ul className="productDetailsMenu">
                                {(() => {
                                    if (overview) {
                                        return (
                                            <li className={activeSection === 'Overview' ? 'active' : ''}><a href="#Overview" style={activeSection ===
                                                'Overview' ? activeStyle : {}}>Overview<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (problem) {
                                        return (
                                            <li className={activeSection === 'Problem' ? 'active' : ''}><a href="#Problem" style={activeSection ===
                                                'Problem' ? activeStyle : {}}>Problem<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (solution) {
                                        return (
                                            <li className={activeSection === 'Solution' ? 'active' : ''}><a href="#Solution" style={activeSection ===
                                                'Solution' ? activeStyle : {}}>Solution<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (pdetail) {
                                        return (
                                            <li className={activeSection === 'ProductDetails' ? 'active' : ''}><a href="#ProductDetails" style={activeSection ===
                                                'ProductDetails' ? activeStyle : {}}>Product Details<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (benefit) {
                                        return (
                                            <li className={activeSection === 'Benefits' ? 'active' : ''}><a href="#Benefits" style={activeSection ===
                                                'Benefits' ? activeStyle : {}}>Benefits<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (ingredient) {
                                        return (
                                            <li className={activeSection === 'Ingredients' ? 'active' : ''}><a href="#Ingredients" style={activeSection ===
                                                'Ingredients' ? activeStyle : {}}>Ingredients<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (howtouse) {
                                        return (
                                            <li className={activeSection === 'HowToUse' ? 'active' : ''}><a href="#HowToUse" style={activeSection ===
                                                'HowToUse' ? activeStyle : {}}>How To Use<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (howitwork) {
                                        return (
                                            <li className={activeSection === 'HowitWorks' ? 'active' : ''}><a href="#HowitWorks" style={activeSection ===
                                                'HowitWorks' ? activeStyle : {}}>How It Works<span>|</span></a></li>
                                        )
                                    }
                                })()}

                                {(() => {
                                    if (compare) {
                                        return (
                                            <li className={activeSection === 'Comparison' ? 'active' : ''}><a href="#Comparison" style={activeSection ===
                                                'Comparison' ? activeStyle : {}}>Comparison<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (review) {
                                        return (
                                            <li className={activeSection === 'Reviews' ? 'active' : ''}><a href="#Reviews" style={activeSection ===
                                                'Problem' ? activeStyle : {}}>Reviews<span>|</span></a></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (faq) {
                                        return (
                                            <li className={activeSection === 'faqs' ? 'active' : ''}><a href="#faqs" style={activeSection ===
                                                'faqs' ? activeStyle : {}}>FAQs</a></li>
                                        )
                                    }
                                })()}
                            </ul>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-9 mb-4 col-sm-12 productDetailSection">
                            <Suspense>
                                {(() => {
                                    if (overview) {
                                        return (
                                            <div dataSection="true" id="Overview">
                                                <div id="Overview">
                                                    <div className="overviewDetails">
                                                        {toverview ?
                                                            <h2>{toverview?.overview_title}</h2>
                                                            :
                                                            <h2>Overview</h2>
                                                        }
                                                    </div>
                                                    <div className="col-md-12">
                                                        {overview?.map((overview, index) => (
                                                            <div className="processBox" key={index}>
                                                                <div className="servicesBox">
                                                                    <p className="mr-0">
                                                                        <div dangerouslySetInnerHTML={{ __html: overview?.overview_desc }}></div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (problem) {
                                        return (
                                            <div id="Problem" className="mt-4">
                                                <div className="problemDetails">
                                                    {tproblem ?
                                                        <h2>{tproblem?.problem_title}</h2>
                                                        :
                                                        <h2>Problem</h2>
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    {problem?.map((problem, index) => (
                                                        <div className="processBox" key={index}>
                                                            <div className="servicesBox">
                                                                <p><BsCheck2Square className="orangeicons" />{problem?.problem_desc}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (solution) {
                                        return (
                                            <div id="Solution" className="mt-4">
                                                <div className="solutionDetails">
                                                    {tsolution ?
                                                        <h2>{tsolution?.solution_title}</h2>
                                                        :
                                                        <h2>Solution</h2>
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    {solution?.map((solution, index) => (
                                                        <div className="processBox" key={index}>
                                                            <div className="servicesBox">
                                                                <p><BsCheck2Square className="greenicons" />{solution?.solution_desc}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (pdetail) {
                                        return (
                                            <div dataSection="true" id="ProductDetails">
                                                <div id="ProductDetails" className="ProductDetails mt-4">
                                                    <div className="productDetails">
                                                        {tpdetail ?
                                                            <h2>{tpdetail?.detail_title}</h2>
                                                            :
                                                            <h2>Product Details</h2>
                                                        }
                                                    </div>
                                                    <div className="col-md-12">

                                                        <div className="product-details-points">
                                                            <table className="table">
                                                                {pdetail?.map((pdetail, index) => (
                                                                    <tr key={index} style={{ borderBottom: "2px solid #e9dfdf" }}>
                                                                        <td><p className="productdetailsdesc">{pdetail?.detail_title}</p></td>
                                                                        <td><p className="productdetailsdesc">{pdetail?.detail_desc}</p></td>
                                                                    </tr>
                                                                ))}
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (benefit) {
                                        return (
                                            <div dataSection="true" id="Benefits">
                                                <div id="Benefits">
                                                    <div className="benefitDetails">
                                                        <div className="real-health">
                                                            {tbenefit ?
                                                                <h2>{tbenefit?.benefits_title}</h2>
                                                                :
                                                                <h2>Benefits</h2>
                                                            }
                                                            <ul>
                                                                {benefit?.map((benefit, index) => (
                                                                    <li key={index}>
                                                                        <span><HiOutlineCheckCircle className="greenicons" /></span>
                                                                        <span>{benefit?.benefits_desc}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (ingredient) {
                                        return (
                                            <div dataSection="true" id="Ingredients">
                                                <div id="Ingredients" className="">
                                                    <div className="ingredientDetails">
                                                        {tingredient ?
                                                            <h2>{tingredient?.ingredient_title}</h2>
                                                            :
                                                            <h2>Ingredient</h2>
                                                        }
                                                    </div>
                                                    <div className="row ingredient-text-view">
                                                        <InCarousel arrows={false} responsive={responsive} infinite={true} className="lpIngredientList" showDots={true} autoPlay={true}>
                                                            {ingredient?.map((ingredient, index) => (
                                                                <div className="ingredientcard" key={index}>
                                                                    <div className="imgSection">
                                                                        <img width={125} height={125} src={BASE_URLI + ingredient?.ingredient_img} alt={ingredient?.ingredient_alt_name} />
                                                                    </div>
                                                                    <div className="lpIngredientDescp">
                                                                        <h4>{ingredient?.ingredient_title}</h4>
                                                                        <p>{ingredient?.ingredient_desc}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </InCarousel>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (howtouse) {
                                        return (
                                            <div dataSection="true" id="HowToUse">
                                                <div id="HowToUse">
                                                    <div className="benefitDetails">
                                                        <div className="real-health">
                                                            {thowtouse ?
                                                                <h2>{thowtouse?.how_to_use_title}</h2>
                                                                :
                                                                <h2>How To Use</h2>
                                                            }
                                                            <ul>
                                                                {howtouse?.map((howtouse, index) => (
                                                                    <li key={index}>
                                                                        <span><IoCheckmarkDoneOutline className="greenicons" /></span>
                                                                        <span>{howtouse?.how_to_use}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (howitwork) {
                                        return (
                                            <div id="HowitWorks">
                                                <div className="howitsworkDetails">
                                                    {thowitwork ?
                                                        <h2 className="text-center">{thowitwork?.howitwork_title}</h2>
                                                        :
                                                        <h2 className="text-center">How Its Work</h2>
                                                    }
                                                </div>
                                                <div className="main-timeline9">
                                                    {howitwork?.map((howitwork, index) => (
                                                        <>
                                                            {index === 0 ? (
                                                                <div className="timeline">
                                                                    <div className="timeline-content">
                                                                        <div className="circle">
                                                                            <span>
                                                                                <img width={164} height={164} style={{ width: "100%" }} src={BASEH_URLH + howitwork?.how_img} alt={howitwork?.how_img} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="content">
                                                                            <span className="year">{howitwork?.stage_type}</span>
                                                                            <h4 className="title">{howitwork?.how_title}</h4>
                                                                            <p className="description">
                                                                            </p><div dangerouslySetInnerHTML={{ __html: howitwork?.how_desc }}></div>
                                                                            <p></p>
                                                                            <div className="icon"><span></span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="timeline">
                                                                    <div className="timeline-content">
                                                                        <div className="circle">
                                                                            <span>
                                                                                <img width={164} height={164} style={{ width: "100%" }} src={BASEH_URLH + howitwork?.how_img} alt={howitwork?.how_img} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="content">
                                                                            <span className="year">{howitwork?.stage_type}</span>
                                                                            <h4 className="title">{howitwork?.how_title}</h4>
                                                                            <p className="description">
                                                                            </p><div dangerouslySetInnerHTML={{ __html: howitwork?.how_desc }}></div>
                                                                            <p></p>
                                                                            <div className="icon"><span></span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (compare) {
                                        return (
                                            <div id="Comparison">
                                                <div className="comparisonDetails">
                                                    <div className="real-health">
                                                        {tcompare ?
                                                            <h2>{tcompare?.comparison_title}</h2>
                                                            :
                                                            <h2>Comparison</h2>
                                                        }
                                                        <div className="comparison-product">
                                                            <table className="table">
                                                                <tbody><tr>
                                                                    <td colSpan="2" className="comparisonRightimg">
                                                                        <Img src={BASE_URLC + compare?.compare_img} alt={compare?.compare_img}></Img>
                                                                    </td>
                                                                    <td colSpan="2" className="comparisonWrongimg">
                                                                        <Img src={BASE_URLC + compare?.other_img} alt={compare?.other_img}></Img>
                                                                    </td>
                                                                </tr>
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <button className="btn-product">{compare?.product_compare_name}</button>
                                                                        </td>
                                                                        <td colSpan="2">
                                                                            <button className="btn-other">{compare?.product_other_name}</button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <HiOutlineCheckCircle className="greenicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-right-tick-span">{compare?.compare_point1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <RxCrossCircled className="redicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-wrong-tick-span">{compare?.other_point1}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <HiOutlineCheckCircle className="greenicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-right-tick-span">{compare?.compare_point2}</span>
                                                                        </td>
                                                                        <td>
                                                                            <RxCrossCircled className="redicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-wrong-tick-span">{compare?.other_point2}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <HiOutlineCheckCircle className="greenicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-right-tick-span">{compare?.compare_point3}</span>
                                                                        </td>
                                                                        <td>
                                                                            <RxCrossCircled className="redicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-wrong-tick-span">{compare?.other_point3}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <HiOutlineCheckCircle className="greenicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-right-tick-span">{compare?.compare_point4}</span>
                                                                        </td>
                                                                        <td>
                                                                            <RxCrossCircled className="redicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-wrong-tick-span">{compare?.other_point4}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <HiOutlineCheckCircle className="greenicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-right-tick-span">{compare?.compare_point5}</span>
                                                                        </td>
                                                                        <td>
                                                                            <RxCrossCircled className="redicons" />
                                                                        </td>
                                                                        <td style={{ "textAlign": "left" }}>
                                                                            <span className="product-wrong-tick-span">{compare?.other_point5}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody></table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (review) {
                                        return (
                                            <div dataSection="true" id="Reviews">
                                                <div id="Reviews" className="mt-4">
                                                    <div className="reviewsDetails">
                                                        <div className="real-health">
                                                            {treview ?
                                                                <h2>{treview?.reviews_title}</h2>
                                                                :
                                                                <h2>Reviews</h2>
                                                            }
                                                            {review?.map((review, index) => (
                                                                <div className="customer-reviews t-desk-2" key={index}>
                                                                    <div className="product-reviews-rating">
                                                                        <div className="orng_star_count" >
                                                                            {review?.user_rating}★
                                                                        </div>
                                                                        <div className="user-name">
                                                                            <h6 content="Kiara Keshvani">{review?.user_name}</h6>
                                                                            <h6 className="user-location"> {review?.user_location}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <p className="review-desck">"{review?.user_desc}"</p>
                                                                    <div className="review-date-like">
                                                                        <p className="date">{moment.utc(review?.review_date).local().startOf('seconds').fromNow()}</p>
                                                                        <p className="like"><FaRegThumbsUp className="plikes" /> {Math.floor(Math.random() * 500)}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (faq) {
                                        return (
                                            <div dataSection="true" id="faqs">
                                                <div id="faqs" className="mt-4">
                                                    <div className="faqDetails">
                                                        {tfaq ?
                                                            <h2>{tfaq?.faq_title}</h2>
                                                            :
                                                            <h2>FAQS</h2>
                                                        }
                                                        <Accordion allowZeroExpanded>
                                                            {faq?.map((faq, index) => (
                                                                <AccordionItem className="pfaqs" key={index}>
                                                                    <AccordionItemHeading>
                                                                        <AccordionItemButton>
                                                                            {faq?.faq_question}
                                                                        </AccordionItemButton>
                                                                    </AccordionItemHeading>
                                                                    <AccordionItemPanel>
                                                                        <p>
                                                                            <div dangerouslySetInnerHTML={{ __html: faq?.faq_desc }}></div>
                                                                        </p>
                                                                    </AccordionItemPanel>
                                                                </AccordionItem>
                                                            ))}
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                            </Suspense>
                        </div>
                        <div className="col-md-3 productDetailBtn">
                            <div className="stickyBuySection">
                                <h4>{productd?.page_title}</h4>
                                <p>{`${productd?.short_desc}... `}</p>
                                {(() => {
                                    if (productd?.price !== 0) {
                                        return (
                                            <>
                                                <div className="pro-price mb-4">
                                                    <span className="new-price">{numberFormat(productd?.price)}</span>
                                                    <span className="old-price"><del>{numberFormat(productd?.product_cut_price)}</del></span>
                                                    <div className="Pro-lable">
                                                        <span className="p-discount-sticky">Save {productd?.product_save}</span>
                                                    </div>
                                                </div>
                                                <button onClick={() => buyItNow(productd)}>BUY IT NOW</button>
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
            {(() => {
                if (productd?.price !== 0) {
                    return (
                        <section className="fact-area mob">
                            <div className="container">
                                <div className="col-12 col-lg-12 pt-1">
                                    <div className="">
                                        <h4 className="sticky-title">{productd?.page_title}</h4>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col-6 col-lg-6 pt-1 d-flex">
                                        <div className="stickyprice">
                                            <span className="sticky-price"><b>{numberFormat(productd?.price)}</b></span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6 pt-1 d-flex" style={{ justifyContent: "space-between" }}>
                                        <div className="sticky-buy-now" id="cartsticky">
                                            <span className="">
                                                <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                            </span>
                                            <button onClick={() => buyItNow(productd)}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    )
                }
            })()}
        </div>
    )
}

export default ProductDetails
function detailsComponent(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 2000);
    }).then(() => promise);
}